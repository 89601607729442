import styled from 'styled-components';
import { Button } from 'antd';

// Не уверен, что такие стили норм. Но сейчас возиться не хочется
const ButtonStyled = styled(Button)<{}>`
    color: ${p => p.theme.colors.font.accent};
    height: 25px;
    padding: 3px;

    &:hover {
        color: ${p => p.theme.colors.ui.accentHover};
    }
`
export default ButtonStyled
import { Empty } from 'antd'
import React from 'react'
import styled, { css } from 'styled-components'
import AttachFilesButton, { FilesList } from './AttachFilesButton'
import ConnectYandexDisk from './ConnectYandexDisk'
import { ExternalFile, Project, UsersUnion } from '../../generated-types'
import { IconFolder } from '../Icon/IconFolder'
import { IconEdit } from '../Icon/IconEdit'
import { CenterJustify } from '../Dropdowned'
import { useTranslation } from 'react-i18next'
import { IconFiles } from '../Icon/IconFiles'
import { IconDocs } from '../Icon/IconDocs'

interface FolderCardProps {
    isAdmin: boolean,
    source: Project | UsersUnion
    loading: boolean, 
    onFolderChanged: (items: ExternalFile[])=>void
}

const paddingLeftRight = css`
    padding-left: ${p => p.theme.isMobile ? '16px' : '40px'};
    padding-right: ${p => p.theme.isMobile ? '16px' : '40px'};
`

const FilesContainer = styled.div`
    
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    
`

const FilesHeader = styled.div`
display: flex;
gap: 8px;
padding: 24px 0 16px 0;
align-items: center;
${paddingLeftRight}
>.folder-name{
    font-size: 16px;
    font-weight: 500;
    flex-grow: 1;
}
`

const FilesTableScrollContainer = styled.div`
    flex-grow: 1;
    overflow: auto
`

const FilesTableScrollContent = styled.div`
${paddingLeftRight}
box-sizing: content-box;
min-width: ${p => p.theme.isMobile ? '' : '990px'};
max-height: calc(100% - 70px);
${p => !p.theme.isMobile && 'height: calc(100% - 70px);'}

`
const AttachFilesButtonStyled = styled(AttachFilesButton)`
    
        background-color: ${p => p.theme.isMobile ? p.theme.colors.ui.bgLight2 : p.theme.colors.ui.bgLight3};
        color: ${p => p.theme.colors.font.accent} !important;
        padding: ${p => p.theme.isMobile ? '8px' : '12px 24px'};
        border: none !important;
        box-sizing: content-box;
        height: auto;
        box-shadow: none;
        align-items: center;

        &:hover{
            background-color: ${p => p.theme.colors.ui.bgLight2} !important;
            
        }
        >.ant-btn-icon{
            stroke-width: 1.5;
            font-size: ${p => p.theme.isMobile ? '16px' : '24px'};
            display: flex;
            align-items: center;
        }
        >.btn-title{
            ${p => p.theme.isMobile && 'display: none;'}
        }
`
const TableContainer = styled.div`
    background-color: ${p => p.theme.colors.ui.bgLight2};
    border-radius: 8px;
    height: 100%;
    overflow: auto;
    padding-top: ${p => p.theme.isMobile ? '16px' : '0'};
`

const EmptyStyled = styled(Empty)`
    max-width: 271px;

    &>.ant-empty-image{
        height: auto;
    }

    &>.ant-empty-footer{

        
        >.ant-btn{
            background-color: ${p => p.theme.colors.ui.bgLight3};
            span{
                display: block;
            }
        }
    }
`

function FolderCard({ isAdmin, source, loading, onFolderChanged }: FolderCardProps) {

    const { t } = useTranslation();

    const attachBtn = <AttachFilesButtonStyled
        icon={source.attachedFolders.length > 0 ? <IconEdit /> : <IconFiles />}
        centerJustify={CenterJustify.center}
        basePath={"/"} loading={loading} onFinish={onFolderChanged} selectable={true} onlyFolders={true}
        buttonTitle={t(source.attachedFolders.length === 0 ? 'file.attachFolderBtn' : 'file.changeAttachedFolder') as string} title={t('file.attachFolder') as string}
        selectedItems={source.attachedFolders.map(({ name, path }) => ({ name, path } as unknown as ExternalFile))}
    />;


    if (source.attachedFolders.length == 0) {
        return <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ConnectYandexDisk allowAttachToken={isAdmin}>
                <EmptyStyled image={<IconDocs fontSize={48} opacity={0.5}/>} description={t(isAdmin? 'file.noConnectedStorage' : 'file.adminHasToAttachFolder')}>
                    {isAdmin && attachBtn}
                </EmptyStyled>
            </ConnectYandexDisk>
        </div>
    }

    return (
        <ConnectYandexDisk allowAttachToken={isAdmin}>
            <FilesContainer>
                <FilesHeader>
                    <IconFolder fontSize={24} opacity={0.5} />
                    <span className='folder-name'>{source.attachedFolders[0]?.name}</span>
                    {isAdmin && attachBtn}
                </FilesHeader>
                <FilesTableScrollContainer>
                    <FilesTableScrollContent>
                        <TableContainer>
                            {<FilesList folders={source.attachedFolders} selectable={false} tableView />}
                        </TableContainer>
                    </FilesTableScrollContent>
                </FilesTableScrollContainer>
            </FilesContainer>
        </ConnectYandexDisk>
    )
}

export default FolderCard
import {message, theme, Upload} from 'antd';
import ImgCrop from 'antd-img-crop';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import React, {useState} from 'react';
import axios from 'axios';
import {tokenKey} from '../../routes/Auth/authContext';
import {useTranslation} from "react-i18next";
import {Maybe} from "../../generated-types";
import { IconEdit } from '../Icon/IconEdit';
import styled from 'styled-components';
import { IconTeam } from '../Icon/IconTeam';

const MAX_WIDTH=500
const MAX_HEIGHT=500

interface AvatarEditorProps {
  /**
   * Тип объекта, для которого добавляется аватарка
   */
  contextType?: string
  /**
   * Идентификатор объекта, для которого добавляется аватарка
   */
  contextId: string
  avatarUrl?: Maybe<string>
}
const token = localStorage.getItem(tokenKey);

const AvatarEditorContainer=styled.div`
 
  width: 80px;
  height: 80px;

  position: relative;

  .ant-upload-wrapper{
    display: block;
    
  }
  
  .ant-upload-list-item-container{
    width: 80px !important;
    height: 80px !important;
  }

  .ant-upload-list-item{
    padding: 0 !important;
    
    border-radius: 40px !important;
    overflow: hidden;
    border: none !important;

    &::before{
      width: 100% !important;
      height: 100% !important;
    }

    >.ant-upload-list-item-thumbnail{
      display: block;
      height: 100%;
    }
  }

  >.change-avatar-btn{
    background-color: ${p=>p.theme.colors.ui.accent};
    width: 24px;
    height: 24px;
    border-radius: 12px;
    color: ${p=>p.theme.colors.ui.white};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16;
    transition: all .3s;

    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;

    >.ant-upload{
      line-height: 1;
    }

    &:hover{
      background-color: ${p=>p.theme.colors.ui.accentHover};
    }
  }

  >.avatar-container{
    width: 100%;
    height: 100%;
    background-color: ${p=>p.theme.colors.ui.bgLight};
    border-radius: 100%;
    color: ${p=>p.theme.colors.font.primary};
    overflow: hidden;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;


    >img{
      width: 100%;
      height: 100%;

    }
  }
`
const AvatarEditor: React.FC<AvatarEditorProps> = ({avatarUrl, contextId, contextType}) => {
  const {t} = useTranslation();
  const [avatar, setAvatar]=useState(avatarUrl)
  
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  
  const uploadImage = ({onSuccess, file, action}:any) => {

    const img=new Image()
    img.src=URL.createObjectURL(file)

    img.onload=()=>{

      //сжимаем изображение при помощи canvas
      const canvas=document.createElement('canvas')
      canvas.width=MAX_WIDTH
      canvas.height=MAX_HEIGHT
      const ctx=canvas.getContext('2d')
      ctx?.drawImage(img,0,0, MAX_WIDTH, MAX_HEIGHT)

      canvas.toBlob((blob)=>{
    
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob || file);
        reader.onload = async () => {
          const data = reader.result;
          try {
            await axios.post(action, data, {
              headers: {
                "Authorization": token ? `Bearer ${token}` : "",
                "Content-Type": "image/png" 
              }
            })
            onSuccess('OK')
            message.success(t('Изображение загружено'), 2)
            setAvatar(img.src)
          } catch (e:any) {
            // TODO: write to log
            message.error(e.message, 2)
          }
        }
      })

  }
  }

    let act = `/avatar/upload?contextId=${contextId}&contextType=${contextType}`;
    
  return(
    <AvatarEditorContainer>
        {<div className='avatar-container'>
          {avatar ? <img src={avatar}/> : <IconTeam/>}
        </div>}
        <ImgCrop rotationSlider>
          <Upload className="change-avatar-btn"
            accept="image/png, image/jpeg"
            maxCount={1}
            action={act}
            customRequest={uploadImage}
            showUploadList={false}><IconEdit/></Upload>
        </ImgCrop>
  </AvatarEditorContainer>
  )
  
};

export default AvatarEditor;
import React, {useEffect, useState} from 'react'
import {Input} from 'antd'
import {useTranslation} from "react-i18next";
import {Task, TaskMemberType, TasksView, TasksViewColumn, useTasksByViewIdLazyQuery,} from "../../../generated-types";
import TasksList from "../TasksList";
import {SearchOutlined} from '@ant-design/icons';
import TasksBoard from "../TasksBoard";
import TasksFastList from "../TasksFastList";
import TasksCalendar from "../TasksCalendar";
import {pollInterval} from "../../../ApolloClient";
import IconStateSwitcher, {IconState} from "../IconStateSwitcher";
import {IconBoards} from "../../Icon/IconBoards";
import {IconTasks} from "../../Icon/IconTasks";
import {IconCalend} from "../../Icon/IconCalend";
import TaskViewSortDropdown, {DIRECTION, SORTFIELD} from "./TaskViewSortDropdown";
import TaskViewFilter, { AvailableFilters, AvailableFlags, FilterKeyItem, NO_MEMBERS, NO_TAGS } from './TaskViewFilter';
import {isMobile} from "react-device-detect";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../../routes/Auth/authContext";
import {taskPriorityByName} from "../TaskImportance";

interface TaskViewListProps {
    tasksView: TasksView
    allowCreate?: boolean
    showProjectTitle?: boolean
    showHeader?: boolean
    defaultDisplayParams?: TaskViewDisplayParams
    onTasksFiltered?: (filtered: number, total: number) => void
}

interface TaskViewDisplayParams {
    sort: SORTFIELD,
    filter: FilterKeyItem[],
    sortDirection: DIRECTION,
    includeResolved: boolean,
    iAmResponsible: boolean
}

function TaskViewList({ tasksView, showProjectTitle = true }: TaskViewListProps) {
    const [tasksByViewId] = useTasksByViewIdLazyQuery({
        pollInterval // TODO: переделать на подписку
    })

    const [tasks, setTasks] = useState([] as Task[])

    useEffect(() => {
        tasksByViewId({
            fetchPolicy: "network-only",
            variables: {
                tasksViewId: tasksView.id,
                includeResolved: false,
                iAmResponsible: false
            },
            onCompleted: (data) => {
                setTasks((data.tasks ?? []) as Task[]);
            }
        })

    }, [tasksView?.id]);

    let style = {padding: "16px 40px 0px 40px", display: "flex", flexDirection: "row", gap: "8px", height: "56px"};
    if (isMobile) {
        style.padding = "3px 5px 3px 5px";
        style.height = "40px";
    }

    return <div style={{width: "100%", flexGrow: 1, display: "flex", flexDirection: "column", height: "100%", overflow: "hidden", maxHeight: '100%'}}>
        <TasksBoard tasks={tasks} taskView={tasksView} showProjectTitle={false} allowCreate={false} allowEdit={false} allowOpenTask={false}/>
    </div>
}

export default TaskViewList
import React, {useState} from 'react';
import {Alert, Col, Form, message, Row, Watermark} from 'antd';
import {useTranslation} from "react-i18next";
import {
    AccessLevel,
    PaymentSlotType,
    UsersUnion,
    UsersUnionMember,
    useUsersUnionQuery,
    useUsersUnionUpdateMutation
} from "../../generated-types";
import UsersUnionMembersList from "./UsersUnionMembersList";
import {useNavigate, useParams} from "react-router-dom";
import UsersUnionSettings from "./UsersUnionSettings";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import Spinner from "../Spinner";
import UsersUnionFilesList from "../File/UsersUnionFilesList";
import UsersUnionProjectsList from "./UsersUnionProjectsList";
import UsersUnionHeader, { UsersUnionTabs } from './UsersUnionHeader';
import { updateUsersUnionInCache } from '../../subscriptions/allUsersUnions';

const UsersUnionCard: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm();
    const { id, tab } = useParams();
    const { loading, error, data, refetch } = useUsersUnionQuery({
        variables: {
            id: id || "",
            showArchived: false,
            slotType: PaymentSlotType.UsersUnionMembers
        }
    });

    const [selectedTab, setTab] = useState<string>(tab ?? UsersUnionTabs.projects);

    const [usersUnionUpdate] = useUsersUnionUpdateMutation({
        onCompleted:(data)=>{
            success()
        },
        update: (cache, r) => {
            updateUsersUnionInCache(cache, r.data?.usersUnionUpdate as UsersUnion)
        }
    });

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('editDataMassage'),
            style: {
                marginTop: '20vh',
            },
        });
    };
    const authInfo = useReactiveVar(authState);

    if (!id)
        return <div>define usersUnion id please</div>

    if (loading) return <Spinner loading />;
    if (error) return<Alert type="error" message={error.message} banner />;

    let union: any = data == null ? null : data.usersUnion;


    if (union.members.length == 0)
        return <div>No access</div>


    if (!union)
        return <div>define right usersUnion id please</div>

    const empty = data?.usersUnion?.paymentAccount?.emptySlotsCount ?? 0;

    const openAddSlotsModal = () => {
        // TODO: тут надо разобраться в структурах с этими TypesWithAmounts и понять, зачем они везде используются
        // addSlotsContext({
        //     slotsType: PaymentSlotType.UsersUnionProjectMember
        // });
    }

    const isAdmin = union.members.some((m: UsersUnionMember)=>m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin)

    const save = (id:string, input: any) => {
        usersUnionUpdate({variables: {id, input}})
    };

    const toggleOnlyArchivedProjects = (onlyArchived: boolean)=>{
        refetch({
            showArchived: onlyArchived
        });
    }

    const content: any = {
        projects: <UsersUnionProjectsList key={union.id} hideFilter={false} union={union} onChange={toggleOnlyArchivedProjects}/>,
        // tasks: <NotImplemented/>
    };

    if (union.currentUserIsMember) {
        content.files = <UsersUnionFilesList usersUnion={union} isAdmin={isAdmin}/>
        content.members = <UsersUnionMembersList usersUnion={union as UsersUnion} isAdmin={isAdmin} />
        content.settings = <>
            <UsersUnionSettings union={union} form={form} save={save} saveButton={true} deleteButton={isAdmin}/>
            {contextHolder}</>
    }

    return (
        <Watermark
            content={data?.usersUnion?.paymentAccount?.isBlocked ? t('paymentAccount.unionIsBlocked')! : undefined}
            style={{display: "flex", flexDirection: "column", flexGrow: 1, maxHeight: '100%'}}
            >
            <UsersUnionHeader union={union} onChangeTab={tab=>setTab(tab.toString())} selectedTab={UsersUnionTabs[selectedTab as keyof typeof UsersUnionTabs]}/>
            <Row style={{margin: 0, flexGrow: 1, overflow: 'hidden'}}>
                <Col span={24} style={{height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column'}}>
                    {content[selectedTab]}
                </Col>
            </Row>
        </Watermark>

    )
};

export default UsersUnionCard;

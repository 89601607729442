import { Avatar } from "antd"
import styled from "styled-components"

export const MembersContainer=styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    gap: 4px;
    width: fit-content;
    max-width: 100%;
    min-width: 0;
`
export const AvatarGroupStyled=styled(Avatar.Group)`
    
    display: flex;
    gap: 4px;

`

export const MaxAvatar=styled(Avatar)`
    flex-shrink: 0;

    background-color: unset;
    color: ${p=>p.theme.colors.font.primary};
    border: ${p=>`${p.theme.colors.ui.bgLight} 1px solid`} !important;
    user-select: none;
    width: 24px;
    height: 24px;
    font-size: 10px !important;
`

export const OverrideContent=styled.div`

display: flex;
flex-direction: column;
gap: 4px;
padding: 8px;
border-radius: 16px;

&>div{
    display: flex;
    gap: 4px;
    align-items: center;

    >span.user-name{
        font-size: 12px;
        font-weight: 400;
    }
}
`
import {Button, Space, theme, Tooltip} from "antd";
import {useTaskResolvedMutation} from "../../generated-types";
import {BorderOutlined, CheckSquareOutlined} from '@ant-design/icons'
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";
import { IconCheck } from "../Icon/IconCheck";

export interface TaskResolvedProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string
    value: boolean,
    onChange?: (value: boolean) => void;
    disabled?:boolean;
    buttonStyle?: CSSProperties
    fullView?: boolean
    small?: boolean
    size?: 12 | 16 | 24
}

export const ResolvedColor = "rgba(45,183,245,0.7)";
export const ResolvedCard = (resolved: boolean, style: React.CSSProperties) => {
    return {
        ...style,
        // borderRight: resolved ? `${ResolvedColor} solid 2px` : undefined,
        // borderLeft: resolved ? `${ResolvedColor} solid 2px` : undefined,
        // background: resolved ? `linear-gradient(-90deg, ${ResolvedColor}, 10%, transparent, transparent)` : undefined
    }
}

//TODO: Переписать это на styled-components
function TaskResolved(props: TaskResolvedProps) {
    const {t} = useTranslation();
    const [value, setValue] = useState<boolean>(props.value)
    const [hover, setHover]=useState(false)
    const {token}=theme.useToken()

    const [taskResolved] = useTaskResolvedMutation({
        onCompleted: () => {
            if (props.onChange) {
                props.onChange(value)
            }
        }
    })
    const small=props.fullView? true : props.small
    const uncheckedBorderColor=small? token.colors.ui.bgDark : token.colors.ui.bgLight3
    const opacity=hover? 0.5 : 0.3
    const succesColor=hover? token.colors.ui.succesHover : token.colors.ui.succes
    const checkBoxViewStyle: CSSProperties={
                        color: token.colors.font.white, 
                        backgroundColor: value? succesColor : 'unset',
                        borderColor: value? succesColor : uncheckedBorderColor,
                        opacity: (value || !small)? 1 : opacity,
                        borderWidth: 1,
                        borderStyle: 'solid',
                        width: props.size??  (small? 16 : 24),
                        height: props.size?? (small? 16 : 24),
                        fontSize: props.size? props.size/2 : (small? 8 : 16),
                        borderRadius: 3,
                        flexShrink: 0,
                        ...props.buttonStyle,
                        padding: 0
    }

    const fullViewStyle: CSSProperties={
        backgroundColor: hover? token.colors.ui.bgLight3 : token.colors.ui.bgLight,
        
        borderRadius: 8,
        padding: 8
    }

    const iconStyle: CSSProperties={display: 'flex', justifyContent: 'center', alignItems: "center"}
    const icon=<IconCheck style={{strokeWidth: small? 2 : undefined}}/>

    return (
        <Tooltip title={t(value ? "task.resolved" : "task.resolve")}>
            <Button icon={(!props.fullView && value)? icon : undefined} type="text"
                    style={props.fullView? fullViewStyle : checkBoxViewStyle}
                    styles={{icon: iconStyle}}
                    disabled={props.disabled}
                    onClick={(e)=>{
                        setValue(!value)
                        taskResolved({variables: { id: props.id, resolved: !value }})
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onMouseEnter={()=>setHover(true)}
                    onMouseLeave={()=>setHover(false)}
            >{
                props.fullView && <Space size={8}>
                                    <div style={{...checkBoxViewStyle, ...iconStyle, boxSizing: 'border-box'}}>
                                        {value && icon}
                                    </div>
                                    <span style={{color: value? succesColor : undefined}}>{t("task.resolve")}</span>
                                </Space>
            }</Button>
            {/*<Switch checkedChildren={t('task.includeResolved')} unCheckedChildren={t('task.includeResolved')} defaultChecked={value}*/}
            {/*        style={{background: value ? ResolvedColor : undefined}}*/}
            {/*        onChange={(checked, event)=>{*/}
            {/*            setValue(checked)*/}
            {/*            taskResolved({variables: {*/}
            {/*                id: props.id,*/}
            {/*                    resolved: checked*/}
            {/*                }})*/}
            {/*        }}*/}
            {/*/>*/}
        </Tooltip>)
}

export default TaskResolved
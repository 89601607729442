import { Tabs } from "antd";
import styled from "styled-components";


export const TabsStyled=styled(Tabs)`
    width: auto;

    .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
            color: ${p=>p.theme.colors.font.primary};
            text-shadow: none;
            
        }

        .ant-tabs-tab > .ant-tabs-tab-btn:hover {
            color: ${p => p.theme.colors.font.accent};
        }

        .ant-tabs-ink-bar-animated {
            height: 4px;
            background: ${p => p.theme.colors.ui.accent};
        }

        .ant-tabs-tab-btn > div {
            display: flex;
            gap: 4px;
            align-items: center;

            svg {
                width: ${p=>p.theme.isMobile? '16px' : '24px'};
                height: ${p=>p.theme.isMobile? '16px' : '24px'};
                color: inherit;
            }
        }
    .ant-tabs-nav{
        margin: 0;
    }

    .ant-tabs-tab{
        margin: 0 !important;
    }
    .ant-tabs-nav-list {
        gap: ${p=>p.theme.isMobile? '16px' : '24px'};
        /*flex-direction: row-reverse;*/
        /*justify-content: right;*/
    }
`
import React, { useEffect, useMemo, useState } from 'react';
import {Col, Empty, Row, Watermark} from 'antd'
import {
    AccessLevel,
    Project,
    useProjectCardLazyQuery,
    useProjectCardQuery,
    useProjectCardReadonlyLazyQuery,
} from '../../generated-types';
import {useMatch, useParams} from "react-router-dom";
import ProjectMembersList from "./ProjectMembersList";
import {useTranslation} from "react-i18next";
import {addTaskContext} from "../Tasks/TaskNew";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import ProjectFilesList from "../File/ProjectFilesList";
import Spinner from "../Spinner";
import {editTaskContext} from "../Tasks/TaskEditor";
import ProjectHeader, {ProjectTabs} from "./ProjectHeader";
import {routes} from "../../AppRouter";
import TaskViewList from "../Tasks/TaskView/TaskViewList";
import ProjectSettingsPage from "./ProjectSettingsPage";
import { allUsersUnions } from '../../subscriptions/allUsersUnions';
import TaskViewListReadOnly from '../Tasks/TaskView/TaskViewListReadOnly';

// TODO: надо прверить на безопасность - чтобы если у пользоваталя нет доступа к проекту, он не получал лишнего с сервера
const ProjectCard: React.FC<{readOnly?: boolean}> = ({readOnly=false}) => {
    const {t} = useTranslation();
    const addTaskCtx = useReactiveVar(addTaskContext);
    const allUnions = useReactiveVar(allUsersUnions);

    const match = useMatch(routes.projectTask);

    if (match?.params.taskId) {
        editTaskContext({taskId: match.params.taskId})
    }

    const {id, tab} = useParams();
    const [selectedTab, setTab] = useState<ProjectTabs>(ProjectTabs.tasks); // TODO: вернуть получение из параметров
    const [taskCount, setTaskCount] = useState(0)
    const [loading, setLoading] = useState(false)

    const [fullProject, {data: fullData}] = useProjectCardLazyQuery({
        variables: {id: id as string},
        onCompleted: (data) => {
            setLoading(false);
        }
    })

    useEffect(() => {
        if (readOnly)
            readonlyProject()
        else
            fullProject()
        setLoading(true);
    }, [id, readOnly]);

    const [readonlyProject, {data: readonlyData}] = useProjectCardReadonlyLazyQuery({
        variables: {id: id as string},
        onCompleted: (data) => {
            setLoading(false);
        }
    })

    const project = (readOnly ? readonlyData?.project : fullData?.project) as Project;

    const authInfo = useReactiveVar(authState);

    let content: any=undefined;

    const isAdmin = useMemo(()=>{
        if (!project) return false;
        // Текущий пользователь - админ проекта
        let res = project.members
          .some(m => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin)

        // Или текущий пользователь - админ команды
        if (!res && project.usersUnionAsOwner)
            res = allUnions.unions.find(v=>v.id === project.usersUnionAsOwner?.id)?.members
              .some(m=>m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin) ?? false;
        return res;
    }, [project, allUnions])

    if(project){
        addTaskCtx.projectId = project.id;
        addTaskContext(addTaskCtx);

        content = {
            [ProjectTabs.tasks]: readOnly
              ? <TaskViewListReadOnly key={project.tasksView.id} tasksView={project.tasksView} />
              : <TaskViewList key={project.tasksView.id} tasksView={project.tasksView}
                                               allowCreate={true}
                                               showProjectTitle={false} onTasksFiltered={(_, taskCount) => {
                setTaskCount(taskCount)
            }}/>,
            [ProjectTabs.files]: <ProjectFilesList project={project} isAdmin={isAdmin}/>,
            [ProjectTabs.members]: <ProjectMembersList project={project} isAdmin={isAdmin}/>,
            [ProjectTabs.settings]: <ProjectSettingsPage project={project} isAdmin={isAdmin}/>,
        }
    }

    const watermarkContent = useMemo(() => {
        if (project && project.paymentAccount?.isBlocked) {
            return t('paymentAccount.projectIsBlocked') as string
        } else if (project && project.archived) {
            return t('project.archived')as string
        } else return undefined
    }, [project])

    if (!id || !project) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    }

    let children =
      <>{project && <ProjectHeader project={project} onChangeTab={tab => setTab(tab)} selectedTab={selectedTab}
                                                taskCount={taskCount} isAdmin={isAdmin} hideFiles={readOnly} hideFavorite={readOnly}
                                                hideTags={readOnly} hideStatus={readOnly}/>}
        <Row style={{margin: 0, flexGrow: 1, marginTop: 4, overflowY: 'auto'}}>
            <Col span={24} style={{height: '100%'}}>
                {content[selectedTab]}
            </Col>
        </Row>
    </>

    if (loading)
        return <Spinner loading={loading}/>
    if (!watermarkContent)
        return children;

    // У меня никак не получается положить дропдаун ниже так, чтобы он был справа, а название проекта было во всю оставшуюся ширину.
    return <Watermark content={watermarkContent} style={{display: "flex", flexDirection: "column", flexGrow: 1, maxHeight: '100%', border: "green 1px solid"}}>
        {children}
    </Watermark>
};

export default ProjectCard
import React, {Key, useRef, useState} from 'react';
import {Project, ProjectMemberInvite, User, UsersUnion} from "../../generated-types";
import {useTranslation} from "react-i18next";
import styled, { css } from 'styled-components';
import UserAvatar from '../User/UserAvatar';
import {Checkbox} from '../Checkbox';
import { Icon } from '../Icon/Icon';
import { IconAttantion } from '../Icon/IconAttantion';

interface RecordType {
    key: string;
    user: User;
}

interface ProjectInviteMembersSelectorProps {
    invite: ProjectMemberInvite,
    union: UsersUnion,
    project: Project
    items: RecordType[]
    onChange: (selected: User[]) => void
    maxMembers: number
}

const SelectorContainer=styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const UsersList=styled.div<{$showWarning: boolean}>`
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow-y: auto;
        margin-bottom: ${p=>p.$showWarning? 0 : 24}px;

        >.users-list-item{
            display: flex;
            gap: 8px;
            align-items: center;

            >.user-list-item-user-info{
                flex-grow: 1;
            }

            &.disabled{
                opacity: 0.5;
                cursor: not-allowed;
                pointer-events: none;
            }
        }

        .user-list-item-text{
            display: block;
            font-size: 12px;
            font-weight: 300;
            line-height: 1;
        }

        .semy-transparent{
            opacity: 0.5;
        }

        .already-in-project{
            margin-right: 8px;
        }
`

interface UserListItemProps{
    user: User,
    selected: boolean,
    onSelectionChange: (key: Key, value: boolean)=>void
    alreadyInProject: boolean
    disabled: boolean
}
const UserListItem=({user, selected, onSelectionChange, alreadyInProject, disabled=false}: UserListItemProps)=>{

    const {t}=useTranslation()

    return <div className={`users-list-item ${disabled? 'disabled' : ''}`} onClick={()=>onSelectionChange(user.id, !selected)}>
        <UserAvatar user={user} size='24'/>
        <div className='user-list-item-user-info'>
            <span className='user-list-item-text'>{user.fullName?? user.username}</span>
            <span className='user-list-item-text semy-transparent'>{user.email}</span>
        </div>
        {alreadyInProject && <span className='user-list-item-text semy-transparent already-in-project'>{t('usersUnion.processProjectInvite.alreadyInProject')}</span>}
        <Checkbox checked={selected}/>
    </div>
}

const DangerContainer=styled.div`
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 8px;
    background-color: ${p=>p.theme.colors.ui.red01};
    margin-bottom: 8px;
    >svg{
        width: 16px;
        height: 16px;
        color: ${p=>p.theme.colors.font.red};
        flex-shrink: 0;
    }

    >span{
        line-height: 1.1;
        font-weight: 300;
    }
`
const ProjectInviteMembersSelector: React.FC<ProjectInviteMembersSelectorProps> = ({
                                                                                       maxMembers,
                                                                                       invite,
                                                                                       union,
                                                                                       project,
                                                                                       items,
                                                                                       onChange
                                                                                   }) => {

    const {t} = useTranslation()
    const [selectedKeys, setSelectedKeys] = useState<Key[]>(project.members.reduce((acc, m)=>{
        m.asUnion && m.asUnion.id==union.id && acc.push(m.user.id)
        return acc
    }, [] as Key[]));
    const [willRemove, setWillRemove]=useState<string[]>([])
    const alreadyInProject=useRef<Key[]>(selectedKeys)

    const onSelectionChange=(key: Key, value: boolean)=>{
        const res = value ? [...selectedKeys, key] : selectedKeys.filter(k=> k !== key);
        setSelectedKeys(res)

        const [selectedUsers, willRemoveUsers]=union.members.reduce(([selectedUsers, willRemoveUsers], member)=>{
            const userId=member.user.id

            if(res.includes(userId))
                selectedUsers.push(member.user)
            else if(alreadyInProject.current.includes(userId))
                willRemoveUsers.push(member.user.fullName?? member.user.username)

            return [selectedUsers, willRemoveUsers]
        }, [[],[]] as [User[], string[]])

        onChange(selectedUsers)
        setWillRemove(willRemoveUsers)
    }

    const empty=maxMembers-selectedKeys.length
    
    const info=empty==0? t('usersUnion.processProjectInvite.notEnoughEmpty') :
        t('usersUnion.processProjectInvite.maxMembers', {maxMembers, empty})
    const showWarning=willRemove.length!==0
    return <>
            <span className='slots-info'>{info}</span>
            <UsersList $showWarning={showWarning}>
                {union.members.map(item=>{
                    const selected=selectedKeys.some(key=>key == item.user.id)
                    return <UserListItem key={item.user.id} user={item.user} disabled={empty===0 && !selected} alreadyInProject={alreadyInProject.current.some(key=>key===item.user.id)} selected={selected} onSelectionChange={onSelectionChange}/>
                }
                )}
            </UsersList>
            
            {showWarning && <DangerContainer>
                <IconAttantion/>
                <span>{t(`usersUnion.processProjectInvite.willBeRemoved`, {count: willRemove.length, users: willRemove.join(', '), projectTitle: project.title })}</span>
                </DangerContainer>}
    </>
};

export default ProjectInviteMembersSelector;
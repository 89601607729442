import React, {useRef, useState} from 'react'
import {isMobile} from 'react-device-detect'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from 'styled-components';
import GeneralBoardColumn, {GeneralBoardColumnType} from './GeneralBoardColumn';
import {GeneralBoardItemType} from './GeneralBoardCard';
import {useDrop} from 'react-dnd';

const BoardContainer = styled.div<{ $isMobile: boolean, $boardPadding?: string }>`

    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    ${({ $boardPadding }) => $boardPadding && `padding: ${$boardPadding}`};

`


interface GeneralBoardProps<I, C> {
    signature: string, //сигнатура карточек на доске
    items: GeneralBoardItemType<I>[],
    columns: GeneralBoardColumnType<C>[],
    columnsSplitter: (column: GeneralBoardColumnType<C>, items: GeneralBoardItemType<I>[]) => GeneralBoardItemType<I>[],//обработчик для разделения элементов по столбцам
    onItemRender: (item: I) => React.JSX.Element,//обработчик для отрисовки карточки элемента
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C) => void
    stickyTopElement?: (column: C) => React.JSX.Element
    columnsToShow?: number,
    touchEventsDelay?: number
    columnTitle?: (column: GeneralBoardColumnType<C>, columnItems: GeneralBoardItemType<I>[]) => string | JSX.Element
    columnWidth?: number | string
    padding?: string
}

function GeneralBoard<I, C>({ signature, items, columns, columnsSplitter, onItemRender, onItemDropped, stickyTopElement, columnsToShow = 4, touchEventsDelay = 500, columnTitle, columnWidth, padding }: GeneralBoardProps<I, C>) {

    const [isDraggable, setIsDraggable] = useState(false)
    const [cursorType, setCursorType] = useState<string | undefined>(undefined)
    const boardRef = useRef<HTMLDivElement>(null)

    const scrollDistance=50

    const scrollBy=(distance: number)=>{
        const board=boardRef.current
        if(board){
            board.scrollBy({
                left: distance,
                behavior: 'smooth'
            })
        }
    }

    const scrollRight = () => {
        scrollBy(scrollDistance)
    }

    const scrollLeft = () => {
        scrollBy(-scrollDistance)
    }

    const [, drop] = useDrop({
        accept: signature,
        hover: (item, monitor) => {
            if (boardRef.current) {

                const ratio=isMobile? 0.4 : 0.1
                const gap = window.innerWidth*ratio;//расстояние в пикселях от края доски, при котором будет срабатывать перелистывание, решил делать не по пикселям, а долями экрана 
                const { left, width } = boardRef.current.getBoundingClientRect()
                const clientOffsetX = monitor.getClientOffset()?.x ?? 0
                const xPosition = clientOffsetX - left
                if (xPosition < gap) {
                    scrollLeft()
                }
                if (xPosition > width - gap) {
                    scrollRight()
                }
            }
        },
        collect: (monitor)=>{
            monitor.isOver()
        }
    })

    drop(boardRef)
    

    return (<div style={{ width: '100%', height: '100%' }}>
        <BoardContainer ref={boardRef} $isMobile={isMobile} $boardPadding={padding}>
            {
                columns.map((column, index, arr) => {
                    const columnItems = columnsSplitter(column, items)
                    const colTitle = columnTitle ? columnTitle(column, columnItems) : column.title + ` (${columnItems.length})`
                    
                    return <GeneralBoardColumn
                            key={`GB-Col-${index}`}
                            column={column}
                            onItemRender={onItemRender}
                            items={columnItems}
                            signature={signature}
                            columnTitle={colTitle}
                            onItemDropped={onItemDropped}
                            stickyTopElement={stickyTopElement}
                            isBoardDragging={isDraggable}
                            cursorType={cursorType}
                            touchEventsDelay={touchEventsDelay}
                            width={columnWidth}
                            isLast={index !== arr.length - 1 && !isMobile}
                        />
                })
            }
        </BoardContainer>
    </div>
    )
}

export default GeneralBoard
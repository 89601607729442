import { Button } from 'antd';
import React from 'react';
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import { IconPlus } from './Icon/IconPlus';

interface AddButtonProps {
    title: string,
    onClick?: any,
    disabled?:boolean|undefined,
    testId: string
}

const ButtonStyled=styled(Button)`
    background-color: unset !important;
    border: 1px dashed ${p=>p.theme.colors.ui.accent}; 
    box-shadow: none;
    display: flex;
    align-items: center;

    >.ant-btn-icon{
        font-size: 24px;
        line-height: 1;
    }

    >span{
        font-size: 16px;
        font-weight: 300;
        line-height: 1;
    }
`
const AddButton = (props: AddButtonProps) => (
    <ButtonStyled
    test-id={props.testId}
    disabled={props.disabled} 
    size="small" 
    block 
    onClick={props.onClick}
    style={{height: "100%"}}
    icon={<IconPlus/>}>
        {props.title}
    </ButtonStyled>
);

export default AddButton;
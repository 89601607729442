import React, {ReactElement, useEffect, useMemo, useRef, useState} from 'react'
import {Button, theme} from 'antd'
import {useTranslation} from "react-i18next";
import {Task, TasksView,} from "../../../generated-types";
import {makeVar, useReactiveVar} from "@apollo/client";
import {allProjects} from "../../../subscriptions/allProjects";
import {allUsersUnions} from "../../../subscriptions/allUsersUnions";
import {allPersonalTags} from "../../../subscriptions/allPersonalTags";
import {onlyUnique} from "../../../utils";
import Dropdowned from "../../Dropdowned";
import styled, {css} from "styled-components";
import {IconFilter} from "../../Icon/IconFilter";
import {IconArrowDown} from "../../Icon/IconArrowDown";
import {Icon} from "../../Icon/Icon";
import UserAvatar from "../../User/UserAvatar";
import {allPriorities, TaskImportanceElement} from "../TaskImportance";
import UsersUnionsAvatar from "../../UsersUnion/UsersUnionsAvatar";
import Checkbox from "../../Checkbox";
import {isMobile} from "react-device-detect";
import TagDisplayItem from "../../Tagger/TagDisplayItem";
import { IconDelete } from '../../Icon/IconDelete';

export enum AvailableFilters {
    flags = 'flags',
    tags = 'tags',
    projects = 'projects',
    usersUnions = 'usersUnions',
    members = 'members',
    priority = 'priority',
}

export enum AvailableFlags {
    onlyMy = 'onlyMy',
    iAmResponsible = 'iAmResponsible',
    includeResolved = 'includeResolved',
    includeUnresolved = 'includeUnresolved'
}

export type FiltersType = {
    [k in AvailableFilters]: boolean
}

interface TaskViewFilteredProps {
    disable: boolean
    tasksView: TasksView
    tasks: Task[]
    excludeFlags?: AvailableFlags[]
    initialState?: FilterKeyItem[]
    onFilterChanged?: (filter: FilterKeyItem[]) => void
    showFilters: FiltersType
}

const Btn = styled(Button)<{ $filterEnabled: string }>`
    background-color: ${({theme}) => theme.colors.ui.bgLight3};
    color: ${({theme}) => theme.colors.font.primary};
    box-shadow: unset;
    border: none;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: 14px;
    height: 100%;

    ${({$filterEnabled, theme}) => $filterEnabled === 'true' && css`
        .ant-btn-icon > svg {
            color: ${theme.colors.ui.accent};
            fill: ${theme.colors.ui.accent};
        }
    `};

    div {
        flex-grow: 1;
        text-align: left;
    }

    .ant-btn-icon {
        display: flex;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.ui.bgLight};
    }
`

const DropdownedContent = styled.div<{ $columnWidth: string, $isMobile: string }>`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 80vh;
    max-width: 80vw;

    .filter-item {
        display: flex;
        flex-direction: column;
        background-color: ${p => p.theme.colors.ui.bgLight};
        width: ${p => p.$columnWidth};
        padding: 0px 12px 12px 12px;
        border-radius: 8px;
        font-size: 12px;
        gap: 4px;

        ${p => p.$isMobile != 'true' && css`
            overflow-y: auto;
            overflow-x: hidden;
        `};

        .title {
            background-color: ${p => p.theme.colors.ui.bgLight};
            position: sticky;
            top: 0;
            font-size: 12px;
            z-index: 10;
            padding-top: 12px;
        }

        div {
            font-size: 12px;
            display: flex;
            width: 100%;
            height: 24px;
            align-items: center;

            .item {
                line-height: 1.2;
            }
            
            .item.semiselected {
                .checkmark {
                    background-color: ${p => p.theme.colors.ui.bgLight3};
                    border: 2px solid ${p => p.theme.colors.ui.bgLight3};
                }
            }
        }

        .uu-title {
            display: flex;
            gap: 3px;
            overflow: hidden;
            line-break: anywhere;
            white-space: nowrap;
        }
    }

    .tag-item {
        margin: 0 !important;
    }

    .row {
        display: flex;
        gap: 4px;
    }

    .row.title {
        align-content: center;
    }

    .ant-btn {
        padding: 10px 0;
        height: 22px;
        color: ${p => p.theme.colors.font.primarySemitransparent};
    }

    .ant-btn:hover {
        color: ${p => p.theme.colors.font.accent} !important;
    }

    .row.first {
        max-height: 153px;
    }

    .row.second {
        max-height: 237px;
    }

    .task-importance-item {
        padding: 2px 4px;
    }
`

function FilterItems({items, title, type, onChange, selectedItems}: {
    type: string,
    title: string,
    items: { key: string, selected?: boolean, r: ReactElement }[]
    onChange: (type: string, key: string | string[], value: boolean) => void,
    selectedItems: FilterKeyItem[]
}) {
    const selected = selectedItems.filter(si => si.type == type) ?? []

    return <div className={"filter-item"}>
        <div className={"title"}>
                <div style={{flexGrow: 1}}>{title}</div>
                {selected.length > 0 && <Button type="text"
                onClick={(e) => {
                    onChange(type, selected.map(v=>v.key), false)
                }}
                ><IconDelete/></Button>}
        </div>
        {items.map(v => {
            let className = "item"
            let checked = selected.some(si => si.key == v.key);

            // Показывать полувыбранный, если пользователь не выбрал ни includeUnresolved ни includeResolved, т.к. по-умолчанию фильтрует по незавершённым
            if (v.key === AvailableFlags.includeUnresolved && !selected.some(si => si.key === AvailableFlags.includeUnresolved || si.key === AvailableFlags.includeResolved)) {
                className += " semiselected"
                checked = true;
            }

            return <div key={v.key}
                onClick={(e) => {
                    onChange(type, v.key, !selected.some(si => si.key == v.key))
                    e.preventDefault()
                    e.stopPropagation()
                }}>
                <Checkbox key={v.key} className={className} checked={checked}
                >{v.r}</Checkbox>
            </div>
        })}
    </div>
}

export interface FilterKeyItem {
    key: string;
    type: AvailableFilters;
    value: boolean;
}

let timer: any;

// Для фильтрации по задачам. У которых нет меток
export const NO_TAGS = "NO_TAGS";
// Для фильтрации по задачам. У которых нет участников
export const NO_MEMBERS = "NO_MEMBERS";

export const taskViewFilterContext=makeVar<FilterKeyItem[]>([])

function TaskViewFilter({
                            tasksView,
                            tasks,
                            initialState,
                            onFilterChanged,
                            showFilters,
                            disable,
                            excludeFlags = [AvailableFlags.iAmResponsible]
                        }: TaskViewFilteredProps) {
    const btnRef = useRef<HTMLButtonElement>(null)
    const {token} = theme.useToken()
    const [opened, setOpened] = useState(false)
    const [filterEnabled, setFilterEnabled] = useState(false)
    const [filterKeyItems, setFilterKeyItems] = useState<FilterKeyItem[]>(initialState ?? [])
    const {t} = useTranslation();

    showFilters = showFilters ?? {
        flags: true, members: true, projects: true, usersUnions: true, tags: true, priority: true
    } as FiltersType

    const allP = useReactiveVar(allProjects);
    const allUnions = useReactiveVar(allUsersUnions);
    const personalTags = useReactiveVar(allPersonalTags);

    // Надо собрать все теги с проектов и команд, которые есть в задачах и отобразить в списке тегов
    const tags = useMemo(() => {
        let filterTags = initialState?.filter(v=>v.type == AvailableFilters.tags).map(v=>v.key) ?? [];

        const ttt = tasks.map((t) => {
            const proj = allP.projects.filter((p) => p.id === t.project.id).flatMap(p => p.tags)
            const unions = allUnions.unions.filter((u) => u.id === t.project.usersUnionAsOwner?.id).flatMap(u => u.tags)
            const allTags = proj.concat(unions).concat(personalTags.tags);
            return allTags.filter((ct) => t.tags.some(tt => tt === ct.id) || filterTags.includes(ct.id))
        }).flat().filter(onlyUnique).map(key => {
            return {
                key: key.id, r: <TagDisplayItem tag={key} removable={false} onClick={() => {
                    onChangeFilter("tags", key.id, true)
                }}/>
            }
        })

        ttt.push({key: NO_TAGS, r: <div>{t('filterFlags.' + NO_TAGS)}</div>})

        return ttt;
    }, [tasks, initialState])

    const flags = useMemo(() => {
        return [AvailableFlags.onlyMy, AvailableFlags.iAmResponsible, AvailableFlags.includeResolved, AvailableFlags.includeUnresolved]
            .filter(v => !excludeFlags?.some(vv => vv == v))
            .map(key => {
            return {key, r: <div>{t('filterFlags.' + key)}</div>}
        })
    }, []);

    useEffect(() => {
        setFilterEnabled(filterKeyItems.length > 0);

        // таймер чтобы не сразу применялся фильтр, т.к. это может сказаться на производительности
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            if (onFilterChanged) {
                onFilterChanged(filterKeyItems)
            }
        }, 500)
    }, [filterKeyItems]);

    const priorityItems = useMemo(() => {
        return allPriorities(token).reverse().map(priority => {
            return {key: priority.title, r: <div><TaskImportanceElement priority={priority}/></div>}
        })
    }, []);

    const members = useMemo(() => {
        const ttt = tasks.map((t) => t.members).flat().map(t => t.user)
            .filter(onlyUnique)
            .map(key => {
                // TODO: универсальный компонент для имени пользователя с аватаркой
                return {
                    key: key.id, r: <div>
                        <UserAvatar user={key} size={"16"} addTitle={true}/>
                    </div>
                }
            })

        ttt.push({key: NO_MEMBERS, r: <div>{t('filterFlags.' + NO_MEMBERS)}</div>})

        return ttt;
    }, [tasks])


    const teams = useMemo(() => {
        return tasks
            .map(t => t.project.usersUnionAsOwner)
            .filter(onlyUnique)
            .filter(v => v != undefined)
            .map(uu => ({
                key: uu?.id as any,
                r: <div className={"uu-title"}><UsersUnionsAvatar size={"16"} uu={uu}/>{uu?.title}</div>
            }))
    }, [tasks]);

    const projects = useMemo(() => {
        return tasks
            .map(t => t.project)
            .filter(onlyUnique)
            .filter(v => v != undefined)
            .map(p => {
                return {key: p?.id as any, r: <div>{p.title}</div>}
            })
    }, [tasks]);

    if (tags.length == 0) showFilters[AvailableFilters.tags] = false;
    if (members.length == 0) showFilters[AvailableFilters.members] = false;
    if (teams.length < 2) showFilters[AvailableFilters.usersUnions] = false;
    if (projects.length < 2) showFilters[AvailableFilters.projects] = false;

    const onChangeFilter = (type: string, key: string | string[] | undefined, value: boolean) => {
        const v = {type, key, value} as FilterKeyItem;
        const filterByType = filterKeyItems.filter(i => i.type == type);
        const exists = filterByType.find(i => i.key === key || (key?.indexOf(i.key) ?? -1) > -1);
        if (value && !exists)
            setFilterKeyItems([...filterKeyItems, v])
        else if (!value && exists) {
            setFilterKeyItems(filterKeyItems.filter(i => i.key !== exists.key && i.type !== exists.type));
        }
    }

    let columnWidth = "50%"
    if (showFilters.projects && showFilters.tags || showFilters.usersUnions && showFilters.priority)
        columnWidth = "33%"
    if (isMobile)
        columnWidth = "100%"

    const itemsFirst = [];
    if (showFilters.flags)
        itemsFirst.push(<FilterItems items={flags} title={t('filterShow')} type={AvailableFilters.flags}
                                     selectedItems={filterKeyItems}
                                     onChange={onChangeFilter}/>)
    if (showFilters.priority)
        itemsFirst.push(<FilterItems items={priorityItems} title={t('filterPriority')} type={AvailableFilters.priority}
                                     selectedItems={filterKeyItems}
                                     onChange={onChangeFilter}/>)
    if (showFilters.usersUnions)
        itemsFirst.push(<FilterItems items={teams} title={t('filterUsersUnion')} type={AvailableFilters.usersUnions}
                                     selectedItems={filterKeyItems}
                                     onChange={onChangeFilter}/>)

    const itemsSecond = [];
    if (showFilters.members)
        itemsSecond.push(<FilterItems items={members} title={t('filterTaskMember')} type={AvailableFilters.members}
                                      selectedItems={filterKeyItems}
                                      onChange={onChangeFilter}/>)
    if (showFilters.tags)
        itemsSecond.push(<FilterItems items={tags} title={t('filterTags')} type={AvailableFilters.tags}
                                      selectedItems={filterKeyItems}
                                      onChange={onChangeFilter}/>)
    if (showFilters.projects)
        itemsSecond.push(<FilterItems items={projects} title={t('filterProject')} type={AvailableFilters.projects}
                                      selectedItems={filterKeyItems}
                                      onChange={onChangeFilter}/>)

    //Обновление контекста фильтра
    useEffect(()=>{
        taskViewFilterContext(filterKeyItems)
    }, [filterKeyItems])
    
    return <>
        <Btn
            $filterEnabled={filterEnabled.toString()}
            iconPosition={"start"}
            // styles={{icon: {display: "flex", alignItems: 'center'}}}
            icon={<Icon size={"24"} icon={<IconFilter/>}/>}
            onClick={() => setOpened(true)}
            ref={btnRef}
            disabled={disable}
        >
            {!isMobile && <div>{t('filter')}</div>}
            <IconArrowDown/>
        </Btn>
        {opened && <Dropdowned opened={opened} onClose={() => setOpened(false)} anchor={btnRef}>
	        <DropdownedContent $columnWidth={columnWidth} $isMobile={isMobile.toString()}>
				    <div className={'row'}>
									    <div style={{flexGrow: 1}} className={"title"}>{t('filters')}</div>
									    <Button type={"link"} onClick={() => {
                          setFilterKeyItems([])
                      }}>{t('filtersClear')}</Button>
				    </div>
              {!isMobile && <div className={"row first"}>{itemsFirst}</div>}
              {!isMobile && <div className={"row second"}>{itemsSecond}</div>}
              {isMobile && itemsFirst}
              {isMobile && itemsSecond}
			    </DropdownedContent>
		    </Dropdowned>}
    </>
}

export default TaskViewFilter
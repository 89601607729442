import React, {useState} from 'react';
import {Button, Col, Divider, Form, Input, Row, Typography} from 'antd';
import {UserOutlined, LockOutlined, MailOutlined} from "@ant-design/icons";
import {saveToken} from "./authContext";
import {
    UserSignUpInput,
    useUserRestorePasswordMutation,
    useUserRestorePasswordSendLinkMutation
} from "../../generated-types";
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from "../../Components/Spinner";
import { Link } from '../../Components/Link';

const { Text } = Typography;

const initState: UserSignUpInput  = {username: "", password: "", email: ""};

const RestorePassword: React.FC = () => {
    const { link } = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [state, setState] = useState(initState);
    const [userRestorePasswordSendLink, s1] = useUserRestorePasswordSendLinkMutation({
        onCompleted: () => {
            //TODO: показать какое-нибудь подсказку, что надо проверить почту
        }
    });
    const [userRestorePassword, s2] = useUserRestorePasswordMutation({
        onCompleted: (data) => {
            saveToken(data.userRestorePassword.token);
            navigate("/")
        }
    });

    const loading = s1.loading || s2.loading;

    if (link == null) {
        return (
            <Row justify="center" align="top" style={{marginTop: "50px"}}>
                <Col xs={24} sm={20} md={12} lg={8}>
                    <Form
                        name="basic"
                        initialValues={state}
                        onFinish={async (data: UserSignUpInput) => {
                            setState(data);
                            await userRestorePasswordSendLink({variables: {email: data.email}})
                        }}
                        // onFinishFailed={undefined}
                        autoComplete="off"
                    >
                        <Divider></Divider>
                        <Text>{t("auth.sendRestoreLinkTitle")}</Text>
                        <Divider></Divider>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: t("auth.inputEmail") as string, type: "email"}]}
                        >
                            <Input
                                prefix={<MailOutlined style={{color: "grey"}} rev={undefined} />}
                                placeholder={t("auth.email") as string}
                            />
                        </Form.Item>
                        <Divider></Divider>

                        <Row justify="center" style={{marginBottom: "10px"}}>
                            <Button type="primary" htmlType="submit" disabled={loading}>
                                {t("auth.sendRestoreLink")}
                            </Button>
                            <Link to={"/signIn"}>
                                <Button type="link" disabled={loading}>
                                    {t("auth.signIn")}
                                </Button>
                            </Link>
                        </Row>
                        <Row justify="center">
                            <Spinner loading={loading} />
                            {/*{error != null && <Alert type="error" message={error.message} banner />}*/}
                        </Row>
                    </Form>
                </Col>
            </Row>
        )

    }


    return (
        <Row justify="center" align="top" style={{marginTop: "50px"}}>
            <Col xs={24} sm={20} md={12} lg={8}>
                <Form
                    name="basic"
                    initialValues={state}
                    onFinish={async (data: UserSignUpInput) => {
                        setState(data);
                        await userRestorePassword({variables: {data: {
                            link, password: data.password, username: data.username//, email: data.email
                        }}})
                    }}
                    // onFinishFailed={undefined}
                    autoComplete="off"
                >
                    <Divider></Divider>
                    <Text>{t("auth.restorePasswordTitle")}</Text>
                    <Divider></Divider>
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: t("auth.inputUserName") as string }]}
                    >
                        <Input
                            prefix={<UserOutlined rev={undefined} style={{color: "grey"}} />}
                            placeholder={t("auth.username") as string}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t("auth.inputPassword") as string}]}
                    >
                        <Input.Password
                            prefix={<LockOutlined rev={undefined} />}
                            placeholder={t("auth.password") as string}
                        />
                    </Form.Item>

                    {/*<Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>*/}
                    {/*    <Checkbox>Remember me</Checkbox>*/}
                    {/*</Form.Item>*/}

                    <Row justify="center" style={{marginBottom: "10px"}}>
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            {t("auth.restorePassword")}
                        </Button>
                    </Row>
                    <Row justify="center">
                        <Spinner loading={loading} />
                        {/*{error != null && <Alert type="error" message={error.message} banner />}*/}
                    </Row>
            </Form>
        </Col>
        </Row>
    )
};

export default RestorePassword;

import React, {useRef, useState} from 'react';
import {Card, Empty, List, Tabs} from 'antd';
import ConnectYandexDisk from "./ConnectYandexDisk";
import {ExternalFile, useAttachFolderMutation, useFilesInUsersUnionQuery, UsersUnion} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import RefetchList from "../../queries/RefetchesList";
import AttachFilesButton, {FilesList} from "./AttachFilesButton";
import {editTaskContext} from "../Tasks/TaskEditor";
import {CenterJustify} from "../Dropdowned";
import FolderCard from './FolderCard';


interface UsersUnionFilesListProps {
    usersUnion: UsersUnion,
    isAdmin: boolean
}

const UsersUnionFilesList: React.FC<UsersUnionFilesListProps> = ({usersUnion, isAdmin}) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState(false);

    const [attachFolder] = useAttachFolderMutation({
        onCompleted: () => {
            setInProgress(false)
            // m.destroy();
        },
        refetchQueries: [RefetchList.UsersUnion, RefetchList.YandexDiskGetFolderItems]
    })

    const onFolderChanged = (items: ExternalFile[])=>{
        setInProgress(true)
        // TODO: не очень-то получилось
        attachFolder({
            variables: {
                path: items[0].path,
                usersUnionId: usersUnion.id,
            }
        })
    };

    return <FolderCard source={usersUnion} isAdmin={isAdmin} onFolderChanged={onFolderChanged} loading={inProgress}/>

};

export default UsersUnionFilesList;
import React, { useEffect, useState } from "react";
import {
    ChildTaskFragmentDoc,
    Project,
    Task,
    useTaskChangeImportanceMutation,
    useTaskChangeTitleMutation
} from "../../generated-types";
import { ConfigProvider, List, Progress, Space, theme } from "antd";
import { BlockOutlined, VerticalAlignMiddleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AddTaskInput from "./AddTaskInput";
import TaskRemoveBtn from "./TaskRemoveBtn";
import TaskResolved from "./TaskResolved";
import Paragraph from "antd/es/typography/Paragraph";
import { useDrag, useDrop } from "react-dnd";
import { isMobile } from "react-device-detect";
import { makeVar, ReactiveVar } from "@apollo/client";
import PointDivider from "../PointDivider";
import { IconDragTask } from "../Icon/IconDragTask";
import styled from "styled-components";

interface TaskCheckListProps {
    parentTask: Task,
    project: Project,
    childTasks: Task[]
}

export const droppedOnTask: ReactiveVar<Task | null> = makeVar<Task | null>(null);

const CHECKLISTITEM = "CHECKLISTITEM"

const TaskCheckListItemStyled=styled(List.Item)<{isOver: boolean}>`

    padding: 0px !important;
    border: ${({isOver, theme})=>isOver? `1px solid ${theme.colors.ui.accent}` : 'none'} !important;
    align-items: center;
    gap: 8px;
    transition: all .3s ease-in-out;

    &:hover{
        background-color: ${({theme})=>theme.colors.ui.bgLight};
        box-shadow: unset;
    }

    .ant-input{
        background-color: unset;
    }
`
const TaskCheckListItem = ({ taskItem, onMoveItem, index }: {
    index: number,
    taskItem: Task,
    onMoveItem: (t1: Task, t2: Task) => void
}) => {
    
    const [, dragRef] = useDrag(
        () => ({
            type: CHECKLISTITEM,
            item: taskItem,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 2 : 1
            })
        }),
        []
    )

    const [{ isOver }, drop] = useDrop(() => ({
        accept: CHECKLISTITEM,
        drop: (taskDropped: Task, monitor: any) => {
            if (!monitor.didDrop()) {
                onMoveItem(taskDropped, taskItem)
                droppedOnTask(taskItem)
            }
        },

        collect: (monitor) => ({
            isOver: !isMobile && monitor.isOver(),
            canDrop: !isMobile && monitor.canDrop()
        })
    }))

    const [changedTitle, setChangedTitle] = useState(taskItem.title);

    const [taskChangeTitle] = useTaskChangeTitleMutation({
        // optimisticResponse: ({id, task: ttt}) => {
        //     const members = ttt.members.map(m => ({
        //         id: m.userId,
        //         memberType: m.memberType,
        //         user: project.members.find(pm => pm.user.id == m.userId)?.user
        //     } as any));
        //
        //     // Почему-то не пересчитывается счетчик файлов на карточке на доске.
        //     const files = ttt.files
        //     const {title, tags, status, endTime, endDate, startTime, startDate, description, importance} = ttt;
        //     const res = {
        //         taskSave: {
        //             ...task, title, tags, status, endTime, endDate,
        //             startTime, startDate, description, importance, members, files
        //         }
        //     } as TaskSaveMutation;
        //     return res;
        // },
        // TODO: эта штука не работает для моих задач. Из-за этого пришлось впендюрить pollInterval в TaskViewList
        // refetchQueries: [RefetchesList.TasksByViewId],
    });


    useEffect(() => {
        if (taskItem.title != changedTitle) {
            taskChangeTitle({
                variables: {
                    id: taskItem.id,
                    title: changedTitle
                }
            })
        }
    }, [changedTitle]);


    return (taskItem &&
        <TaskCheckListItemStyled
            isOver={isOver}
            ref={drop}
            actions={[<TaskRemoveBtn
                taskTitle={taskItem.title}
                disabled={
                    taskItem.parentTask?.project?.paymentAccount?.isBlocked
                    ||
                    taskItem.parentTask?.project?.archived
                }
                id={taskItem.id}
                onChange={() => {
                }} />]}
        >
            <div ref={dragRef} style={{height: 16, opacity: 0.5}}><IconDragTask/></div>
            <TaskResolved
                small
                disabled={
                    taskItem.parentTask?.project?.paymentAccount?.isBlocked
                    ||
                    taskItem.parentTask?.project?.archived
                }

                id={taskItem.id}
                value={taskItem.resolved}
                key={"" + taskItem.resolved} />
            <Paragraph editable={{ onChange: setChangedTitle, triggerType: ["text"] }}
                style={{
                    textDecoration: taskItem.resolved ? "line-through" : undefined,
                    opacity: taskItem.resolved? 0.5 : 1,
                    flex: 1,
                    margin: 0
                    
                }}>
                {changedTitle}
            </Paragraph>
        </TaskCheckListItemStyled>
    )
}

const byImportance = (p1: Task, p2: Task) => {
    if (p1.importance != p2.importance) return p1.importance < p2.importance ? 1 : -1
    return p1.id > p2.id ? 1 : -1
};


const TaskCheckListContainer=styled.div`
        padding: 16px;
        border-radius: 16px;
        background-color: ${({theme})=>theme.colors.ui.bgLight2};
        width: 100%;

        .ant-input-affix-wrapper {
            background-color: ${({theme})=>theme.colors.ui.bgLight};
            padding: 8px 16px;
            margin: 12px 0px;

            &:hover,:focus {
                background-color: ${({theme})=>theme.colors.ui.bgLight3};
            }
        }
`

const TaskCheckList = ({ childTasks: tasks, project, parentTask }: TaskCheckListProps) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const childTasks = tasks ? [...tasks].sort(byImportance) : []

    const [taskChangeImportance] = useTaskChangeImportanceMutation({
        onCompleted: () => {
        }
    })

    const onMoveItem = (t1: Task, t2: Task) => {
        // TODO: тут надо правильно вычислять важность причем для всех остальных задач - тоже
        const importance = t2.importance + 1;

        taskChangeImportance({
            variables: {
                id: t1.id, importance
            },
            update: (cache, r) => {
                const iid = cache.identify(t1);

                const baseQ = {
                    id: iid,
                    fragment: ChildTaskFragmentDoc,
                    fragmentName: "childTask"
                };

                const tsk = cache.readFragment(baseQ) as Task

                if (tsk)
                    cache.updateFragment(baseQ, () => ({ ...tsk, importance }))
            },
            optimisticResponse: ({ id, importance }) => {
                return {
                    taskChangeImportance: {
                        id: t1.id, importance: importance
                    }
                };
            }

        })
    }
  
    const resolvedTasksLength = childTasks.filter(ct => ct.resolved).length
    const childTasksLength = childTasks.length
    return <TaskCheckListContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                    <span style={{ opacity: 0.5 }}>
                        {`${t('task.childTasks')}`}
                    </span>
                    <PointDivider value={childTasksLength ?? 0} />
                </span>
                {resolvedTasksLength > 0 && <span>
                    {resolvedTasksLength}/{childTasksLength}
                </span>}
            </div>
            {resolvedTasksLength > 0 && <Progress size={{ height: 4 }} percent={resolvedTasksLength / childTasksLength * 100} strokeColor={[token.colors.ui.accent, token.colors.ui.bgLight]} showInfo={false} />}
            <AddTaskInput
                enterButtonTestId="addSubTask-btn"
                placeholder={t('task.addChildTask') as string} ctx={{
                projectId: project.id,
                parentId: parentTask.id,
                taskId: undefined
                //TODO: хз.... что ставить?
                // tasksViewColumnId: task.,
                // taskViewId: taskView.id,
            }} onCompleted={(newChild: Task) => {
                console.log(newChild)
            }} />

            {childTasks && childTasks.length > 0 &&
                <List size={"small"} dataSource={childTasks}
                    renderItem={(child, i) => <TaskCheckListItem index={i} key={child.id + "_" + child.importance}
                        taskItem={{ ...child, importance: i }}
                        onMoveItem={onMoveItem}></TaskCheckListItem>}>
                </List>}
        </TaskCheckListContainer>
}

export default TaskCheckList
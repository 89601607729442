import React, {useEffect, useState} from 'react'
import {Input} from 'antd'
import {useTranslation} from "react-i18next";
import {Task, TaskMemberType, TasksView, TasksViewColumn, useTasksByViewIdLazyQuery,} from "../../../generated-types";
import TasksList from "../TasksList";
import {SearchOutlined} from '@ant-design/icons';
import TasksBoard from "../TasksBoard";
import TasksFastList from "../TasksFastList";
import TasksCalendar from "../TasksCalendar";
import {pollInterval} from "../../../ApolloClient";
import IconStateSwitcher, {IconState} from "../IconStateSwitcher";
import {IconBoards} from "../../Icon/IconBoards";
import {IconTasks} from "../../Icon/IconTasks";
import {IconCalend} from "../../Icon/IconCalend";
import TaskViewSortDropdown, {DIRECTION, SORTFIELD} from "./TaskViewSortDropdown";
import TaskViewFilter, { AvailableFilters, AvailableFlags, FilterKeyItem, NO_MEMBERS, NO_TAGS } from './TaskViewFilter';
import {isMobile} from "react-device-detect";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../../routes/Auth/authContext";
import {taskPriorityByName} from "../TaskImportance";
import SearchInput from '../../SearchInput';

interface TaskViewListProps {
    tasksView: TasksView
    allowCreate?: boolean
    showProjectTitle?: boolean
    showHeader?: boolean
    defaultDisplayView?: DisplayView
    defaultDisplayParams?: TaskViewDisplayParams
    onTasksFiltered?: (filtered: number, total: number) => void
}

export enum DisplayView {
    list,
    board,
    fastList,
    calendar
}

export const tasksSorter = (sort: SORTFIELD, direction: DIRECTION, p1: Task, p2: Task, tasksView: TasksView): number => {
    if (sort === SORTFIELD.responsible) {
        let v1 = (p1.members.find(m => m.memberType === TaskMemberType.Responsible))?.user.username;
        let v2 = (p2.members.find(m => m.memberType === TaskMemberType.Responsible))?.user.username;
        return (v1 ?? "") > (v2 ?? "") ? direction : -direction
    }

    if (sort === SORTFIELD.title) {
        let v1 = p1.title;
        let v2 = p2.title;
        return (v1 ?? "") > (v2 ?? "") ? direction : -direction
    }

    if (sort === SORTFIELD.position) {
        let colpos1 = p1.tasksViewsPositions.find(v => v.tasksViewId === tasksView.id);
        let colpos2 = p2.tasksViewsPositions.find(v => v.tasksViewId === tasksView.id);

        let res = 0;

        if (colpos1?.tasksViewColumnId === colpos2?.tasksViewColumnId)
            res = (colpos1?.position ?? 0) > (colpos2?.position ?? 0) ? direction : -direction;

        return res;
    }

    if (sort === SORTFIELD.importance) {
        let v1 = p1.importance;
        let v2 = p2.importance;
        return (v1 ?? 0) > (v2 ?? 0) ? direction : -direction
    }

    if (sort === SORTFIELD.createDate) {
        let v1 = p1.startTime; // TODO: нужно передавать дату создания задачи отдельно
        let v2 = p2.startTime;
        return (v1 ?? 0) > (v2 ?? 0) ? direction : -direction
    }

    if (sort === SORTFIELD.deadline) {
        let v1 = p1.endDate;
        let v2 = p2.endDate;
        return (v1 ?? 0) > (v2 ?? 0) ? direction : -direction
    }

    if (sort === SORTFIELD.column) {
        let clmns = tasksView.columns ?? []
        let v1 = clmns.find(clmn => (clmn?.title === p1.status)) as TasksViewColumn;
        let v2 = clmns.find(clmn => (clmn?.title === p2.status)) as TasksViewColumn;
        return clmns.indexOf(v1) > clmns.indexOf(v2) ? direction : -direction
    }

    return p1.id > p2.id ? 1 : -1
};

interface TaskViewDisplayParams {
    displayView: DisplayView,
    sort: SORTFIELD,
    filter: FilterKeyItem[],
    sortDirection: DIRECTION,
    includeResolved: boolean,
    iAmResponsible: boolean
}

// TODO: настройки конкретного отображения задач надо сохранять на сервере для конкретного пользователя и оттуда же считывать
const getDefaultTaskViewDisplayParams = (id: String, defaultDisplayView: DisplayView): TaskViewDisplayParams => {
    const txt = localStorage.getItem(`taskView${id}`);

    let res = {
        displayView: defaultDisplayView,
        sort: SORTFIELD.column,
        filter: [],
        iAmResponsible: false,
        includeResolved: false,
        sortDirection: DIRECTION.ASC,
    } as TaskViewDisplayParams;

    if (txt) {
        const saved = JSON.parse(txt) as TaskViewDisplayParams;

        if (saved) {
            res = saved
        }
    }

    return res
}

const saveTaskViewDisplayParams = (id: String, p: TaskViewDisplayParams) => {
    localStorage.setItem(`taskView${id}`, JSON.stringify(p));
}

function TaskViewList({
                          tasksView, allowCreate = false, showProjectTitle = true, showHeader = true,
                          defaultDisplayView = DisplayView.board,
                          defaultDisplayParams = undefined,
                          onTasksFiltered
                      }: TaskViewListProps) {
    const {t} = useTranslation();
    const authInfo = useReactiveVar(authState);
    const [displayParams, setDisplayParams] = useState(() => {
        const res = defaultDisplayParams ?? getDefaultTaskViewDisplayParams(tasksView.id, defaultDisplayView);
        if (!res.filter)
            res.filter = [] as FilterKeyItem[]
        return res;
    });
    const [search, setSearch] = useState<string | undefined>();

    const [tasksByViewId, {data}] = useTasksByViewIdLazyQuery({
        pollInterval // TODO: переделать на подписку
    })

    const [tasks, setTasks] = useState((data?.tasks ?? []) as Task[])

    useEffect(() => {
        tasksByViewId({
            fetchPolicy: "network-only",
            variables: {
                tasksViewId: tasksView.id,
                includeResolved: displayParams.includeResolved,
                iAmResponsible: displayParams.iAmResponsible
            }
        })
    }, [tasksView?.id, displayParams.includeResolved, displayParams.iAmResponsible]);

    useEffect(() => {
        let newTasks: Task[] = (data?.tasks ?? []) as Task[];

        const flags = displayParams.filter.filter(f => f.type == AvailableFilters.flags);
        if (flags.length > 0) {
            const includeResolved = flags.find(v => v.key == AvailableFlags.includeResolved);
            const includeUnresolved = flags.find(v => v.key == AvailableFlags.includeUnresolved);
            if (includeResolved || includeResolved)
                newTasks = newTasks.filter(t => t.resolved && includeResolved || !t.resolved && includeUnresolved)

            const onlyMy = flags.find(v => v.key == AvailableFlags.onlyMy);
            if (onlyMy) {
                newTasks = newTasks.filter(t => t.members.some(m => m.user.id == authInfo.user.id))
            }
        }

        if (displayParams.filter.filter(f => f.type == AvailableFilters.tags).length > 0) {
            const ff = displayParams.filter.filter(f => f.type == AvailableFilters.tags).map(t => t.key);
            newTasks = newTasks.filter(t => !t.tags.length && ff.some(v=>v==NO_TAGS) || t.tags.some(tg => ff.some(f => tg == f)))
        }

        if (displayParams.filter.filter(f => f.type == AvailableFilters.members).length > 0) {
            const ff = displayParams.filter.filter(f => f.type == AvailableFilters.members).map(t => t.key);
            newTasks = newTasks.filter(t => !t.members.length && ff.some(v=>v==NO_MEMBERS) ||  t.members.some(m => ff.some(f => m.user.id == f)))
        }

        if (displayParams.filter.filter(f => f.type == AvailableFilters.projects).length > 0) {
            const ff = displayParams.filter.filter(f => f.type == AvailableFilters.projects).map(t => t.key);
            newTasks = newTasks.filter(t => ff.some(f => f == t.project.id))
        }

        if (displayParams.filter.filter(f => f.type == AvailableFilters.usersUnions).length > 0) {
            const ff = displayParams.filter.filter(f => f.type == AvailableFilters.usersUnions).map(t => t.key);
            newTasks = newTasks.filter(t => ff.some(f => f == t.project.usersUnionAsOwner?.id))
        }

        if (displayParams.filter.filter(f => f.type == AvailableFilters.priority).length > 0) {
            const ff = displayParams.filter.filter(f => f.type == AvailableFilters.priority)
                .map(t => taskPriorityByName(t.key));

            newTasks = newTasks.filter(t => {
                return ff.some(f => {
                    return f == t.importance || f == 0 && !t.importance;
                })
            })
        }

        if (search?.length && search) {
            newTasks = newTasks.filter((task) => (
                task.project.title.toUpperCase().includes(search) ||
                task.project.usersUnionAsOwner?.title.toUpperCase().includes(search) ||
                task.title.toUpperCase().includes(search) ||
                task.members.some((m) => m.user.username.toUpperCase().includes(search) || m.user.fullName?.toUpperCase().includes(search)) ||
                "#" + task.number == search
            ))
        }

        newTasks = newTasks.map(v => v).sort((p1, p2) => tasksSorter(displayParams.sort, displayParams.sortDirection, p1, p2, tasksView))

        setTasks(newTasks)
    }, [search, displayParams, data]);

    useEffect(() => {
        if (onTasksFiltered) onTasksFiltered(tasks.length, (data?.tasks?.length ?? 0))
    }, [tasks]);

    const onSearch = (value: string) => {
        setSearch(value)
    }

    useEffect(() => {
        // TODO: надо разобраться, как не вызывать сохранение сразу после загрузки из локалстореджа
        if (!defaultDisplayParams)
            saveTaskViewDisplayParams(tasksView.id, displayParams)
    }, [displayParams]);


    let view;
    switch (displayParams.displayView) {
        case DisplayView.list:
            view = <TasksList tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle}/>
            break;
        case DisplayView.fastList:
            view = <TasksFastList tasks={tasks} taskView={tasksView}/>
            break;
        case DisplayView.board:
            view = <TasksBoard tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle} allowCreate={!showProjectTitle}/>
            break;
        case DisplayView.calendar:
            view = <TasksCalendar tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle}/>
            break;
        default:
            view = <TasksBoard tasks={tasks} taskView={tasksView} showProjectTitle={showProjectTitle} allowCreate={!showProjectTitle}/>
            break;
    }

    let style = {padding: "16px 40px 0px 40px", display: "flex", flexDirection: "row", gap: "8px", height: "56px"};
    if (isMobile) {
        style.padding = "3px 5px 3px 5px";
        style.height = "40px";
    }

    return <div style={{width: "100%", flexGrow: 1, display: "flex", flexDirection: "column", height: "100%", overflow: "hidden", maxHeight: '100%'}}>
        {showHeader && <div
	        style={style as any}>
			    <IconStateSwitcher
				    defaultValue={displayParams.displayView}
				    onChange={(v => {
                setDisplayParams({...displayParams, displayView: v})
            })}
								    title={isMobile ? undefined : t('events.view')}
				    items={[
                {
                    state: DisplayView.board,
                    icon: <IconBoards/>,
                    tooltipText: t('task.displayType.' + DisplayView[DisplayView.board])
                },
                {
                    state: DisplayView.list,
                    icon: <IconTasks/>,
                    tooltipText: t('task.displayType.' + DisplayView[DisplayView.list])
                },
                {
                    state: DisplayView.calendar,
                    icon: <IconCalend/>,
                    tooltipText: t('task.displayType.' + DisplayView[DisplayView.calendar])
                },
            ] as IconState<DisplayView>[]
            }
			    />

            {<TaskViewFilter tasksView={tasksView} tasks={(data?.tasks ?? []) as Task[]}
                             initialState={displayParams.filter}
                             disable={!data}
		                                 showFilters={{
                                         projects: true,
                                         usersUnions: true,
                                         tags: true,
                                         flags: true,
                                         members: true,
                                         priority: true
                                     }}
                             onFilterChanged={(filter: FilterKeyItem[]) => {
                                        const includeResolved = filter.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.includeResolved);
                                        const iAmResponsible = filter.some(v => v.type == AvailableFilters.flags && v.key == AvailableFlags.onlyMy);
                                        setDisplayParams({...displayParams, includeResolved, iAmResponsible, filter})
                                     }}/>}

                    <SearchInput placeHolder={t('task.search') as string} onSearch={onSearch}/>

            {displayParams.displayView !== DisplayView.board && displayParams.displayView !== DisplayView.calendar &&
					    <TaskViewSortDropdown taskView={tasksView}
					                          sort={displayParams.sort} direction={displayParams.sortDirection}
					                          onChange={(sort: SORTFIELD, sortDirection: DIRECTION) => {
                                        setDisplayParams({...displayParams, sort, sortDirection})
                                    }}/>
            }
		    </div>}
        {view}
    </div>
}

export default TaskViewList
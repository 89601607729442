import styled, {css} from "styled-components";
import React, { useMemo, useRef, useState } from 'react';
import {Button, message, notification, Popconfirm, Tabs, theme, Tooltip} from "antd";
import {ItemType} from "antd/es/menu/interface";
import Tagger from "../Tagger/Tagger";
import {
    AccessLevel,
    Project,
    usePlugToUnionProjectMutation,
    useProjectAttachTagsMutation,
    useProjectLeaveMutation
} from "../../generated-types";
import {generatePath, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {addTaskContext} from "../Tasks/TaskNew";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import {isMobile} from "react-device-detect";
import type {GlobalToken} from "antd/es/theme/interface";
import ProjectAvatar from "./ProjectAvatar";
import ProjectFavoriteButton from "./ProjectFavoriteButton";
import UsersUnionsAvatar from "../UsersUnion/UsersUnionsAvatar";
import UserAvatar from "../User/UserAvatar";
import {routes} from "../../AppRouter";
import {IconRock} from "../Icon/IconRock";
import {IconTasks} from "../Icon/IconTasks";
import {IconDocs} from "../Icon/IconDocs";
import {IconTeam} from "../Icon/IconTeam";
import {IconSetting} from "../Icon/IconSetting";
import PointDivider from "../PointDivider";
import {IconMenu} from "../Icon/IconMenu";
import Dropdowned from "../Dropdowned";
import {Icon} from "../Icon/Icon";
import ProjectChangeColumn from "./ProjectChangeColumn";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import { TabsStyled } from "../AntCastomComponents/Tabs.styled";


export const DropdownedMenuStyled = styled.div<{ $isMobile: boolean, $token: GlobalToken }>`

    padding: 16px 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    .inner-container {
        width: 100%;
        display: flex;
        align-items: center;
        //padding: 2px 10px;
        border-radius: 16px;
        background-color: ${({$token}) => $token.colors.ui.bgLight};
        padding: 10px 16px;
        //margin-bottom: 10px;


        & > div {
            width: 100%;
            display: flex;
            align-items: center;

            .rightAlign {
                flex-grow: 1;
                text-align: right;
            }
        }
    }
`

export const ProjectHeaderStyled = styled.div<{ $isMobile: boolean, $token: GlobalToken }>`
    background-color: ${({$token}) => $token.colors.ui.bgLight2};
    padding-block: 0;
    padding: 0 5px;

    .title {
        display: flex;
        align-items: center;
        //maxWidth: isMobile ? "100vw" : undefined,
        width: 100%;
    }

    .board-selector-holder {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;

        button {
            height: 24px;
            background-color: ${({$token}) => $token.colors.ui.bgLight2};
            border: 1px solid ${({$token}) => $token.colors.ui.bgLight3}
        }
    }

    .top-holder {
        display: flex;
        width: 100%;
        align-items: center;

        ${({$isMobile}) => !$isMobile && css`
            padding: 16px 40px 8px 40px;
            gap: 32px;
        `};

        .union-holder {
            font-size: 12px;
        }

        .usercard-button {
            font-size: 100%;
            //padding: 2px;
            gap: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;

            .ant-avatar {
                width: 24px;
                height: 24px;
                font-size: 1em;
            }
        }

        .rightAlign {
            display: flex;
            align-items: center;
            gap: 3px;
        }

        .usercard-button:hover {
            border-radius: 4px;
            background-color: ${({$token}) => $token.colors.ui.bgLight}; // TODO: какой цвет?
        }

        .buttons-holder {
            margin-left: auto;

            button {
                height: 24px;
            }
        }

        .project-favorite-button {
            align-items: center;
            display: flex;
        }

        .add-tags-button {
            background-color: ${({$token}) => $token.colors.ui.bgLight};
            color: ${({$token}) => $token.colors.font.primary};
            height: 24px;
            font-size: 90%;
            padding: 4px 8px;

            .ant-btn-icon > svg {
                width: 16px;
                height: 16px;
            }
        }

        .tagger-holder {
            flex-grow: 1;

            .add-tags-button {
                color: ${({$token}) => $token.colors.font.primary};

                svg {
                    color: ${({$token}) => $token.colors.font.primary};
                }
            }
        }

        .tagger-holder > div {
            display: flex;
            align-items: center;

            .tag-item {
                margin-bottom: 0;
            }
        }
    }

    .bottom-holder {
        display: flex;
        width: 100%;
        height: 44px;
        justify-content: space-between;
        ${({$isMobile}) => !$isMobile && css`
            padding: 0px 40px;
        `};
    }

    .tabs-holder {
        min-width: 0px;
        /*width: ${p => p.theme.isMobile ? `100vw` : `50%`};*/

        /*@media (min-width: 1350px) {
            .ant-tabs-nav-wrap {
                flex-direction: row-reverse;
            }
        }*/
    }

    .title-holder {
        display: flex;
        flex-grow: 1;
        align-items: center;
        font-size: 160%;
        margin-right: 10px;
        width: 50%;

        .project-favorite-button .ant-btn-icon {
            font-size: 150%;
        }

        .title {
            margin-left: 5px;
            width: auto;
            line-height: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            align-content: space-around;
        }
    }
`

interface ProjectHeaderProps {
    project: Project;
    onChangeTab: (tab: ProjectTabs) => void;
    selectedTab: ProjectTabs;
    taskCount: number;
    isAdmin: boolean;
    hideFiles?: boolean;
    hideFavorite?: boolean;
    hideTags?: boolean;
    hideStatus?: boolean;
}

export enum ProjectTabs {
    tasks = "tasks",
    files = "files",
    members = "members",
    settings = "settings",
}

const ProjectHeader: React.FC<ProjectHeaderProps> = (
  {project, selectedTab, onChangeTab, taskCount, isAdmin, hideFiles=false, hideFavorite=false, hideTags=false, hideStatus=false}
) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const addTaskCtx = useReactiveVar(addTaskContext);
    const {token} = theme.useToken();
    const [showMenu, setShowMenu] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)
    const allU = useReactiveVar(allUsersUnions);

    const authInfo = useReactiveVar(authState);

    const [projectAttachTags] = useProjectAttachTagsMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [plugToUnionProject, {}] = usePlugToUnionProjectMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [leaveProject, {}] = useProjectLeaveMutation({
        onCompleted: (_) => {
            notification.success({
                message: t("project.leavedSuccessfully"),
                description: project.title,
            });
            navigate("/")
        }
    });

    let items: any[] = [];
    let plugToProjectButton: React.JSX.Element | false;
    let dropdownItems: ItemType[] = [];

    items.push({
        label: <div><IconTasks/> {t('project.tasks')}<PointDivider hideZero={false} value={taskCount ?? 0}/></div>,
        key: ProjectTabs.tasks,
        children: <></>,
    })

    if (!hideFiles)
        items.push({
            label: <div><IconDocs/> {t('project.files')}<PointDivider hideZero={false} value={project.filesCount?? 0}/></div>,
            key: ProjectTabs.files,
            children: <></>,
        })

    items.push({
        label: <div><IconTeam/> {t('project.members')}<PointDivider value={project.members.length}/></div>,
        key: ProjectTabs.members,
        children: <></>,
    })

    const isUnionMember = useMemo(()=>{
        if (!project || !project.usersUnionAsOwner) return false;
        // текущий пользователь - участник команды
        return allU.unions.find(v=>v.id === project.usersUnionAsOwner?.id)?.members
          .some(m=>m.user.id === authInfo.user.id) ?? false;
    }, [project, allU])

    const isNotMember = !(project.members.some(m => m.user.id == authInfo.user.id)) && isUnionMember

    addTaskCtx.projectId = project.id;
    addTaskContext(addTaskCtx);

    if (isAdmin)
        items.push({
            label: <div><IconSetting/> {t('project.settings')}</div>,
            key: ProjectTabs.settings,
            children: <></>,
        })

    const tagger = <Tagger projectId={project.id} allowAdd={true} block={true}
                           maxTags={5} maxLines={1}
                           defaultValue={project.attachedTags}
                           allowEditUsersUnionTags={true} allowEditProjectTags={false}
                           addButtonText={t('project.headerAddTagsButton')}
                           onChanged={(tags) => {
                               projectAttachTags({
                                   variables: {
                                       projectId: project.id,
                                       tags: tags.map(t => t.id)
                                   }
                               })
                           }}/>;

    // Присоединиться к проекту
    plugToProjectButton = isNotMember ?
        <Button disabled={project.archived} icon={<IconRock/>}
                key={"plugToUnionProject"} type={"primary"} onClick={() => {
            plugToUnionProject({
                variables: {
                    projectId: project.id
                }
            })
        }}>{t('project.joinYourselfToProject')}</Button> : false;

    if (isMobile) {
        dropdownItems = items.map(itm => {
            return {
                label: itm.label,
                key: itm.key,
                onClick: (e: any) => {
                    onChangeTab(e.key)
                }
            } as ItemType
        });
        dropdownItems.push({label: plugToProjectButton, key: 'plugToProjectButton'})
    }

    let changeColumn = project.usersUnionAsOwner != null && !hideStatus && <ProjectChangeColumn project={project}/>

    const userIsMemberOfUnion = project.usersUnionAsOwner && allU.unions.some(u => u.id == project.usersUnionAsOwner?.id)

    let unionHolder = project.usersUnionAsOwner

        ? <div className={"usercard-button"}
                           onClick={() => {
                               if (userIsMemberOfUnion)
                                   navigate(generatePath(routes.usersUnionCard, {id: project.usersUnionAsOwner?.id}))
                               else
                                   message.info(t('membersList.youNotMemberOfAUsersUnion'));
                           }}
        >{isMobile && <div>{t('usersUnion.team')}</div>}
            <div className={"rightAlign"}><UsersUnionsAvatar
                uu={project.usersUnionAsOwner}/>{project.usersUnionAsOwner?.title}</div>
        </div>

        : <div className={"usercard-button"}
                           onClick={() => {
                               navigate(generatePath(routes.userCard, {id: project.userAsOwner?.id}))
                           }}
        >{isMobile && <div>{t('user')}</div>}
            <div className={"rightAlign"}><UserAvatar user={project.userAsOwner} addTitle={true}/></div>
        </div>

    if (isMobile) {
        return <ProjectHeaderStyled $token={token} $isMobile={isMobile} ref={anchorRef}>
            <div className={"top-holder"}>
                <div className={"title-holder"}>
                    <ProjectAvatar icon={project.icon} iconColor={project.iconColor}/>
                    <div className={"title"}>{t('project.headerTitle')} {project.title}</div>
                    {!hideFavorite && <ProjectFavoriteButton project={project}/>}
                </div>

                <Button
                    icon={<Icon size={"24"} icon={<IconMenu/>}/>}
                    type={"text"}
                    onClick={() => {
                        setShowMenu(true);
                    }}>
                </Button>

                {showMenu &&
			            <Dropdowned opened={showMenu} title={t('project.showDetails')} onClose={() => setShowMenu(false)}
			                        anchor={anchorRef}>
				            <DropdownedMenuStyled $token={token} $isMobile={isMobile}>
					            <div className="inner-container">
                          {unionHolder}
					            </div>
                        {changeColumn &&
							            <div className="inner-container">
								            <div>{t('project.headerBoard')}</div>
								            <div className={"rightAlign"}>{changeColumn}</div>
							            </div>
                        }
                        {!hideTags && <div className="inner-container">
                          {tagger}
					            </div>}
				            </DropdownedMenuStyled>
			            </Dropdowned>}
            </div>

            <div className={"bottom-holder"}>
                <div className={"tabs-holder"}>
                    <Tabs defaultActiveKey={ProjectTabs.tasks.toString()}
                          activeKey={selectedTab.toString()}
                          items={items}
                          onChange={(v: any) => {
                              onChangeTab(v)
                          }}
                    />
                </div>
            </div>
        </ProjectHeaderStyled>
    }

    return <ProjectHeaderStyled $token={token} $isMobile={isMobile}>

        <div className={"top-holder"}>
            <div className={"union-holder"}>{unionHolder}</div>

            {changeColumn && <div className={"board-selector-holder"}>
                {t('project.headerBoard')} {changeColumn}
		        </div>
            }

            {!hideTags && <div className={"tagger-holder"}>
                {tagger}
            </div>}

            <div className={"buttons-holder"}>
                {plugToProjectButton}
            </div>
        </div>

        <div className={"bottom-holder"}>
            <div className={"title-holder"}>
                <ProjectAvatar icon={project.icon} iconColor={project.iconColor}/>
                <div className={"title"}>{t('project.headerTitle')} {project.title}</div>
                {!hideFavorite && <ProjectFavoriteButton project={project}/>}
            </div>

            <div className={"tabs-holder"}>
                <TabsStyled defaultActiveKey={ProjectTabs.tasks.toString()}
                      activeKey={selectedTab.toString()}
                      items={items}
                      onChange={(v: any) => {
                          onChangeTab(v)
                      }}
                />
            </div>
        </div>
    </ProjectHeaderStyled>
};

export default ProjectHeader;
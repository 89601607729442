import {theme} from 'antd';
import React from 'react';
import {
    Task,
    TasksView,
    TasksViewColumn,
    TaskViewFilterProject,
    useTaskChangeColumnInViewMutation
} from "../../generated-types";
import TasksBoardItem from "./TasksBoardItem";
import {tasksSorter} from "./TaskView/TaskViewList";
import {useTranslation} from "react-i18next";
import AddTaskInput from "./AddTaskInput";
import {isMobile} from 'react-device-detect';
import {DIRECTION, SORTFIELD} from "./TaskView/TaskViewSortDropdown";
import {GeneralBoardItemType} from '../GeneralBoard/GeneralBoardCard';
import {GeneralBoardColumnType} from '../GeneralBoard/GeneralBoardColumn';
import GeneralBoard from '../GeneralBoard/GeneralBoard';
import PointDivider from '../PointDivider';
import {getStyleByStatus} from './TaskChangeColumn';


interface TasksBoardProps {
    tasks: Task[],
    taskView: TasksView,
    showProjectTitle: boolean
    allowCreate?: boolean
    allowEdit?: boolean
    allowOpenTask?: boolean
}

const TasksBoard: React.FC<TasksBoardProps> = ({tasks, taskView, showProjectTitle, allowCreate = true, allowEdit = true, allowOpenTask=true}) => {
    const visibleCount = isMobile ? 1 : (taskView.columns?.length ?? 1)
    const {t} = useTranslation();
    const {token}=theme.useToken();

    const [changeColumn] = useTaskChangeColumnInViewMutation({
        // optimisticResponse: ({taskId, taskViewId, overTaskId, columnId}) => {
        //     // TODO: почему-то не работает optimisticResponse. надо доразбираться в причинах
        //     const res = {
        //         taskChangeColumnInView: {
        //             id: taskId,
        //             status: "wip", // TODO: change
        //             tasksViewsPositions: [{
        //                 tasksViewColumnId: columnId,
        //                 position: 1 // TODO: change
        //             }]
        //         }
        //     } as TaskChangeColumnInViewMutation;
        //     return res;
        // }
    })

    const boardTasks=tasks.map(task=>({id: task.id, columnId: task.status, item: task} as GeneralBoardItemType<Task>))
    const boardColumns=taskView.columns.map(col=>({id: col.title, title: t(`task.status.${col.title}`), item: col} as GeneralBoardColumnType<TasksViewColumn>))

    const taskBoardColumnSplitter=(column: GeneralBoardColumnType<TasksViewColumn>, items: GeneralBoardItemType<Task>[] )=>{
        return items.filter(item=>column.id===item.columnId).sort((task1, task2)=>tasksSorter(SORTFIELD.position, DIRECTION.DESC, task1.item, task2.item, taskView))
    }

    const onTaskRender=(task: Task)=>{

        return <TasksBoardItem showProjectTitle={showProjectTitle} task={task} taskView={taskView} allowOpenTask={allowOpenTask} allowEdit={allowEdit} />
    }

    const onItemDropped=(droppedTask: Task, droppedOnItem?: Task, column?: TasksViewColumn)=>{
  
        if (!droppedTask) return;
        
        //droppedOnItem?.tasksViewsPositions.find(tic=>tic.tasksViewId===taskView.id)?.tasksViewColumnId - не у всех задач почему-то имеется запись о tic в моих задачах
        const columnId=column? column.id : taskView.columns.find(col=>droppedOnItem?.status==col.title)?.id?? ''
        const variables = {
            taskId: droppedTask.id,
            taskViewId: taskView.id,
            columnId: columnId,
            overTaskId: droppedOnItem?.id
        };

        changeColumn({ variables })
    }

    const taskInput=(column: TasksViewColumn)=>{
        return <>{allowCreate && <AddTaskInput
            fastAddOnly={false}
            showPrefix
            isBlocked={taskView.project?.paymentAccount?.isBlocked}
            ctx={{
            projectId: (taskView.filterValue as TaskViewFilterProject)?.projectId,
            tasksViewColumnId: column.id,
            taskViewId: taskView.id,
            taskView: taskView,
            taskId: undefined,
            status: column.title // TODO: нужно убирать зависимость статуса и колонки. слишком сильно уже разрослось
        }} />}
        </>
    }

    const columnTitle=(col: GeneralBoardColumnType<TasksViewColumn>, items: GeneralBoardItemType<Task>[])=>{

        const itemsCount=items.filter(item=>!item.item.resolved).length
        return <div style={{padding: '0px 0px 8px',display: 'flex', gap: 8}}>
        <span style={getStyleByStatus(col.item, token)}>• </span>
        <span>{t(col.title)}<PointDivider value={itemsCount}/></span>
        
        </div>
    }

    if (!taskView.columns)
        return null;

    const columnWidth=isMobile? 'calc(100% - 8px)' : `calc(${Math.round(100/visibleCount)}% - 1px)`

    return <div style={{flexGrow: 1}}>
                <GeneralBoard signature={'TASK'} 
                    items={boardTasks} 
                    columns={boardColumns} 
                    columnsSplitter={taskBoardColumnSplitter}
                    onItemRender={onTaskRender}
                    onItemDropped={onItemDropped}
                    columnsToShow={visibleCount}
                    stickyTopElement={taskInput}
                    columnTitle={columnTitle}
                    columnWidth={columnWidth}
                    padding={isMobile? '20px 16px 0px' : '20px 12px 0px 24px'}
                    />
        </div>
  
};

export default TasksBoard;

import { Form } from "antd";
import styled from "styled-components";

export const FormStyled=styled(Form)`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ant-form-item{
        margin: 0;

        >.ant-form-item-required{
            line-height: 0.9;
        }
    }

    
`
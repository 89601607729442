import React, {type SVGProps} from "react"

export function IconBack(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <mask id="path-1-inside-1_4435_221069" fill="white">
        <path d="M2.99882 10.3964C2.77254 10.5048 2.67553 10.7772 2.80076 10.9947C3.39925 12.0338 4.29756 12.8731 5.38289 13.3991C6.60491 13.9915 7.99172 14.1518 9.31664 13.8538C10.6416 13.5558 11.8262 12.8171 12.6769 11.7586C13.5276 10.7 13.994 9.38419 13.9999 8.02619C14.0059 6.66819 13.5509 5.34835 12.7095 4.28242C11.8681 3.21648 10.69 2.46752 9.3677 2.15796C8.04544 1.8484 6.65727 1.99656 5.43013 2.57821C4.34025 3.0948 3.43465 3.92619 2.8271 4.96007C2.69998 5.17639 2.79461 5.44961 3.01993 5.56C3.24525 5.6704 3.51569 5.57594 3.64573 5.36135C4.16074 4.51145 4.91533 3.82775 5.8193 3.39928C6.86061 2.90571 8.03856 2.77999 9.16058 3.04267C10.2826 3.30535 11.2823 3.94089 11.9963 4.8454C12.7103 5.74991 13.0964 6.86988 13.0913 8.02223C13.0863 9.17458 12.6905 10.2911 11.9686 11.1894C11.2468 12.0876 10.2415 12.7144 9.11725 12.9673C7.99298 13.2202 6.81617 13.0842 5.77922 12.5815C4.87901 12.1452 4.13043 11.4549 3.62285 10.6005C3.4947 10.3848 3.22509 10.288 2.99882 10.3964Z"/>
    </mask>
    <path d="M2.99882 10.3964C2.77254 10.5048 2.67553 10.7772 2.80076 10.9947C3.39925 12.0338 4.29756 12.8731 5.38289 13.3991C6.60491 13.9915 7.99172 14.1518 9.31664 13.8538C10.6416 13.5558 11.8262 12.8171 12.6769 11.7586C13.5276 10.7 13.994 9.38419 13.9999 8.02619C14.0059 6.66819 13.5509 5.34835 12.7095 4.28242C11.8681 3.21648 10.69 2.46752 9.3677 2.15796C8.04544 1.8484 6.65727 1.99656 5.43013 2.57821C4.34025 3.0948 3.43465 3.92619 2.8271 4.96007C2.69998 5.17639 2.79461 5.44961 3.01993 5.56C3.24525 5.6704 3.51569 5.57594 3.64573 5.36135C4.16074 4.51145 4.91533 3.82775 5.8193 3.39928C6.86061 2.90571 8.03856 2.77999 9.16058 3.04267C10.2826 3.30535 11.2823 3.94089 11.9963 4.8454C12.7103 5.74991 13.0964 6.86988 13.0913 8.02223C13.0863 9.17458 12.6905 10.2911 11.9686 11.1894C11.2468 12.0876 10.2415 12.7144 9.11725 12.9673C7.99298 13.2202 6.81617 13.0842 5.77922 12.5815C4.87901 12.1452 4.13043 11.4549 3.62285 10.6005C3.4947 10.3848 3.22509 10.288 2.99882 10.3964Z" stroke="#12121E" stroke-width="2" mask="url(#path-1-inside-1_4435_221069)"/>
    <path d="M2.41618 3.29918L2.48307 5.39847C2.49293 5.70796 2.77886 5.93416 3.08231 5.87255L5.14737 5.45322" stroke="#12121E" strokeLinecap="round"/>
</svg>
    )
}

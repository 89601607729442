import React, {useState} from 'react';
import {Alert, Button, Col, Form, message, notification, Popconfirm, Row, Tabs, Tooltip, Watermark} from 'antd';
import {useTranslation} from "react-i18next";
import {
    AccessLevel,
    PaymentSlotType,
    User,
    UsersUnion,
    UsersUnionMember,
    useUsersUnionQuery, useUsersUnionReadOnlyQuery,
    useUsersUnionUpdateMutation,
} from '../../generated-types';
import UsersUnionMembersList from "./UsersUnionMembersList";
import {useNavigate, useParams} from "react-router-dom";
import UsersUnionSettings from "./UsersUnionSettings";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import Spinner from "../Spinner";
import UsersUnionFilesList from "../File/UsersUnionFilesList";
import UsersUnionProjectsList from "./UsersUnionProjectsList";
import {ExportOutlined} from "@ant-design/icons";
import {CustomPageHeader} from "../AntCastomComponents/PageHeader";
import {isMobile} from "react-device-detect";
import UsersUnionHeader, { UsersUnionTabs, UserUnionHeaderContainer } from './UsersUnionHeader';
import UsersUnionsAvatar from './UsersUnionsAvatar';

const UsersUnionReadOnlyCard: React.FC = () => {
    const {t} = useTranslation();
    const { linkId } = useParams();
    const { loading, error, data } = useUsersUnionReadOnlyQuery({
        variables: {
            linkId: linkId!,
        }
    });

    if (loading) return <Spinner loading />;
    if (error) return<Alert type="error" message={error.message} banner />;

    let union: any = data == null ? null : data.usersUnionReadOnly;

    if (!union)
        return <div>define right usersUnion id please</div>

    return (
        <Watermark
            content={undefined}
            style={{display: "flex", flexDirection: "column", flexGrow: 1, maxHeight: '100%'}}
            >
            <UserUnionHeaderContainer>
                <div className='team-title-container'>
                    <UsersUnionsAvatar avatarUrl={union.avatarUrl} size={isMobile? '24' : '32'} />
                    <span className='team-title'>{t("usersUnion.teamTitle", { team: union.title })}</span>
                </div>
                <div className='tabs-container'>
                    {/*{*/}
                    {/*    isMobile? <div className='tabs-scroll'>{tabs}</div> : tabs*/}
                    {/*}*/}
                </div>

            </UserUnionHeaderContainer>
            <Row style={{margin: 0, flexGrow: 1, overflow: 'hidden'}}>
                <Col span={24} style={{height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column'}}>
                    <UsersUnionProjectsList key={union.id} hideFilter={true} union={union} onChange={()=>{}}/>
                </Col>
            </Row>
        </Watermark>

    )
};

export default UsersUnionReadOnlyCard;

import React, { useMemo } from 'react';
import { Button, Skeleton } from 'antd';
import {
    AccessLevel,
    Project,
    ProjectMember, ProjectMemberInvite, useProjectMemberChangeLevelMutation,
} from '../../generated-types';
import { useTranslation } from 'react-i18next';
import AccessLevelSelector from '../AccessLevelSelector';
import styled from 'styled-components';
import UserAvatar from '../User/UserAvatar';
import { IconDelete } from '../Icon/IconDelete';
import TooltipButton from '../TooltipButton';
import { IconBack } from '../Icon/IconBack';

const MaxCountEditor = styled.div<{}>`
    display: flex;
    width: 100%;
    
    .btn {
        width: 24px;
        height: 24px;
    }
    
    .caption {
        flex-grow: 1;
    }
    
    .countEditor {
        display: flex;
        flex-grow: 0;
        align-self: end;
        .maxMembersCount {
            width: 28px;
            text-align: center;
        }
        
        button {
            color: ${(p) => p.theme.colors.ui.accent};
            background-color: ${(p) => p.theme.colors.ui.bgLight};
            width: 24px;
            height: 24px;
            border: none;
        }
    }
`

const Holder = styled.div<{}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    
    .items-holder {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            .content {
                padding: 4px;
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                gap: 4px;
                align-items: center;
                flex-grow: 1;

                & > *:nth-child(1) {
                    flex-grow: 2;
                }

                & > *:nth-child(2) {
                    width: 175px;
                }

                & > *:nth-child(3) {
                    width: 100px;
                }
            }

            &.empty {
                color: ${(p) => p.theme.colors.ui.bgLight3};                
            }

            .content.isRemoved {
                position: relative;
                color: ${(p) => p.theme.colors.ui.bgLight3};
                .ant-select-selection-item {
                    color: ${(p) => p.theme.colors.ui.bgLight3};
                }
            }
            
            .content.isRemoved:after {
                position: absolute;
                left: 0;
                top: 50%;
                height: 2px;
                background: ${(p) => p.theme.colors.ui.bgLight3};
                content: "";
                width: 100%;
                display: block;
            }
        }
        
        .item:hover {
            background-color: ${(p) => p.theme.colors.ui.bgLight};
        }
        
        .item .actions {
            button {
                padding: 0 4px;
            }
        }
    }
`


interface ProjectMembersUsersUnionMembersListProps {
    members: ProjectMember[];
    removedMembers: ProjectMember[];
    maxMembersCount: number;
    readonly: boolean;
    invite?: ProjectMemberInvite;
    project: Project;
    onChangeMaxMembersCount: (value: number) => void;
    onChangeRemovedMembers: (value: ProjectMember[]) => void;
}

const ProjectMembersUsersUnionMembersList: React.FC<ProjectMembersUsersUnionMembersListProps> =
  ({members, readonly, invite, project, maxMembersCount, removedMembers, onChangeRemovedMembers, onChangeMaxMembersCount}) => {
    const {t} = useTranslation()

    const [ChangeLevel, opt2] = useProjectMemberChangeLevelMutation({});

    const items = useMemo(() => {
        const res = [];
        for (let i = 0; i < maxMembersCount; i++) {
            if (i < members.length)
                res.push(members[i])
            else
                res.push(null);
        }
        return res;
    }, [members, invite, maxMembersCount]);

    return (
        <Holder>
            <div className={'items-holder'}>
                {items.map((i, j)=> {
                    if (!i)
                        return <div className={'item empty'} key={j}>
                            <div>
                                <Skeleton.Avatar size={'small'} />
                                <span style={{padding: 4}}>{t('project.noMemberInUnionSlot')}</span>
                            </div>
                        </div>

                    const isRemoved = removedMembers.some(rm => rm.id == i.id);

                    return <div className={'item'} key={j}>
                        <div className={'content'+(isRemoved ? ' isRemoved' : '')}>
                            <UserAvatar className={'avatar'} user={i.user} addTitle={true} />
                            <span className={'email'}>{i.user?.email}</span>
                            <div className={'role'}>
                                <AccessLevelSelector selected={i.accessLevel}
                                                     disabled={readonly || isRemoved}
                                                     onChange={(level: AccessLevel) => {
                                                         if (readonly) return false;
                                                         ChangeLevel({
                                                             variables: {
                                                                 input: {
                                                                     userId: i.user.id,
                                                                     projectId: project.id,
                                                                     level
                                                                 }
                                                             }
                                                         })
                                                         return true;
                                                     }} />
                            </div>
                        </div>
                        <div className={'actions'}>
                            <TooltipButton type="text" tooltipProps={{ title: t('remove') }}
                                           onClick={()=>{
                                               if (isRemoved)
                                                   onChangeRemovedMembers(removedMembers.filter(m => m.id !== i.id))
                                               else
                                                   onChangeRemovedMembers([...removedMembers, i])
                                           }}
                            >{isRemoved ? <IconBack/> : <IconDelete/>}</TooltipButton>
                        </div>
                    </div>
                })}
            </div>

            <MaxCountEditor>
                <div className={"caption"}>{t('project.maxCountMembersFromUnion')}</div>
                <div className={"countEditor"}><Button size={'small'} onClick={()=>{
                    if (maxMembersCount > 1)
                        onChangeMaxMembersCount(maxMembersCount-1)
                }}>-</Button><div className={'maxMembersCount'}>{maxMembersCount}</div><Button size={'small'}
                  onClick={()=>{
                      onChangeMaxMembersCount(maxMembersCount+1)
                  }}
                >+</Button></div>
            </MaxCountEditor>
        </Holder>
    );
};

export default ProjectMembersUsersUnionMembersList;
import React from 'react'
import { Col, Form, FormInstance, Input, Row } from 'antd';
import { Project } from '../../generated-types';
import {useTranslation} from "react-i18next";
import ProjectIconEditor from "./ProjectIconEditor";
import ProjectChangeColumn from './ProjectChangeColumn';


interface ProjectSettingsProps {
    project: Project,
    save: any,
    form: FormInstance
}

function ProjectSettings(props: ProjectSettingsProps) {
    const {t} = useTranslation();

    return (
        <Form layout="vertical" requiredMark={false} form={props.form}
              initialValues={props.project as any}
              onFinish={props.save}
        >
            <Row gutter={[32,32]}>
                <Col flex={'auto'}>
                    <Form.Item
                      name="title"
                      label={t('project.title')}
                      rules={[{required: true, message: "" + t('project.titlePlaceholder')}]}
                    >
                        <Input placeholder={"" + t('project.titlePlaceholder')}/>
                    </Form.Item>
                </Col>
                <Col>
                    <div className="ant-form-item css-dev-only-do-not-override-tkzidb">
                        <div className="ant-row ant-form-item-row css-dev-only-do-not-override-tkzidb">
                            <div className="ant-col ant-form-item-label css-dev-only-do-not-override-tkzidb"><label
                              htmlFor="title" className="ant-form-item-required" title="Название">{t('project.statusField')}</label></div>
                            <div className="ant-col ant-form-item-control css-dev-only-do-not-override-tkzidb">
                                <div className="ant-form-item-control-input">
                                    <div className="ant-form-item-control-input-content">
                                        <Form.Item
                                          name="status" noStyle
                                        >
                                            <ProjectChangeColumn project={props.project} onChanged={(status) => {
                                                props.form.setFieldValue('status', status);
                                            }} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>

            <Form.Item
              name="description"
              label={'' + t('project.description')}
              rules={[
                  {
                      required: false,
                  },
              ]}
            >
                <Input.TextArea rows={7} placeholder={t('project.descriptionPlaceholder')} />
            </Form.Item>

            <Form.Item name="icon" hidden={true}></Form.Item>
            <Form.Item name="iconColor" hidden={true}></Form.Item>

            <div>
                <div>{t('project.settingsPage.icon')}</div>
                <ProjectIconEditor project={props.project} onChange={(icon, iconColor) => {
                    props.form.setFieldValue("icon", icon);
                    props.form.setFieldValue("iconColor", iconColor);
                }}/>
            </div>
        </Form>
    )
}

export default ProjectSettings
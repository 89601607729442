import React, { useEffect, useRef, useState } from 'react'
import { DragPreviewImage, DropTargetOptions, useDrag, useDragLayer, useDrop } from 'react-dnd'
import styled, { css } from 'styled-components'
import { GeneralBoardColumnType } from './GeneralBoardColumn'
import { message } from 'antd'
import { theme } from 'antd'
import { isMobile } from 'react-device-detect'
import { draggingCardSize } from '../CommonDragLayer'
import { useReactiveVar } from '@apollo/client'

const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'

export enum DropPositions {
    top='top',
    bottom='bottom'
}

const GenearalBoardCardContainer = styled.div<{ $dropPosition?: DropPositions, $gapSize: number, $lastCard: boolean }>`
width: 100%;
position: relative;
min-width: 298px;

&>.gb-card-content-container{

    background-color: ${p => p.theme.colors.ui.white};
    border-radius: 8px;
    margin: ${p => p.theme.isMobile ? '2px 0px' : '6px 0px'};
    transition: all .3s;

    &:hover{
    background-color: ${p => p.theme.colors.ui.bgLight2}
    }

    ${p => p.$dropPosition && !p.$lastCard && (p.$dropPosition === DropPositions.bottom ? `margin-bottom: ${p.$gapSize}px;` : `margin-top: ${p.$gapSize}px;`)}
}

${p => p.$dropPosition && p.$lastCard && css`
    &::before{
    content: '';
    background-color: ${p.theme.colors.ui.accent};
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    top: 100%;
    }
`}


`

export type GeneralBoardItemType<T> = {
    id: number | string,
    columnId: number | string,
    item: T,
    cardInColumnIndex: number
}

interface GeneralBoardCardProps<I, C> {
    signature: string,
    item: GeneralBoardItemType<I>,
    onItemRender: (item: I) => React.JSX.Element,
    onItemDropped: (droppedItem: GeneralBoardItemType<I>, droppedOnItem?: GeneralBoardItemType<I>, column?: C, dropPosition?: DropPositions) => void,
    isBoardDragging: boolean,
    touchEventsDelay: number,
    lastCard: boolean
}

function GeneralBoardCard<I, C>({ signature, item, onItemRender, onItemDropped, isBoardDragging, touchEventsDelay, lastCard}: GeneralBoardCardProps<I, C>) {

    const [canDrag, setCanDrag] = useState(false)
    const [dropPosition, setDropPosition] = useState<DropPositions | undefined>(undefined)
    const holdTimer = useRef<NodeJS.Timeout | null>(null)
    const itemCardRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const {height} = useReactiveVar(draggingCardSize)

    const [{ isDragging }, dragRef, preview] = useDrag(() => ({
        type: signature,
        item: item,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),

    }), [item.cardInColumnIndex])


    const [{ isOver }, dropRef] = useDrop(() => (
        {
            accept: signature,
            drop(itemDropped: GeneralBoardItemType<I>, monitor) {
                if(itemDropped.id!==item.id)
                    onItemDropped(itemDropped, item, undefined, dropPosition)

                setDropPosition(undefined)
                return
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            }),
            hover: (i, monitor) => {
                
                const monitorItem=monitor.getItem()

                if(monitorItem.id===item.id)
                    return

                const container = containerRef.current
                const monitorClientOffset = monitor.getClientOffset()
                
                
                if (container && monitorClientOffset) {
                    const containerRect = container.getBoundingClientRect()
                    const offsetY = monitorClientOffset.y - containerRect.top

                    const isSameColumn = monitorItem.columnId == item.columnId

                    /*console.log('drag: ', monitorItem.columnId, monitorItem.cardInColumnIndex)
                    console.log('drop: ', columnId, cardInColumnIndex)*/
                    if(isSameColumn){
                        //Если перетаскиваемая карточка находится прямо над данной, то для данной оставляем только дроп снизу
                        if(monitorItem.cardInColumnIndex+1===item.cardInColumnIndex){
                            setDropPosition(DropPositions.bottom)
                            return   
                        }
    
                        //Если перетаскиваемая карточка находится прямо под данной, то оставляем дроп сверху
                        if(monitorItem.cardInColumnIndex-1===item.cardInColumnIndex){
                            setDropPosition(DropPositions.top)
                            return
                        }
                    }

                    //Для остальных случаев смотрим, на положение курсора
                    if (offsetY < containerRect.height * 0.5)
                        setDropPosition(DropPositions.top)
                    else
                        setDropPosition(DropPositions.bottom)
                }
            },
        }
    ), [dropPosition, item.cardInColumnIndex])

    dropRef(containerRef)

    useEffect(() => {

        if (isDragging && itemCardRef.current) {
            const { width, height } = itemCardRef.current.getBoundingClientRect()

            draggingCardSize({ width, height })
        }
    }, [isDragging])

    useEffect(() => {
        if (!isOver)
            setDropPosition(undefined)
    }, [isOver])


    dragRef(itemCardRef)
    //нужно как-то сообщать пользователю, что элемент можно перетаскивать после зажатия, пока сделал сообщение
    const touchStartHandler = () => {
        holdTimer.current = setTimeout(() => {
            setCanDrag(true)
        }, touchEventsDelay);
    }

    const touchEndHandler = () => {
        if (holdTimer.current) {
            clearTimeout(holdTimer.current)
            holdTimer.current = null
        }
        if (canDrag) {
            setCanDrag(false)
        }
    }

    useEffect(() => {
        if (canDrag) {
            message.success('Элемент можно перетаскивать')
        }
    }, [canDrag])

    return (<>
        <DragPreviewImage connect={preview} src={boxImage} />
        <GenearalBoardCardContainer
            $gapSize={height}
            ref={containerRef}
            $lastCard={lastCard}
            onTouchStart={touchStartHandler}
            onTouchEnd={touchEndHandler}
            //$isOver={isOver}
            $dropPosition={dropPosition}
        >
            <div className='gb-card-content-container' ref={itemCardRef} style={{ opacity: isDragging ? 0 : 1 }}>
                {
                    onItemRender(item.item)
                }
            </div>
        </GenearalBoardCardContainer>
    </>
    )
}

export default GeneralBoardCard
import React, {useMemo, useState} from 'react';
import {
    InviteResolution,
    Project,
    ProjectMemberInvite,
    useProcessInviteMutation,
    useProjectsInvitesQuery,
    User,
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import TopAlertsList from "./TopAlertsList";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import {pollInterval} from "../../ApolloClient";
import { useNavigate } from 'react-router-dom';
import Button from '../AntCastomComponents/Button';

const ProjectInvitesList: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [inviteInProgress, setInviteInProgress] = useState<ProjectMemberInvite>();

    const q = useProjectsInvitesQuery({
        pollInterval: pollInterval * 2
    })

    const [processInvite] = useProcessInviteMutation({
        onCompleted: () => {
            const id = inviteInProgress?.project?.id;
            if (id && inviteInProgress?.resolution == InviteResolution.Accepted)
                navigate(`/project/${id}`)
            setInviteInProgress(undefined)
            q.refetch();
        },
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.processInvite as Project)
        }
    });

    const acceptInvite = (i: ProjectMemberInvite) => {
        setInviteInProgress({...i, resolution: InviteResolution.Accepted})
        processInvite({
            variables: {
                inviteId: i.id,
                users: [],
                resolution: InviteResolution.Accepted
            }
        })
    };

    const declineInvite = (i: ProjectMemberInvite) => {
        setInviteInProgress({...i, resolution: InviteResolution.Declined})
        processInvite({
            variables: {
                inviteId: i.id,
                users: [],
                resolution: InviteResolution.Declined
            }
        })
    };

    const invites: ProjectMemberInvite[] = useMemo(() => {
        return q.data?.projectsInvites as ProjectMemberInvite[] ?? [];
    }, [q.data]);

    return <TopAlertsList<ProjectMemberInvite>
        onRender={(i) => {
            return <div style={{display: "flex", gap: 4}}>
                <div>{t('project.inviteToProject')}</div>
                <Button type="link" onClick={()=>navigate(`/projectro/${i.project.id}`)}>
                    {i.project.title}
                </Button>
            </div>
        }}
        items={invites}
        getUser={i => i.inviter as User}
        acceptTitle={t('project.inviteToProjectAcceptTitle')}
        declineTitle={t('project.inviteToProjectDeclineTitle')}
        declinePopupTitle={t('project.inviteToProjectDeclinePopupTitle')}
        declinePopupText={t('project.inviteToProjectDeclinePopupText')}
        onAccept={acceptInvite}
        onDecline={declineInvite}
    ></TopAlertsList>
};

export default ProjectInvitesList;
import React, {useMemo} from "react";
import styled from "styled-components";

interface SettingsPanelProps {
    children: React.ReactNode
    height?: number
    title?: string
    gap?: number
}

const StyledDiv = styled.div<{}>`
    background-color: ${p => p.theme.colors.ui.white};
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    gap: 12px;
    .title {
        font-size: 16px;
        font-weight: 400;
    }

    .ant-input{
        padding: 16px;
        border-radius: 8px;
        line-height: 1;
        resize: none;
    }

    .ant-btn{
        padding: 10px;
        height: auto;
    }

    .tags-editor{
        max-width: none;
        padding: 0;
    }

    .scroll-container{
        flex-grow: 1;
        overflow-y: auto
    }
`

export const SettingsPanel: React.FC<SettingsPanelProps> = (props) => {
    const style = useMemo(() => {
        return {maxHeight: props.height, gap: props.gap};
    }, [props.height])

    return (
        <StyledDiv style={style}>
            {props.title && <div className={"title"}>{props.title}</div>}
            {props.children}
        </StyledDiv>
    )
};

export default SettingsPanel;
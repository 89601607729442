import {Space, theme} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {ButtonsContainer, ButtonWrapper} from "./LeftMenuButtons.styled";
import PointDivider from "../../../PointDivider";
import {IconHome} from "../../../Icon/IconHome";
import {IconTasks} from "../../../Icon/IconTasks";
import {TasksView, TasksViewFilterType, useTasksCountQuery, useTasksViewsQuery} from "../../../../generated-types";
import {Icon} from "../../../Icon/Icon";
import {currentMenuIsSelected} from "../LeftMenu";

interface LeftMenuButtonsProps {
    collapsed: boolean;
    onClick: (link: string) => void
}

export const LeftMenuButtons: React.FC<LeftMenuButtonsProps> = ({collapsed, onClick}) => {
    const {t} = useTranslation();
    const {token} = theme.useToken();

    const {loading, error, data} = useTasksViewsQuery({
        variables: {}
    })

    const tw = data?.tasksViews.find(v => v.filterType == TasksViewFilterType.Personal) as TasksView

    const {data: countData} = useTasksCountQuery({
        skip: tw == null,
        variables: {
            iAmResponsible: false,
            includeResolved: false,
            tasksViewId: tw?.id
        },
        // skip: skip,
        fetchPolicy: 'network-only',
        pollInterval: 60000// TODO: переделать на подписку
    })

    const myTaskCount = countData?.tasksCount.total;

    return <ButtonsContainer collapsed={collapsed ? "true" : undefined} $token={token}>
        <Space direction={"vertical"} style={{width: '100%'}}>
            <ButtonWrapper
            test-id="main-btn"
            $token={token} 
            collapsed={collapsed ? "true" : undefined}
            className={currentMenuIsSelected("/") ? "selected" : ""} onClick={() => onClick('/')}
            >
                <Icon size={"24"} icon={<IconHome/>}/>
                {!collapsed && <p>{t('allProjects')}</p>}
            </ButtonWrapper>
            <ButtonWrapper $token={token} collapsed={collapsed ? "true" : undefined}
                           className={currentMenuIsSelected("/mytasks") ? "selected" : ""}
                           onClick={() => onClick("/mytasks")}>
                <Icon size={"24"} icon={<IconTasks/>}/>
                {!collapsed && <p>{t('myTasks')}<PointDivider value={myTaskCount}/></p>}
            </ButtonWrapper>
        </Space>
    </ButtonsContainer>
}
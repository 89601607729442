import React, {useRef, useState} from 'react';
import {Card, Empty, List, Table, Tabs, theme} from 'antd';
import ConnectYandexDisk from "./ConnectYandexDisk";
import {AttachedFolder, ExternalFile, Project, useAttachFolderMutation, useFilesInProjectQuery} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import RefetchList from "../../queries/RefetchesList";
import AttachFilesButton, {FilesList} from "./AttachFilesButton";
import {editTaskContext} from "../Tasks/TaskEditor";
import DownloadFileButton from "./DownloadFileButton";
import {ExternalFileWithId} from "../Tasks/TaskCard";
import {CenterJustify} from "../Dropdowned";
import styled, { css } from 'styled-components';
import { IconFolder } from '../Icon/IconFolder';
import { ColumnsType } from 'antd/es/table';
import { isMobileOnly } from 'react-device-detect';
import { IconEdit } from '../Icon/IconEdit';
import { IconFiles } from '../Icon/IconFiles';
import { IconDocs } from '../Icon/IconDocs';
import FolderCard from './FolderCard';

interface ProjectFilesListProps {
    project: Project,
    isAdmin: boolean
}

const ProjectFilesList: React.FC<ProjectFilesListProps> = ({project, isAdmin}) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState(false);

    const [attachFolder] = useAttachFolderMutation({
        onCompleted: () => {
            setInProgress(false)
            // m.destroy();
        },
        refetchQueries: [RefetchList.ProjectCard, RefetchList.YandexDiskGetFolderItems]
    })

    const onFolderChanged = (items: ExternalFile[])=>{
        setInProgress(true)
        // TODO: не очень-то получилось
        attachFolder({
            variables: {
                path: items[0].path,
                projectId: project.id,
            }
        })
    };

    return <FolderCard isAdmin={isAdmin} source={project} onFolderChanged={onFolderChanged} loading={inProgress}/>
};

export default ProjectFilesList;
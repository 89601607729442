import React, {useState} from 'react';
import {Button, Modal} from 'antd';
import {AccessLevel, Project, useProjectTransferMutation, User, UsersUnion} from "../../generated-types";
import UserSearch, {UserOrUnionInvite, UserSearchResults} from "../User/UserSearch";
import {useTranslation} from "react-i18next";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import styled, {css} from "styled-components";
import TooltipButton from '../TooltipButton';
import {Icon} from "../Icon/Icon";
import {IconUserProject} from "../Icon/IconUserProject";

interface ProjectTransferProps {
    project: Project
}

const ModelContent = styled.div<{}>`
    min-height: 500px;
    display: flex;
    flex-direction: column;

    .txt2 {
        padding-top: 8px;
        padding-bottom: 8px;
        ${p => !p.theme.isMobile && css`
            width: 70%;
        `}

        font-weight: normal;
    }

    .title {
        font-size: 20px;
    }
`

const ProjectTransfer: React.FC<ProjectTransferProps> = ({project}) => {
    // const {t} = useTranslation()
    // const [open, setOpen] = useState<boolean>(false);
    // const [selectedItems, setSelectedItems] = useState<(UserOrUnionInvite)[]>([]);
    // const {token} = theme.useToken();
    //
    // const [Add, opt1] = useProjectAddMembersMutation({
    //     update: (cache, r) => {
    //         updateProjectInCache(cache, r.data?.changedProject as Project)
    //     },
    //     onCompleted: () => {
    //         setOpen(false)
    //     }
    // });
    //
    // const {data: {paymentAccount} = {payment: null}, loading} = useUserPaymentAccountQuery({
    //     variables: {id: project.paymentAccount.id}
    // });
    //
    // const slots = paymentAccount?.slots as PaymentSlot[];
    //
    // let type = '';
    //
    // if (project.userAsOwner) {
    //     type = 'personalProjectAdditionalMember'
    // }
    // if (project.usersUnionAsOwner) {
    //     type = 'usersUnionProjectMember'
    // }
    //
    // const [allSlots, empty] = useMemo(() => {
    //     const s = Object.keys(PaymentSlotType)
    //         .map(k => slotsTypesWithAmounts(slots as any ?? [], k))
    //     const membersSlots = s.find((s) => s.type === type);
    //     const e = membersSlots?.payEmptySlots.length! + membersSlots?.freeEmptySlots.length!
    //     return [s, e]
    // }, [slots]);
    //
    // const user = paymentAccount?.managers.find((m) => m.id === authInfo.user.id)
    //
    // const openAddSlotsModal = () => {
    //     const allSlots = paymentAccount?.slots
    //     const slotsType = Object.keys(PaymentSlotType)
    //         .map(k => slotsTypesWithAmounts(allSlots!, k))
    //     addSlotsContext({
    //         slotsType: slotsType
    //     });
    // }
    //
    // const totalSelected = useMemo(() => {
    //     return selectedItems.map(v => {
    //         if (v.__typename == "UsersUnion")
    //             return (v as UUInvite).maxMembers
    //         return 1;
    //     }).reduce((prev, cur) => prev + cur, 0);
    // }, [selectedItems])

    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ownerU, setOwnerU] = useState<User | null>(project.transfer?.user ?? null);
    const [ownerUU, setOwnerUU] = useState<UsersUnion | null>(
        project.transfer?.usersUnion ?? null
    );
    const isAdmin = project.members.some(m => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin)

    const [ProjectTransfer, opt2] = useProjectTransferMutation({
        onCompleted: _ => {
            setIsModalOpen(false);
        }
    });
    const handleOk = () => {
        if (!ownerU && !ownerUU) return;
        ProjectTransfer({
            variables: {
                projectId: project.id,
                userAsOwnerId: ownerU?.id,
                usersUnionAsOwnerId: ownerUU?.id
            }
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let title = t('project.transfer')

    if (project.transfer) {
        title = t('project.transferWaiting') + (
            project.transfer.usersUnion?.title ??  project.transfer.user?.fullName ?? project.transfer.user?.username)
    }

    // TODO: эту фигню надо перенести внутрь UserSearch
    let owner: UserOrUnionInvite[] = ownerU ? [ownerU as UserOrUnionInvite] : (ownerUU ? [ownerUU as UserOrUnionInvite] : []);

    if (!isAdmin)
        return <></>;

    const modalContent = <ModelContent>
        <div className={"title"}>{t('project.transferTooltip')}</div>
        <div className={"txt2"}>{t('project.transferModalText')}</div>

        <div style={{flexGrow: 1}}>
            <UserSearch
                selectedText={t('project.transferSelectedText')}
                onlyOne={true}
                maxMembers={1}
                addUsersUnions={true}
                membersAdded={(result: UserSearchResults) => {
                    setOwnerU(null)
                    setOwnerUU(null)

                    if (result.users.length > 0)
                        setOwnerU(result.users[0])

                    if (result.unions.length > 0)
                        setOwnerUU(result.unions[0])

                    return false;
                }}

                members={[]}
                invites={[]}/>
        </div>

        <div style={{display: "flex", gap: 8, alignItems: "center"}}>
            <Button type={"primary"} disabled={ownerU === null && ownerUU === null}
                    onClick={() => {
                        handleOk()
                    }}>
                {t(('project.transfer2'))}
            </Button>
        </div>
    </ModelContent>;

    return <>
        <Modal
            open={isModalOpen}
            onOk={handleOk} onCancel={handleCancel}
            cancelButtonProps={{style: {display: 'none'}}}
            okButtonProps={{style: {display: 'none'}}}
            centered
        >
            {modalContent}
        </Modal>
        <TooltipButton icon={<Icon icon={<IconUserProject/>} size={"24"}/>} ghost block
                       loading={opt2.loading}
                       onClick={() => {
                           setIsModalOpen(true)
                       }}
                       size={"middle"}
                       tooltipProps={{
                           title: t('project.transferTooltip')
                       }}
        >{title}</TooltipButton>
    </>
};

export default ProjectTransfer;
import React, {useEffect, useMemo, useState} from "react";
import {
    Project,
    ProjectChangePriorityAndStatusMutation,
    ProjectMember,
    ProjectMemberInvite, ProjectStatus,
    useProjectChangePriorityAndStatusMutation,
    UsersUnion,
} from '../../generated-types';
import client from "../../ApolloClient";
import {GeneralBoardItemType} from "../GeneralBoard/GeneralBoardCard";
import {t} from "i18next";
import {GeneralBoardColumnType} from "../GeneralBoard/GeneralBoardColumn";
import ProjectsListCard from "../Project/ProjectsListCard";
import {theme} from "antd";
import ProcessProjectInvite from "./ProcessProjectInvite";
import GeneralBoard from "../GeneralBoard/GeneralBoard";
import { isMobile } from "react-device-detect";
import { getStyleByStatus } from "../Project/ProjectChangeColumn";
import PointDivider from "../PointDivider";
import UsersUnionProjectMembersEditor from "./UsersUnionProjectMembersEditor";

export const statuses = [ProjectStatus.New, ProjectStatus.Frozen, ProjectStatus.Active, ProjectStatus.Finished];

const UsersUnionProjectsBoard: React.FC<{
    union: UsersUnion;
    projects: Project[];
    invites: ProjectMemberInvite[];
    onChange?: (checked: boolean) => void;
}
> = ({union, projects, invites}) => {
    const [drop, setDrop] = useState<[Project | undefined, Project | undefined, string | undefined]>([undefined, undefined, undefined])
    const [inviteInfo, setInviteInfo] = useState<ProjectMemberInvite | null>(null);
    const {token}=theme.useToken()

    const [moveProject] = useProjectChangePriorityAndStatusMutation({
        optimisticResponse: ({projectId, status, priority}) => {
            const res = {
                projectChangePriorityAndStatus: {
                    id: projectId,
                    priority,
                    status
                }
            } as ProjectChangePriorityAndStatusMutation;
            return res;
        }
    })

    const byPriority = (p1: Project | GeneralBoardItemType<Project>, p2: Project | GeneralBoardItemType<Project>) => {

        const project1 = 'item' in p1 ? p1.item : p1
        const project2 = 'item' in p2 ? p2.item : p2

        if (project1.priority != project2.priority) return project1.priority < project2.priority ? 1 : -1
        return project1.id < project2.id ? 1 : -1
    };

    useEffect(() => {

        const [droppedProject, droppedOnProject, col] = drop
        if (!droppedProject) return

        const column = (droppedOnProject ? droppedOnProject.status : col) as string

        const columnProjects = projects.filter(value => value.status === column)

        let underPosition = droppedOnProject?.priority ?? 0
        const ps = columnProjects.filter(v => v.priority >= underPosition && v.id != droppedOnProject?.id).sort(byPriority);
        const upperProject = ps.pop();

        if (!underPosition && !upperProject?.priority) underPosition = 1000000;

        const upperPosition = upperProject?.priority ?? underPosition * 3
        const priority: number = underPosition + (upperPosition - underPosition) / 2;

        client.cache.modify({
            fields: {
                priority() {
                    return priority
                },
                status() {
                    return column
                }
            },
            id: client.cache.identify(droppedProject)
        })


        moveProject({
            variables: {
                projectId: droppedProject.id,
                usersUnionId: union.id,
                priority,
                status: column
            },
        })


    }, [drop])

    const boardProjects = projects.map((value) => ({
        id: value.id,
        columnId: value.status,
        item: value
    } as GeneralBoardItemType<Project>))

    const boardColumns = statuses.map((status) => ({
        id: status,
        title: t(`project.status.${status}`),
        item: status
    }) as GeneralBoardColumnType<string>)

    const projectsBoardColumnSplitter = (column: GeneralBoardColumnType<string>, items: GeneralBoardItemType<Project>[]) => {
        return items.filter(item => item.columnId === column.id).sort(byPriority)
    }

    const onProjectRender = (p: Project) => {
        const i = invites?.find(v => v.project.id == p.id);
        
        const [uuProjectMembers, projectMembers]=p.members.reduce<[ProjectMember[], ProjectMember[]]>
        (([uuMembers, projectMembers],m)=>{
            if(m.asUnion && m.asUnion.id===union.id)
                uuMembers.push(m)
            else
                projectMembers.push(m)

            return [uuMembers,projectMembers]
        }, [[],[]])

    
        return <ProjectsListCard key={p.id + '-' + p.status + '-' + p.priority} project={{...p, members: i? projectMembers : p.members}}
                                showStatus={false} showButtons={true}
                                showOwnerInfo
                                usersUnionMembersEditor={i && <UsersUnionProjectMembersEditor members={uuProjectMembers} onClick={()=>setInviteInfo(i)}/>}
                                showFavorite={true}></ProjectsListCard>
    }

    const onProjectDropped = (droppedProject: Project, droppedOnProject?: Project, column?: string) => {
        setDrop([droppedProject, droppedOnProject, column])
    }

    const columnTitle=(col: GeneralBoardColumnType<string>, items: GeneralBoardItemType<Project>[])=>{

        
        return <div style={{padding: '0px 0px 4px',display: 'flex', gap: 8}}>
        <span style={getStyleByStatus(col.item as ProjectStatus, token)}>• </span>
        <span>{t(col.title)}<PointDivider value={items.length} valueOpacity={1}/></span>
        
        </div>
    }

    const columnWidth=isMobile? 'calc(100% - 8px)' : `calc(${Math.round(100/4)}% - 1px)`
    
    return <div style={{flexGrow: 1}}>
        {inviteInfo && <ProcessProjectInvite invite={inviteInfo} union={union} onClose={() => setInviteInfo(null)}/>}
        <GeneralBoard
            columnTitle={columnTitle}
            signature='PROJECT'
            items={boardProjects}
            columns={boardColumns}
            columnsSplitter={projectsBoardColumnSplitter}
            onItemRender={onProjectRender}
            onItemDropped={onProjectDropped}
            columnWidth={columnWidth}
            padding={isMobile? '0px 16px' : '0px 24px'}
        />
    </div>
}

export default UsersUnionProjectsBoard;
import React from 'react'
import styled, { css } from 'styled-components'
import { AccessLevel, UsersUnion, UsersUnionMember } from '../../generated-types'
import { useTranslation } from 'react-i18next'
import UsersUnionsAvatar from './UsersUnionsAvatar'
import { Col, Row, Tabs } from 'antd'
import { Tab } from 'rc-tabs/lib/interface'
import PointDivider from '../PointDivider'
import { IconTeam } from '../Icon/IconTeam'
import { IconTasks } from '../Icon/IconTasks'
import { IconDocs } from '../Icon/IconDocs'
import { TabsStyled } from '../AntCastomComponents/Tabs.styled'
import { useReactiveVar } from '@apollo/client'
import { authState } from '../../routes/Auth/authContext'
import { IconSetting } from '../Icon/IconSetting'
import { isMobile } from 'react-device-detect'

interface UsersUnionHeaderProps {
    union: UsersUnion,
    onChangeTab: (tab: UsersUnionTabs) => void
    selectedTab: UsersUnionTabs
}


export enum UsersUnionTabs {
    projects = "projects",
    files = "files",
    members = "members",
    settings = "settings",
}

export const UserUnionHeaderContainer = styled.div`
    padding: 0px ${p => p.theme.isMobile ? '0' : '40px'};
    display: ${p => p.theme.isMobile ? 'block' : 'flex'};
    justify-content: space-between;
    background-color: ${p => p.theme.colors.ui.bgLight2};
    align-items: end;
    gap: 32px;
    width: 100%;
    
    >.team-title-container{
        display: flex;
        gap: 8px;
        align-items: center;
        flex-shrink: 0;
        padding: ${p => p.theme.isMobile ? '8px 16px' : '16px 0px'};
        max-width: ${p=>p.theme.isMobile? '100%' : '50%'};

        >.ant-avatar{
            flex-shrink: 0;
        }


        >.team-title{
            font-size: ${p=>p.theme.isMobile? '18px' : '24px'};
            font-weight: 600;
        }
    }

    >.tabs-container{
        min-width: 0px;

        ${p=>p.theme.isMobile && css`
            overflow-x: auto;
            overflow: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            &::-webkit-scrollbar {
                width: 0;
                height: 0;
            }
        `}

        >.tabs-scroll{
            width: fit-content;
            padding: 0px 16px;
        }
    }
`

function UsersUnionHeader({ union, selectedTab, onChangeTab }: UsersUnionHeaderProps) {

    const { t } = useTranslation()

    const authInfo = useReactiveVar(authState)
    const invitedProjects = union.projectInvites.map((v: any) => v.project);
    const isAdmin = union.members.some((m: UsersUnionMember) => m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin)

    const items = [
        {
            label: <div><IconTasks /> {t('usersUnion.projects')}<PointDivider hideZero={false} value={`${union.ownedProjects.length}${invitedProjects.length ? "+" + invitedProjects.length : ""}`} /></div>,
            key: UsersUnionTabs.projects,
            children: <></>,
        },
        {
            label: <div><IconDocs /> {t('usersUnion.files')}</div>,
            key: UsersUnionTabs.files,
            children: <></>,
        },
        {
            label: <div><IconTeam /> {t('usersUnion.members')}<PointDivider value={union.members.length} /></div>,
            key: UsersUnionTabs.members,
            children: <></>,
        }
    ]

    if (isAdmin)
        items.push({
            label: <div><IconSetting /> {t('usersUnion.settings')}</div>,
            key: UsersUnionTabs.settings,
            children: <></>,
        })


    const tabs=<TabsStyled defaultActiveKey={UsersUnionTabs.projects} activeKey={selectedTab} items={items} onChange={v => onChangeTab(UsersUnionTabs[v as keyof typeof UsersUnionTabs])} />

    return (
        <UserUnionHeaderContainer>
            <div className='team-title-container'>
                <UsersUnionsAvatar avatarUrl={union.avatarUrl} size={isMobile? '24' : '32'} />
                <span className='team-title'>{t("usersUnion.teamTitle", { team: union.title })}</span>
            </div>
            <div className='tabs-container'>
                {
                    isMobile? <div className='tabs-scroll'>{tabs}</div> : tabs
                }  
            </div>

        </UserUnionHeaderContainer>
    )
}

export default UsersUnionHeader
import React, {type SVGProps} from "react"

export function IconHelp(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
    <g clipPath="url(#clip0_3887_79788)">
        <path d="M7.5 8.79347C7.5 8.31935 7.5675 7.22889 9.07219 5.97439C9.96562 5.22955 11.0386 5.01383 12 5.00056C12.878 4.98965 13.6627 5.13995 14.1319 5.37131C14.9339 5.76862 16.5 6.73581 16.5 8.79347C16.5 10.9587 15.1322 11.9402 13.5773 13.0216C12.0225 14.1031 11.625 14.7844 11.625 16" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
        <circle cx="11.7002" cy="18.5" r="0.5" fill="currentColor"/>
        <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="currentColor" strokeMiterlimit="10"/>
    </g>
    <defs>
        <clipPath id="clip0_3887_79788">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}

import { theme } from 'antd'
import React, { PropsWithChildren } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { ThemeProvider, StyleSheetManager } from 'styled-components'

const propsFilter = (prop: string) => {
  return true;
  // return !['test-id'].includes(prop);
};

function StyledThemeProvider({children}:PropsWithChildren) {

    const { token } = theme.useToken()

    return (
        <ThemeProvider theme={{...token, isMobile: isMobileOnly}}>
            <StyleSheetManager shouldForwardProp={propsFilter}>
                {children}
            </StyleSheetManager>
        </ThemeProvider>
    )
}

export default StyledThemeProvider
import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { Link as Lnk } from 'react-router-dom';



export const Link = styled(Lnk)<{}>`
    color: ${p => p.theme.colors.ui.accent};
    transition: all .3s ease-in-out;
    
    &:hover {
        color: ${p => p.theme.colors.ui.accentHover};
    }
`

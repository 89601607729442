import React, {useState} from 'react';
import {Button, Col, Form, FormInstance, Input, message, notification, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {
    useCheckUsersUnionNameLazyQuery,
    User,
    UsersUnion,
    useUserPaymentAccountQuery,
    useUsersUnionDeleteMutation
} from "../../generated-types";
import UserAvatar from "../User/UserAvatar";
import Text from "antd/es/typography/Text";
import {useNavigate} from "react-router-dom";
import TooltipButton from "../TooltipButton";
import AvatarEditor from "../User/AvatarEditor";
import styled, {css} from "styled-components";
import SettingsPanel from "../SettingsPanel";
import { FormStyled } from '../AntCastomComponents/Form.styled';
import { IconDelete } from '../Icon/IconDelete';
import { TagsListEditor } from '../Tagger/TaggerDropdowned';
import { Link } from '../Link';

interface UsersUnionSettingsProps {
    union: UsersUnion,
    save: any,
    form: FormInstance,
    saveButton?: boolean
    deleteButton?: boolean
}

const Container=styled.div`
    padding: ${p=>p.theme.isMobile? '16px' : '24px 40px'};
    overflow-y: auto;
`
const StyledRow = styled.div<{}>`
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
    gap: 16px;
    

    


    .remove-btn{
        background-color: ${p=>p.theme.colors.ui.white};
        border: none;

        >.ant-btn-icon{
            font-size: 24px
        }
    }
`

const UsersUnionSettings = (props: UsersUnionSettingsProps) => {
    const {t} = useTranslation();
    const [isActiveSave, setActiveSave] = useState<boolean>(false); //Делает кнопку аутивной при изменении полей
    // эта переменная нужна, чтобы побороть проблему, когда кастомный валидатор поля name не отрабатывает на собранном приложении и не работает сохранение команды
    const [validatedName, setValidatedName] = useState<string>();
    const navigate = useNavigate();

    const isNew = !props.union?.id;

    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery({
        variables: {id: isNew ? null : props.union.paymentAccount.id}
    });

    const [checkUsersUnionName] = useCheckUsersUnionNameLazyQuery({})


    const [deleteUsersUnion, { loading}] = useUsersUnionDeleteMutation({
        onCompleted: (res) => {
            if (res.usersUnionDelete) {
                notification.success({
                    message: t("usersUnion.deletedSuccessfully"),
                    description: props.union.title,
                });
                navigate("/")
            }
        }
    });

    const onChangeHandler = (): void => {
        setActiveSave(true);
    }

    const onSaveHandler = (values: any): void => {
        setActiveSave(false);
        props.save(props.union.id, values)
    }

    const onErrors=(e: any)=>{
        e.errorFields.map((field: {errors: string[], name: string})=>field.errors.map(err=>message.error(`${t(`usersUnion.${field.name}`)}: ${err}`)))
    }

    const onDeleteHandler = (): void => {
        deleteUsersUnion({
            variables: {
                usersUnionId: props.union.id
            }
        })
    }

    return <Container>
                <StyledRow>
                    <Col flex="0 1 450px">
                        {/*<Col xs={24} sm={12} md={12} lg={9}>*/}
                        <SettingsPanel title={props.union.id && t('usersUnion.info')} gap={16}>

                            <FormStyled layout="vertical" requiredMark={false} form={props.form}
                                initialValues={props.union as any}
                                onFinish={onSaveHandler}
                                onFinishFailed={onErrors}
                                onChange={onChangeHandler}
                            >
                            <Row style={{gap: 16}} className='ant-form-item-row'>
                                {props.union.id && <Col>
                                    <AvatarEditor contextType={props.union.__typename} contextId={props.union.id} avatarUrl={props.union.avatarUrl}/>
                                </Col>}
                                <Col flex='auto'>
                                    <Form.Item
                                        name="title"
                                        label={t('usersUnion.title')}
                                        rules={[{required: true, message: "" + t('usersUnion.titlePlaceholder')},
                                            {message: t('usersUnion.oneHundredMax') as string, max: 100},
                                        ]}
                                    >
                                        <Input placeholder={"" + t('usersUnion.titlePlaceholder')}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                                <Form.Item
                                    // hasFeedback
                                    name="name"
                                    label={t('usersUnion.name')}
                                    // validateDebounce={500}
                                    rules={
                                        [
                                            {required: true, message: t('usersUnion.namePlaceholder') as string},
                                            {message: t('usersUnion.onlyNumbersAndSymbols') as string,
                                                type: "string",
                                                pattern: /^[a-z][a-z0-9]*$/},
                                            {min: 6, message: t('usersUnion.minSix') as string},
                                            {max: 100, message: t('usersUnion.oneHundredMax') as string},
                                            { validator: (obj, val) => {
                                                    if (val === validatedName) return Promise.resolve();

                                                    return new Promise<any | void>((resolve, reject) => {
                                                        checkUsersUnionName({
                                                            variables: {
                                                                id: props.union.id,
                                                                name: val
                                                            },
                                                            onCompleted: (data) => {
                                                                if (data && data.result) {
                                                                    resolve(undefined)
                                                                    setValidatedName(val)
                                                                } else
                                                                    reject(t('usersUnion.nameIsBusySelectAnother') as string)
                                                            }
                                                        })
                                                    })
                                                }
                                            }
                                        ]
                                    }
                                >
                                    <Input onInput={e=>e.currentTarget.value=e.currentTarget.value.toLowerCase()} placeholder={"" + t('usersUnion.namePlaceholder')}/>
                                </Form.Item>

                                <Form.Item
                                    name="description"
                                    label={"" + t('usersUnion.description')}
                                    rules={[
                                        {
                                            required: false, message: t('usersUnion.oneHundredMax') as string, max: 100
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={9} placeholder={"" + t('usersUnion.descriptionPlaceholder')}/>
                                </Form.Item>
                            </FormStyled>

                            {props.saveButton && <Button type={"primary"} disabled={!isActiveSave}
                                                                        onClick={()=>props.form.submit()}>{t('profile.save')}</Button>}
                        </SettingsPanel>
                    </Col>

                    <Col flex="0 1 450px" style={{display: "flex", flexDirection: "column", gap: 16}}>
                        {!isNew && <SettingsPanel height={100} title={t('usersUnion.paymentAccount')}>
                            {paymentAccount &&
                                                <Col>
                                                    <Link to={`/User/${paymentAccount?.id}`}>
                                                        
                                                        <UserAvatar key={paymentAccount?.id} user={paymentAccount.managers[0] as User}/>
                                                        <Text style={{marginLeft: 8}}>{paymentAccount?.title}</Text>
                                                    </Link>
                                                </Col>
                            }
                        </SettingsPanel>}
                        {!isNew && paymentAccount && <SettingsPanel height={287} title={t('project.settingsPage.tags')}>
                            <div className='scroll-container'>
                                            <TagsListEditor editorTagsList={props.union.tags} context={{
                                                usersUnionId: props.union.id
                                            }}/>
                            </div>
                                        </SettingsPanel>}
                        {props.deleteButton && 

                                    <TooltipButton loading={loading}
                                    className='remove-btn'
                                    icon={<IconDelete/>}
                                                danger={true}
                                                            type={"primary"}
                                                            ghost
                                                            tooltipProps={{
                                                    title: t('usersUnion.deletedConfirmation')
                                                }}
                                                popconfirmProps={{
                                                title: t('beCaution'),
                                                color: "red",
                                                description: t('usersUnion.deletedConfirmation'),
                                                onConfirm: onDeleteHandler,
                                                okText: t('usersUnion.delete'),
                                                okButtonProps: {
                                                    danger: true
                                                }
                                            }}
                                    >
                                {t('usersUnion.delete')}
                                    </TooltipButton>
                                    
                        }
                    </Col>
                </StyledRow>
    </Container>

};

export default UsersUnionSettings;

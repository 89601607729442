import { Button, Modal, Popconfirm, theme, Tooltip } from "antd";
import { useTaskRemoveMutation } from "../../generated-types";
import { DeleteOutlined } from "@ant-design/icons";
import React, { CSSProperties, useState } from "react";
import { useTranslation } from "react-i18next";
import RefetchesList from "../../queries/RefetchesList";
import { IconDelete } from "../Icon/IconDelete";
import { useReactiveVar } from "@apollo/client";
import { taskEditorState } from "./TaskEditor";
import Dropdowned, { CenterJustify } from "../Dropdowned";
import { isMobile, isMobileOnly } from "react-device-detect";
import { IconClose } from "../Icon/IconClose";
import styled from "styled-components";


export interface TaskRemoveBtnProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string,
    taskTitle: string,
    value?: boolean,
    onChange?: (value: boolean) => void;
    disabled?: boolean;
    fullView?: boolean;
    reverse?: boolean;
    buttonStyle?: CSSProperties
}

const ButtonStyled=styled(Button)<{$isMobile: boolean, $fullView?: boolean}>`
    color: ${({theme,$isMobile})=>$isMobile? theme.colors.font.red : theme.colors.font.primary};
    background-color: ${({theme, $fullView, $isMobile})=>$fullView? ($isMobile? 'unset' : theme.colors.ui.bgLight2) : 'unset'};

    &:hover{
        color: ${({theme})=>theme.colors.ui.redHover} !important;
        background-color: ${({theme, $fullView})=>$fullView? theme.colors.ui.bgLight : 'unset'} !important;
    }
`

function TaskRemoveBtn(props: TaskRemoveBtnProps) {
    const { t } = useTranslation();
    const [value, setValue] = useState<boolean>(props.value ?? false)
    const { token } = theme.useToken()
    const [hover, setHover] = useState(false)
    const [open, setOpen] = useState(false)
    const { ref } = useReactiveVar(taskEditorState)!

    const [taskRemove] = useTaskRemoveMutation({
        onCompleted: () => {
            if (props.onChange) {
                props.onChange(value)
            }
        },
        refetchQueries: [RefetchesList.TasksByViewId, RefetchesList.TasksCount]
    })

    const redColor = hover ? token.colors.ui.redHover : token.colors.font.red

    const buttonStyle: CSSProperties = {
        //color: redColor,
        borderRadius: 8,
        //backgroundColor: 'inherit',
        flexDirection: props.reverse ? 'row-reverse' : 'row',
        height: props.fullView? 32 : 24,
        ...props.buttonStyle
    }

    const confirmButtonStyle: CSSProperties = {
        flexGrow: 1,
        fontSize: 12,
        fontWeight: 400,
        border: 'none',
        boxShadow: 'none',
        borderRadius: 8
    }

    const confirmTitle = t('task.confirmRemoveTaskTitle')
    return (
        <Tooltip title={t("task.removeTask")}>
                <ButtonStyled 
                test-id="taskRemove-button"
                $isMobile={isMobileOnly}
                $fullView={props.fullView}
                    type="text"
                    icon={<IconDelete style={{ strokeWidth: 1.5 }}/> }
                    style={buttonStyle}
                    styles={{ icon: { fontSize: 16, display: 'inline-flex', justifyContent: 'center', alignItems: 'center' } }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={() => setOpen(true)}
                >

                    {props.fullView && <span>{t("task.removeTask")}</span>}
                </ButtonStyled>
            {open && <Dropdowned opened={open}
                onClose={() => setOpen(false)}
                anchor={ref}
                popupOnly
                centerJustify={CenterJustify.center}
                backdrop>
                <div style={{ width: 290, padding: 16, borderRadius: 16 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ fontWeight: 500 }}>{confirmTitle}</span>
                        <Button
                            onClick={() => setOpen(false)}
                            style={{ padding: 0, backgroundColor: 'unset', color: 'inherit', border: 'none', boxShadow: 'none', width: 16, height: 16 }}
                            icon={<IconClose style={{ strokeWidth: 1.5, fontSize: 16 }} />}
                        />
                    </div>
                    <div style={{ padding: '12px 0px' }}>
                        <span>{t('task.confirmRemoveTaskDescription')}</span>
                        <span style={{ fontWeight: 500 }}> "{props.taskTitle}"</span>
                    </div>
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Button
                            test-id="taskRemoveConfirm-button"
                            onClick={() => {
                                setValue(true)
                                taskRemove({ variables: { id: props.id } })
                            }}
                            style={{ ...confirmButtonStyle, backgroundColor: redColor, color: token.colors.font.white }}
                        >{t('remove')}</Button>
                        <Button
                            onClick={()=>setOpen(false)}
                            style={{ ...confirmButtonStyle, backgroundColor: token.colors.ui.bgLight, color: 'inherit' }}
                        >{t('cancel')}</Button>
                    </div>
                </div>
            </Dropdowned>}
        </Tooltip>)
}

export default TaskRemoveBtn
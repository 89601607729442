import React, {useEffect, useMemo, useState} from 'react'
import {Button, Checkbox, CollapseProps, Divider, Row, Typography} from 'antd'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {FolderAddOutlined} from "@ant-design/icons";
import {
    Project,
    useProjectsListLazyQuery,
    User,
    UsersUnion,
    UsersUnionMemberInvite,
    useUsersUnionsListLazyQuery,
} from "../../generated-types";
import UsersUnionNew from "../UsersUnion/UsersUnionNew";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import ProjectsGroup from "./ProjectsGroup";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {AllProjects, allProjects} from "../../subscriptions/allProjects";
import Spinner from "../Spinner";
import SearchInput from '../SearchInput';
import styled from 'styled-components';
import { CollapseStyled } from '../AntCastomComponents/Collapse.styled';
import { IconArrowRight } from '../Icon/IconArrowRight';
import UserAvatar from '../User/UserAvatar';
import UsersUnionsAvatar from '../UsersUnion/UsersUnionsAvatar';
import PointDivider from '../PointDivider';
import { Link } from '../Link';

const {Title} = Typography;

interface ProjectsGroupTitleProps {
    owner: User | UsersUnion
    linkToItem?: boolean
    additionalInfo?: any
    projectsCount: number
}

const ProjectsGroupTitleContainer=styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    

    >.group-title-text{
        color: ${p=>p.theme.colors.font.primary};
        font-size: 20px;
        font-weight: 600;

        >.group-title-link{
            color: inherit;
            transition: all .3s ease-in-out;

            &:hover{
                opacity: 0.7;
            }
        }
    }
    
`

const ProjectsGroupTitle = (props: ProjectsGroupTitleProps) => {
    const {t} = useTranslation()
    const {owner} = props;
    let id: string = "";
    let name: string | null = null;
    let caption: string | null = null;
    let avatar: JSX.Element | null =null;

    //TODO: почему не получается нормально работать с юнионами?
    if (!owner.__typename) {
        name = (owner as User).username;
        avatar=<UserAvatar size='40' user={owner as User}/>
    } else if (owner.__typename == "User") {
        id = "/User/" + (owner as User).id;
        // caption = t("aMemberInProjectOfUser");
        name = (owner as User).username;
        avatar=<UserAvatar size='40' user={owner as User}/>
    } else if (owner.__typename == "UsersUnion") {
        id = "/UsersUnion/" + owner.id;
        // caption = t("aMemberInProjectOfUsersUnion");
        name = (owner as UsersUnion).title;
        avatar=<UsersUnionsAvatar size='40' uu={owner}/>
    }

    return <ProjectsGroupTitleContainer>
        {avatar}
        <span className='group-title-text'>
            {props.linkToItem == false ? name : <Link className='group-title-link' to={id}>{name}</Link>}
            <PointDivider value={props.projectsCount} valueOpacity={1}/>
        </span>
    </ProjectsGroupTitleContainer>
}

function groupBy<T>(arr: T[], fn: (item: T) => any): Record<string, T[]> {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return {...prev, [groupKey]: group};
    }, {});
}


const LinkToUsersUnionProjectInvites = ({usersUnion}: { usersUnion: UsersUnion }) => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    if (usersUnion.projectInvites?.length == 0)
        return null;
    return <>
        <Divider type={"vertical"}></Divider>
        <Button type="link" icon={<FolderAddOutlined rev={undefined}/>}
                onClick={() => navigate(`/usersUnion/${usersUnion.id}/projects`)}
        >{t('usersUnion.projectsInvitesLink', {count: usersUnion.projectInvites.length})}</Button>
    </>
}

const ProjectListContainer=styled.div<{$mainPage: boolean}>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    >.projects-list-content{
        flex-grow: 1;
        overflow-y: auto;
        padding: 0px 0px 24px;

        .list{
            padding: 0px 40px;
        }
    }

    >.projects-list-header{
        
        padding: 24px 40px ${p=>p.$mainPage? '24px' : '0px'};
        flex-direction: column;
        gap: 16px;
        background-color: ${p=>p.$mainPage? p.theme.colors.ui.bgLight2 : p.theme.colors.ui.bgLight};

        >.projects-list-header-controlls{
            width: 100%;
            display: flex;
            gap: 16px
        }
    }
`

const SectionTitle=styled.span<{$pageTitle?: boolean}>`
    display: block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    padding-top: ${p=>p.$pageTitle? '0px' : '40px'};

`

const ArchivedChanger=styled.div`
    height: 100%;
    background-color: ${p=>p.theme.colors.ui.bgLight3};
    border-radius: 8px;
    padding: 8px;
    display: flex;
    gap: 8px;
    flex-grow: 1;
    font-size: 16px;
    min-width: fit-content;
    font-weight: 300;
    
    .ant-checkbox-inner{
        background-color: unset;
        width: inherit;
        height: inherit;
        border-color: ${p=>p.theme.colors.font.primarySemitransparent};

        &::after{
            inset-inline-start: 35%
        }
    }
    
    .ant-checkbox{
        width: 24px;
        height: 24px;
    }

    .ant-checkbox-checked{
        background-color: ${p=>p.theme.colors.ui.accent};
    }
`
function ProjectsList({onlyMyProjects, onlyUsersUnions, onlyPeoples, onlyFavourites}: {
    onlyMyProjects?: boolean,
    onlyUsersUnions?: boolean,
    onlyPeoples?: boolean,
    onlyFavourites?: boolean
}) {
    const [search, setSearch] = useState<string>();
    const {t} = useTranslation();
    const authInfo = useReactiveVar(authState);
    const [onlyArchived, setOnlyArchived] = useState<boolean>(false);

    const showAll = !onlyMyProjects && !onlyUsersUnions && !onlyPeoples && !onlyFavourites;

    let myUnions = useReactiveVar(allUsersUnions);
    let allP = useReactiveVar(allProjects);

    const [archivedUsersUnions, {data: usersUnions, loading: loading1}] = useUsersUnionsListLazyQuery({
        variables: {
            showArchived: onlyArchived
        }
    })
    const [archivedProjectsList, {data: projects, loading: loading2}] = useProjectsListLazyQuery({
        variables: {
            filter: {
                archived: onlyArchived
            }
        }
    })

    useEffect(() => {
        if (onlyArchived) {
            archivedUsersUnions();
            archivedProjectsList();
        }
    }, [onlyArchived]);


    if (onlyArchived) {
        myUnions = {
            unions: usersUnions?.usersUnions as UsersUnion[] ?? [],
            invites: usersUnions?.usersUnionsInvites as UsersUnionMemberInvite[] ?? []
        };
        allP = new AllProjects(projects?.projects as Project[]);
    }


    let myUnionsProjects: UsersUnion[] = [];
    if (search) {
        if (myUnions.unions.some((u) => u.title.toUpperCase().includes(search.toUpperCase()))) {
            myUnionsProjects = myUnions.unions.filter(u => u.title.toUpperCase().includes(search.toUpperCase()))
        } else if (myUnions.unions.some(u => u.ownedProjects.some(p => p.title.toUpperCase().includes(search.toUpperCase())))) {
            myUnionsProjects = myUnions.unions.filter(u => u.ownedProjects.some(p => p.title.toUpperCase().includes(search.toUpperCase())))
        }
    } else myUnionsProjects = myUnions.unions

    const elements1: any[] = useMemo(() => {
        const elements: any[] = [];

        if (showAll || onlyFavourites){
            const favProjects=allP.favoriteProjects(search)
            if(!onlyFavourites)
                elements.push(<SectionTitle key={'favouriteProjects'}>{t("favoriteProjects")}<PointDivider value={favProjects.length} valueOpacity={1}/></SectionTitle>);

            elements.push(<ProjectsGroup key={"favorites"} projects={favProjects} context={authInfo.user} archived={onlyArchived}/>)
        }


        if (showAll || onlyMyProjects) {

            const myProjects=allP.myProjects(search)
            if (!onlyMyProjects)
                elements.push(<SectionTitle key={'personalProjects'}>{t("personalProjects")}<PointDivider value={myProjects.length} valueOpacity={1}/></SectionTitle>);

            elements.push(
              <ProjectsGroup key={"my" + authInfo.user.id} projects={myProjects} context={authInfo.user} archived={onlyArchived}/>
        );
        }

        // Список проектов, к которым у текущего пользователя есть доступ от лица объединения
        const accessAsUnionsToProjects: any[] = []

        if (myUnions && (showAll || onlyUsersUnions)) {
            if (!onlyUsersUnions)
                elements.push(<SectionTitle key={'teams'}>{t("usersUnion.teams") as string}</SectionTitle>);

            const items1: CollapseProps['items'] = []

            for (const i in myUnionsProjects) {
                const uu = myUnionsProjects[i];
                let projects = allP.myUnionsProjects(search).filter(p => p.usersUnionAsOwner && p.usersUnionAsOwner.id == uu.id);

                projects = projects.concat(accessAsUnionsToProjects.filter(aup => aup.accessAsUnion.id == uu.id))

                items1.push({
                    key: "uu" + uu.id,
                    label: <ProjectsGroupTitle projectsCount={projects.length} owner={uu} additionalInfo={<>
                        <LinkToUsersUnionProjectInvites usersUnion={uu}/>
                    </>}/>,
                    children: <ProjectsGroup projects={projects} context={uu as UsersUnion} archived={onlyArchived}/>
                });
            }

            const expanded = items1.map(v => v.key) as string[];

            elements.push(<CollapseStyled key={'myUnionsProjects'} expandIcon={()=><IconArrowRight/>} items={items1} defaultActiveKey={expanded[0]}/>)
        }

        let otherUsers = groupBy(allP.otherUsersProjects(search), v => v.userAsOwner?.id);
        if (otherUsers && (showAll || onlyPeoples)) {
            if (!onlyPeoples)
                elements.push(<SectionTitle key={'otherUsers'}>{t("otherUsersProjects")}</SectionTitle>);

            const items: CollapseProps['items'] = []
            for (const i in otherUsers) {
                if (!i) continue
                const projects = (otherUsers[i] as Array<Project>).filter(p => {
                    if (p.accessAsUnion == null) return true
                    accessAsUnionsToProjects.push(p);
                    return false;
                });
                if (projects.length == 0)
                    break;

                const u = projects[0].userAsOwner as User;
                if (!u) continue

                items.push({
                    key: "u"+i,
                    label: <ProjectsGroupTitle owner={u} linkToItem={true} projectsCount={projects.length}/>,
                    children: <ProjectsGroup projects={projects} archived={onlyArchived}/>
                })

            }
            const defaultKey=items[0]?.key as string?? ''
            elements.push(<CollapseStyled key={'otherUsersProjects'} expandIcon={()=><IconArrowRight/>} items={items} defaultActiveKey={defaultKey}/>);
        }

        const otherUnions = allP.otherUnions(search);
        if (otherUnions.length > 0 && (showAll || onlyUsersUnions)) {
            if (!onlyUsersUnions)
                elements.push(<SectionTitle key={'otherUsersUnions'}>{t("otherUsersUnionsProjects")}</SectionTitle>);

            const items: CollapseProps['items']=[]

            for (const i in otherUnions) {
                const uu = otherUnions[i];
                let projects = allP.projects.filter(p => p.usersUnionAsOwner && p.usersUnionAsOwner.id == uu.id);

                projects = projects.concat(accessAsUnionsToProjects.filter(aup => aup.accessAsUnion.id == uu.id))

                items.push({
                    key: "uu"+uu.id,
                    label: <ProjectsGroupTitle owner={uu} linkToItem={false} projectsCount={projects.length}/>,
                    children: <ProjectsGroup projects={projects} archived={onlyArchived}/>
                })
            }
            const defaultKey=items[0]?.key as string?? ''
            elements.push(<CollapseStyled key={'otherUsersUnionsProjects'} expandIcon={()=><IconArrowRight/>} items={items} defaultActiveKey={defaultKey}/>);
        }
        return elements;
    }, [myUnionsProjects, myUnions, showAll, onlyUsersUnions, onlyPeoples, onlyMyProjects,allP]);

    let title = '';
    if (onlyMyProjects) title = t("personalProjects");
    if (onlyUsersUnions) title = t("usersUnion.teams");
    if (onlyPeoples) title = t("otherUsersProjects");

    return <ProjectListContainer $mainPage={title==''}>
        <Spinner loading={[loading2, loading1]} />
            <Row wrap={true} className='projects-list-header'>
                    {title && <SectionTitle $pageTitle>{title}</SectionTitle>}
                    <div className='projects-list-header-controlls'>
                        <ArchivedChanger>
                            <Checkbox checked={onlyArchived} onChange={(e) => setOnlyArchived(e.target.checked)}/>
                            <span>{t('project.showArchivedProjects')}</span>
                        </ArchivedChanger>
                        <SearchInput placeHolder={t('search') as string} onSearch={(value) => setSearch(value)}/>
                        <UsersUnionNew/>
                    </div>
            </Row>
            <div className='projects-list-content'>
            {/*!title && <MainPageInfo/>*/}
                <Row datatype="flex" className='list'>{elements1}</Row>
            </div>
            </ProjectListContainer>
}

export default ProjectsList
import React, { useState } from 'react'
import styled from 'styled-components'
import { ProjectMember, ProjectMemberInvite, UsersUnion, UsersUnionAddMemberInput } from '../../generated-types'
import { Button } from 'antd'
import { IconUserProject } from '../Icon/IconUserProject'
import { IconUserEdit } from '../Icon/IconUserEdit'
import ProcessProjectInvite from './ProcessProjectInvite'
import { useTranslation } from 'react-i18next'
import ProjectMembers from '../Project/ProjectMembers'

interface UsersUnionProjectMembersEditorProps {
    /*inviteInfo: ProjectMemberInvite,
    union: UsersUnion*/

    onClick: ()=>void,
    members?: ProjectMember[]
}
const EditorContainer = styled.div`
    cursor: pointer;
    border: ${p => p.theme.colors.ui.bgLight} 1px solid;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    gap: 4px;
    align-items: center;
    /*flex-shrink: 1;
    
    min-width: 0;
    width: min-content;
    flex-grow: 1;*/

    >.editor-title{
        font-size: 10px;
        font-weight: 300;
        max-width: 60px;
        opacity: 0.5;
        line-height: 1;
        flex-shrink: 0;
    }

    &:hover>.editor-btn{
        
        background-color: ${p => p.theme.colors.ui.bgLight3} !important;
        color: ${p => p.theme.colors.ui.accentHover} !important;
    
    }
`

const ButtonStyled = styled(Button)`

    border-radius: 100%;
    background-color: ${p => p.theme.colors.ui.bgLight};
    color: ${p => p.theme.colors.font.accent};
    flex-shrink: 0;
    transition: all .3s ease-in-out;

    &:hover{
        background-color: ${p => p.theme.colors.ui.bgLight3} !important;
        color: ${p => p.theme.colors.ui.accentHover} !important;
    }

    >.ant-btn-icon{
        font-size: 16px;
    }

`

function UsersUnionProjectMembersEditorBtn({ onClick, members }: UsersUnionProjectMembersEditorProps) {

    const {t}=useTranslation()

    return (
        <EditorContainer onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onClick()
        }}
            >
            <span className='editor-title'>{t('usersUnion.processProjectInvite.membersAsUsersUnion')}</span>
            <ButtonStyled className='editor-btn' type={'text'} size='small' icon={<IconUserEdit />}/>
            <ProjectMembers members={members??[]} responsible={false}/>
        </EditorContainer>
    )
}

export default UsersUnionProjectMembersEditorBtn
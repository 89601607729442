import React from 'react'
import { ProjectStats } from '../../generated-types'
import styled from 'styled-components';
import { Progress, theme } from 'antd';
import { useTranslation } from 'react-i18next';

const ProjectStatisticsContainer = styled.div`
    width: 100%;
    height: 27px;
    display: block;
    position: relative;

    >span{
        font-weight: 300;
        font-size: 12px;
        line-height: 1;
    }

    >.ant-progress{
        position: absolute !important;
        top: 20px;
        left: 0;
        line-height: 0.1 !important;
        
    }
    
`
function ProjectStatistics({ stats }: { stats: ProjectStats }) {

    const { token } = theme.useToken()
    const { t } = useTranslation()


    const { totalTasks, doneTasks } = stats;
    return (
        <ProjectStatisticsContainer>
            {totalTasks > 0 ? <>
                                <span>{t('project.doneTasks', { done: doneTasks, total: totalTasks })}</span>
                                <Progress size={{ height: 4 }} percent={doneTasks / totalTasks * 100} strokeColor={[token.colors.ui.accent, token.colors.ui.bgLight]} showInfo={false} />
                            </>
                            :
                            <span>{t('project.noTasks')}</span>
            }
        </ProjectStatisticsContainer>

    )
}

export default ProjectStatistics
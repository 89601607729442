import { Collapse } from "antd";
import styled from "styled-components";

export const CollapseStyled=styled(Collapse)`
    border: none;
    background-color: unset;
    width: 100%;


    >.ant-collapse-item{

        border: none;

        >.ant-collapse-header{
            padding: 24px 0px 0px 0px;
            align-items: center;
            gap: 16px;

            >.ant-collapse-expand-icon{
                width: 24px;
                height: 24px;
                padding: 0;
                border-radius: 4px;
                background-color: ${p=>p.theme.colors.ui.bgLight3};
                display: flex;
                justify-content: center;
                align-items: center;
               
        
                >svg{
                    stroke-width: 1.5px;
                    width: 16px;
                    height: 16px;
                    transition: all .3s ease-in-out;
                    transform: rotate(-90deg);
                }
            }

            >.ant-collapse-header-text{
                margin: 0;
            }
        }

        >.ant-collapse-content{
            border: none;
            background-color: unset;

            >.ant-collapse-content-box{
                padding: 0;
            }
        }
    
        &.ant-collapse-item-active{
            >.ant-collapse-header{

                >.ant-collapse-expand-icon{

                    >svg{
                    transform: rotate(90deg);
                    }
                }
            }
        }
    }
`
import React, { useEffect, useRef, useState } from 'react'
import { Project, ProjectMember, ProjectMemberInvite } from '../../generated-types'
import { useReactiveVar } from '@apollo/client';
import { authState } from '../../routes/Auth/authContext';
import UserAvatar from '../User/UserAvatar';
import { Avatar, theme } from 'antd';
import { AvatarGroupStyled, MaxAvatar, MembersContainer, OverrideContent } from '../AntCastomComponents/Avatars.styled';
import Dropdowned from '../Dropdowned';

interface ProjectMembersProps {
    members: ProjectMember[],
    invite?: ProjectMemberInvite,
    responsible?: boolean
}

function ProjectMembers({ members, invite, responsible = true }: ProjectMembersProps) {

    const { token } = theme.useToken();
    const [maxCount, setMaxCount] = useState(2)
    const [showOverride, setShowOverride] = useState(false)
    const showOverrideBtnRef = useRef<HTMLSpanElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const authInfo = useReactiveVar(authState)

    useEffect(() => {

        if (responsible) {
            const setShowAvatarsCount = () => {
                const container = containerRef.current
                if (container) {
                    const containerWidth = container.clientWidth
                    let maxCount = Math.floor((containerWidth + 4) / 28)
                    if (maxCount < members.length)
                        maxCount = maxCount - 1
                    setMaxCount(maxCount)
                }
            }
            setShowAvatarsCount()

            window.addEventListener('resize', setShowAvatarsCount)

            return () => {
                window.removeEventListener('resize', setShowAvatarsCount)
            }

        }
    }, [containerRef.current])

    return (
        <MembersContainer ref={containerRef}>
            {invite
                ?
                <AvatarGroupStyled
                    size="default"
                >
                    {<UserAvatar user={invite.inviter} />}
                </AvatarGroupStyled>
                :
                <>
                    <AvatarGroupStyled
                        size="default"
                    >
                        {members?.slice(0,members.length>maxCount? maxCount-1 : maxCount).map(member => <UserAvatar key={member.user.id} user={member.user} />)}
                    </AvatarGroupStyled>
                    {members.length > maxCount && <>

                        <MaxAvatar onClick={(e) => {
                            e?.preventDefault()
                            e?.stopPropagation()
                            setShowOverride(true)
                        }} ref={showOverrideBtnRef}>
                            +{members.length - maxCount+1}
                        </MaxAvatar>
                        {showOverride && <Dropdowned popupOnly onClose={() => setShowOverride(false)} opened={showOverride} anchor={showOverrideBtnRef}>
                            <OverrideContent>
                                {members.slice(maxCount-1).map(member => <div>
                                    <UserAvatar key={member.user.id} user={member.user} />
                                    <span className="user-name">{member.user.fullName || member.user.username}</span>
                                </div>
                                )}
                            </OverrideContent>
                        </Dropdowned>}
                    </>
                    }
                </>
            }
        </MembersContainer>
    )
}

export default ProjectMembers
import React from 'react'
import { Task } from '../../generated-types'
import { styled } from 'styled-components'
import dayjs from 'dayjs'
import PointDivider from '../PointDivider';

const dateFormat = 'DD.MM';
const timeFormat = 'HH:mm'
interface TaskDatesProps {
    task: Task
}

const TaskDatesContainer = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    flex-shrink: 0;
`

const TaskDate = styled.span`
    background-color: ${({ theme }) => theme.colors.ui.bgLight};
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 12px;
    font-weight: 300;
    line-height: 1;
`
function TaskDates({ task }: TaskDatesProps) {

    return (
        <TaskDatesContainer>
            {task.startDate && <TaskDate>
                с {dayjs(task.startDate).format(dateFormat)}
                {task.startTime && <PointDivider valueOpacity={1} value={dayjs(task.startTime).format(timeFormat)} />}
            </TaskDate>}
            {task.startDate && task.endDate && <span>—</span>}
            {task.endDate && <TaskDate>
                до {dayjs(task.endDate).format(dateFormat)}
                {task.endTime && <PointDivider valueOpacity={1} value={dayjs(task.endTime).format(timeFormat)} />}
            </TaskDate>}

        </TaskDatesContainer>
    )
}

export default TaskDates
import React from "react";
import styled, {css} from "styled-components";
import SettingsPanel from "../SettingsPanel";
import {
    Project,
    useProjectSwitchArchivedMutation,
    useProjectUpdateMutation,
    User,
    useUserPaymentAccountQuery
} from "../../generated-types";
import ProjectSettings from "./ProjectSettings";
import {Button, Col, Form, message} from "antd";
import TooltipButton from "../TooltipButton";
import ProjectTransfer from "./ProjectTransfer";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import {useTranslation} from "react-i18next";
import Text from "antd/es/typography/Text";
import UserAvatar from "../User/UserAvatar";
import {TagsListEditor} from "../Tagger/TaggerDropdowned";
import Spinner from "../Spinner";
import {IconArchive} from "../Icon/IconArchive";
import {Icon} from "../Icon/Icon";
import { Link } from '../Link';

interface ProjectSettingsPageProps {
    project: Project
    isAdmin: boolean
}

const StyledRow = styled.div<{}>`
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
    gap: 16px;

    ${p => !p.theme.isMobile && css`
        margin-top: 16px;
        padding-left: 40px;
    `};
`

export const ProjectSettingsPage: React.FC<ProjectSettingsPageProps> = ({project, isAdmin}) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage()

    const [projectUpdate] = useProjectUpdateMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        },
        onCompleted: () => success()
    });

    const [archivedProject] = useProjectSwitchArchivedMutation({
        update: (cache, r) => {
            const project = r.data?.projectSwitchArchived as Project;
            updateProjectInCache(cache, project)
        }
    });

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('editDataMassage'),
            style: {
                marginTop: '20vh',
            },
        });
    };

    const save = (input: any) => {
        projectUpdate({variables: {projectId: project.id, input: {...input, status: project.status} }})
    };

    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery({
        variables: {id: project.paymentAccount.id}
    });

    return (
        <StyledRow>
            <Col flex="0 1 450px">
                {/*<Col xs={24} sm={12} md={12} lg={9}>*/}
                <SettingsPanel height={671} title={t('project.settingsPage.info')}>
                    <div style={{flexGrow: 1}}>
                        <ProjectSettings project={project} form={form} save={save}/>
                    </div>
                    <Button type="primary" size={"middle"} block onClick={form.submit} htmlType="submit">
                        {t('project.saveChanges')}
                    </Button>
                </SettingsPanel>
            </Col>

            <Col flex="0 1 450px" style={{display: "flex", flexDirection: "column", gap: 16}}>
                <SettingsPanel height={158} title={t('project.settingsPage.projectOwner')}>
                    {paymentAccount ?
                        <Link to={`/User/${paymentAccount?.id}`}>
                            <UserAvatar key={paymentAccount?.id} user={paymentAccount.managers[0] as User}/>
                            <Text style={{marginLeft: 8}}>{paymentAccount?.title}</Text>
                        </Link>
                        : <Spinner loading={true}/>
                    }
                    {isAdmin && <ProjectTransfer project={project}/>}
                </SettingsPanel>

                <SettingsPanel height={287} title={t('project.settingsPage.tags')}>
                    <div className="scroll-container">
                    <TagsListEditor editorTagsList={project.tags} context={{
                        projectId: project.id
                    }}/>
                    </div>
                </SettingsPanel>

                <SettingsPanel height={194} title={t('project.settingsPage.archive')}>
                    <div style={{lineHeight: "110%"}}>{t('project.settingsPage.archiveDescription')}</div>
                    <TooltipButton ghost block size={"middle"} icon={<Icon icon={<IconArchive/>} size={"24"}/>}
                                   onClick={() => archivedProject({
                        variables: {
                            projectSwitchArchivedId: project.id
                        }
                    })} tooltipProps={{title: t('project.archiveTooltip')}}>
                        {project.archived ? t('project.unArchiving') : t('project.archiving')}
                    </TooltipButton>
                </SettingsPanel>
            </Col>
            {contextHolder}
        </StyledRow>
    )
};

export default ProjectSettingsPage;
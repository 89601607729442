import React, {useState} from 'react';
import {Alert, Button, Col, Form, Input, Row} from 'antd';
import {UserOutlined, LockOutlined, MailOutlined} from "@ant-design/icons";
import {saveToken} from "./authContext";
import {UserSignUpInput, useSignUpMutation} from "../../generated-types";
import { useTranslation } from 'react-i18next';
import Spinner from "../../Components/Spinner";
import { useNavigate } from 'react-router-dom';
import { Link } from '../../Components/Link';

const initState: UserSignUpInput  = {username: "", password: "", email: ""};

const SignUp: React.FC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [state, setState] = useState(initState);
    const [signUp, {loading, error }] = useSignUpMutation({
        onCompleted: (data) => {
            saveToken(data.userSignUp.token);
            navigate("/")
        }
    });

    return (
        <Row justify="center" align="top" style={{marginTop: "50px"}}>
            <Col xs={24} sm={20} md={12} lg={8}>
                <Form
                    name="basic"
                    initialValues={state}
                    onFinish={async (data: UserSignUpInput) => {
                        setState(data);
                        await signUp({variables: {data}})
                    }}
                    // onFinishFailed={undefined}
                    autoComplete="off"
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: t("auth.inputUserName") as string }]}
                    >
                        <Input
                            prefix={<UserOutlined rev={undefined} style={{color: "grey"}} />}
                            placeholder={t("auth.username") as string}
                        />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: t("auth.inputEmail") as string, type: "email"}]}
                    >
                        <Input
                            prefix={<MailOutlined style={{color: "grey"}} rev={undefined} />}
                            placeholder={t("auth.email") as string}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t("auth.inputPassword") as string}]}
                    >
                        <Input.Password
                            prefix={<LockOutlined rev={undefined} />}
                            placeholder={t("auth.password") as string}
                        />
                    </Form.Item>

                    {/*<Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>*/}
                    {/*    <Checkbox>Remember me</Checkbox>*/}
                    {/*</Form.Item>*/}

                    <Row justify="center" style={{marginBottom: "10px"}}>
                        <Button type="primary" htmlType="submit" disabled={loading} test-id="signUp-btn">
                            {t("auth.signUp")}
                        </Button>
                        <Link to={"/signIn"}>
                            <Button type="link" disabled={loading}>
                                {t("auth.signIn")}
                            </Button>
                        </Link>
                    </Row>
                    <Row justify="center">
                        <Spinner loading={loading} />
                        {error != null && <Alert type="error" message={error.message} banner />}
                    </Row>
            </Form>
        </Col>
        </Row>
    )
};

export default SignUp;

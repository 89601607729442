import React, {type SVGProps} from "react"

export function IconUserEdit(props: SVGProps<SVGSVGElement>) {
    
    return (
<svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
<g id="name=user edit, size=24" clipPath="url(#clip0_4248_125740)">
<g id="Frame 415">
<path id="Vector" d="M10.233 12.467C13.1976 12.467 15.6008 10.012 15.6008 6.98361C15.6008 3.95523 13.1976 1.50024 10.233 1.50024C7.26847 1.50024 4.86523 3.95523 4.86523 6.98361C4.86523 10.012 7.26847 12.467 10.233 12.467Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
<g id="Group">
<path id="Vector_2" d="M13 22.5C13 22.5 12.2331 22.5003 10.3338 22.5003C6.47886 22.5003 3.18423 20.6686 1.67139 18.3003" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
<path id="Vector_3" d="M13 15.5C12 15.7683 11.1159 15.7683 10.2665 15.7683C8.4511 15.7683 6.8374 15.22 5.25732 14.4617" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
<path id="Vector_4" d="M1.70494 18.3585C1.29031 17.6585 1.49202 16.4218 2.33249 15.4184C3.2514 14.3101 4.40564 14.1001 5.26852 14.4618" stroke="currentColor" strokeMiterlimit="10"/>
</g>
</g>
<g id="_&#208;&#160;&#208;&#142;&#208;&#160;&#194;&#187;&#208;&#160;&#209;&#149;&#208;&#160;&#226;&#132;&#150;_1" clipPath="url(#clip1_4248_125740)">
<path id="Vector_5" d="M15.4604 19.8443L22.6856 12.6081C23.0031 12.2906 23.0031 11.7761 22.6856 11.4586L21.5362 10.3092C21.2187 9.99168 20.7042 9.99168 20.3867 10.3092L13.1559 17.5563C13.1012 17.611 13.0684 17.6877 13.0738 17.7643L13.1231 19.6473C13.1231 19.8005 13.2545 19.9264 13.4077 19.9264H15.2578C15.3345 19.9264 15.4056 19.8936 15.4604 19.8443Z" stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
</g>
</g>
<defs>
<clipPath id="clip0_4248_125740">
<rect width="24" height="24" fill="white"/>
</clipPath>
<clipPath id="clip1_4248_125740">
<rect width="12" height="12" fill="white" transform="translate(12 9)"/>
</clipPath>
</defs>
</svg>

    )
}

import React, { useEffect, useMemo, useState } from 'react';
import { Button, message, Modal, notification, Popconfirm, Table } from 'antd';
import {
    AccessLevel,
    InviteResolution,
    Project, ProjectMember,
    ProjectMemberInvite,
    useProjectLeaveMutation,
    useProjectMemberChangeLevelMutation, useProjectMemberInviteChangeMaxMembersMutation,
    useProjectMemberInviteRemoveMutation,
    useProjectRemoveMemberMutation,
    User,
    UsersUnion,
} from '../../generated-types';
import { useTranslation } from 'react-i18next';
import AccessLevelSelector from '../AccessLevelSelector';
import { showErrors, showLoading } from '../helpers';
import { authState } from '../../routes/Auth/authContext';
import { useReactiveVar } from '@apollo/client';
import { updateProjectInCache } from '../../subscriptions/allProjects';
import styled, { css } from 'styled-components';
import ProjectMembersEditor2 from './ProjectMembersEditor2';
import { IconSorting } from '../Icon/IconSorting';
import UserAvatar from '../User/UserAvatar';
import { IconDelete } from '../Icon/IconDelete';
import { IconStop } from '../Icon/IconStop';
import { allUsersUnions } from '../../subscriptions/allUsersUnions';
import TooltipButton from '../TooltipButton';
import { IconClock } from '../Icon/IconClock';
import { ColumnType } from 'antd/lib/table';
import { IconAttantion } from '../Icon/IconAttantion';
import ProjectMembersUsersUnionMembersList from './ProjectMembersUsersUnionMembersList';
import UsersUnionsAvatar from '../UsersUnion/UsersUnionsAvatar';
import PointDivider from '../PointDivider';
import { IconEdit } from '../Icon/IconEdit';
import ButtonStyled from '../AntCastomComponents/Button';

interface ProjectMembersEditorProps {
    project: Project
    isAdmin: boolean
}

const UsersUnionInviteHolder = styled.div<{}>`
    display: flex;
    align-items: center;
    
    & > span.txt {
        padding: 0 2px;
    }
    & > button {
        padding: 0 2px;
        color: ${p => p.theme.colors.font.accent};
    }
    
    & > button:hover {
        color: ${p => p.theme.colors.font.primary};
    }
`;

const Holder = styled.div<{}>`
    ${p => !p.theme.isMobile && css`margin: 40px 24px;`}
    max-width: 1141px;

    .ant-table-content {
        overflow: auto;
    }
`

const TableStyled = styled(Table)<{}>`
    ${p => !p.theme.isMobile && css`margin: 40px 24px;`}
    max-width: 1141px;

    .ant-table-column-sorters {
        justify-content: left;

        .ant-table-column-title {
            flex: 0;
        }
    }

    .ant-table-cell.ant-table-column-sort {
        background: none;
    }

    .ant-table-thead {

        .ant-table-cell.ant-table-column-sort {
            background: none;
        }

        .ant-table-cell {
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 300;
            gap: 4px;
            background: none;
        }
    }

    .invited {
        color: ${p => p.theme.colors.ui.bgDark05};
        display: flex;
        align-items: center;
        gap: 4px;
    }
    
    .invited.red {
        color: ${p => p.theme.colors.font.red};
    }

    .role-column {
        display: flex;

        .role-holder {
            flex: 1;
        }

        .actions {
            .ant-btn {
                color: ${p => p.theme.colors.ui.bgDark05}
            }
        }
    }
`


interface DataType {
    key: string;
    user?: User;
    accessLevel: AccessLevel;
    uu?: UsersUnion;
    uuMembers?: ProjectMember[];
    invite?: ProjectMemberInvite;
}

const ProjectMembersList: React.FC<ProjectMembersEditorProps> = ({project, isAdmin}) => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedUUInvite, setSelectedUUInvite] = useState<ProjectMemberInvite | undefined>(undefined);
    const [maxMembersCount, setMaxMembersCount] = useState<number>(0);
    const [removedMembers, setRemovedMembers] = useState<ProjectMember[]>([]);

    useEffect(() => {
        setMaxMembersCount(selectedUUInvite?.maxMembers ?? 0)
    }, [selectedUUInvite?.maxMembers]);

    const [ChangeLevel, opt2] = useProjectMemberChangeLevelMutation({});

    const [Remove, opt3] = useProjectRemoveMemberMutation({

        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [changeMaxMembers, opt4] = useProjectMemberInviteChangeMaxMembersMutation({

        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.projectMemberInviteChangeMaxMembers as Project)
        }
    });

    const [RemoveInvite] = useProjectMemberInviteRemoveMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [leaveProject, {}] = useProjectLeaveMutation({
        onCompleted: (_) => {
            notification.success({
                message: t("project.leavedSuccessfully"),
                description: project.title,
            });
        }
    });


    const sortIcon = useMemo(() => {
        return (props: { sortOrder: any }) => (props.sortOrder == 'ascend' ? <IconSorting/> : <IconSorting/>)
    }, [])

    const data: DataType[] = useMemo(() => {
        const members = project.members.filter(m=>!m.asUnion).map(m => {
            return {
                user: m.user,
                accessLevel: m.accessLevel,
                uu: m.asUnion,
            } as DataType
        })

        const invites = (project.invites ?? []).map(i => {
            return {
                user: i.user,
                accessLevel: i.accessLevel,
                uu: i.usersUnion,
                invite: i,
                uuMembers: i.usersUnion?.id ? project.members.filter(m=>m.asUnion?.id == i.usersUnion?.id) : []
            } as DataType
        })

        return [...members, ...invites];
    }, [project.members, project.invites])

    const columns: ColumnType<unknown>[] = useMemo(() => {
        return [
            {
                title: 'Имя',
                key: 'name',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.fullName ?? a.user?.username ?? '') > (b.user?.fullName ?? b.user?.username ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    return <>
                        {d.user && <UserAvatar user={d.user} addTitle={true}/>}
                        {d.invite?.usersUnion &&
													<UsersUnionInviteHolder>
                              <UsersUnionsAvatar uu={d.invite.usersUnion} />
                              <span className={'txt'}>{t('usersUnion.team')}</span>
                              <span className={'txt'}>{d.invite?.usersUnion.title}</span>
                              <PointDivider hideZero={false} />
                              <ButtonStyled type={"link"} onClick={() => {
                                  if (d.invite?.usersUnion)
                                      setSelectedUUInvite(d.invite)
                              }}>{t('countInvitesUnionMembers', {count: d.uuMembers?.length, countMax: d.invite.maxMembers, })}
                                  {isAdmin && !project.archived && <IconEdit />}
                              </ButtonStyled>
                          </UsersUnionInviteHolder>}
                    </>
                },
            },
            {
                title: 'Эл.почта',
                key: 'email',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.email ?? '') > (b.user?.email ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    return <span style={{padding: 3}}>{d.user?.email ?? d.invite?.email}</span>
                },
            },
            {
                title: 'Роль',
                key: 'role',
                className: 'role-column',
                showSorterTooltip: false,
                defaultSortOrder: 'descend',
                sortDirections: ['descend', 'ascend'],
                sorter: (a: DataType, b: DataType) => ((a.user?.fullName ?? a.user?.username ?? '') > (b.user?.fullName ?? b.user?.username ?? '') ? 1 : -1),
                sortIcon: sortIcon,
                render: (d: DataType) => {
                    const isCurUser = d.user?.id === authInfo.user.id;

                    const selector = <AccessLevelSelector selected={d.accessLevel}
                                                          disabled={!isAdmin || project.archived}
                                                          onChange={(level: AccessLevel) => {
                                                              if (isCurUser) {
                                                                  messageApi.warning(t('youCantEditYourselfInProjectMembers'));
                                                                  return false;
                                                              } else if (d.user)
                                                                  ChangeLevel({
                                                                      variables: {
                                                                          input: {
                                                                              userId: d.user.id,
                                                                              projectId: project.id,
                                                                              level
                                                                          }
                                                                      }
                                                                  })
                                                              return true;
                                                          }
                                                          }/>;

                    return <>
                        <div className={'role-holder'}>
                            {!d.invite && selector}
                            {d.invite && d.user == null && d.uu == null && <span className={'invited'}>
                                <IconClock/>{t('invitedByEmail')}
                            </span>}
                            {d.invite && d.user != null && d.invite.resolution === InviteResolution.Received && <span className={'invited'}>
                                <IconClock/>{t('invitedUser')}
                            </span>}
                            {d.invite && d.user != null && d.invite.resolution === InviteResolution.Declined && <span className={'invited red'}>
                                <IconAttantion/>{t('inviteDeclinedByUser')}
                            </span>}
                            {/*{d.invite && d.uu != null && <span className={'invited'}>*/}
                            {/*    <IconClock/>{t('invitedUsersUnion')}*/}
                            {/*</span>}*/}
                        </div>
                        {!project.archived && (isAdmin || isCurUser) && <div className={'actions'}>
													<Popconfirm
														icon={null}
														title={t('really')}
														description={d.invite ?
                                t('inviteSureRemove') : (isCurUser ? t('project.leaveConfirmDescription') : t('projectMembers.SureRemove'))}
														onConfirm={() => {
                                if (d.invite)
                                    RemoveInvite({variables: {id: d.invite.id}})
                                else if (d.user) {
                                    if (isCurUser) {
                                        leaveProject({
                                            variables: {
                                                projectId: project.id
                                            }
                                        })
                                        return false;
                                    } else if (isCurUser && isAdmin) {
                                        messageApi.warning(t('youCantEditYourselfInProjectMembers'));
                                        return false;
                                    } else if (isAdmin)
                                        Remove({
                                            variables: {
                                                input: {
                                                    userId: d.user.id,
                                                    projectId: project.id
                                                }
                                            }
                                        })
                                }
                            }}
														onCancel={() => {
                            }}
														okText={t('yes')}
														cancelText={t('no')}
													>
														<TooltipButton type="text" tooltipProps={{
                                title: isCurUser ? t('project.leaveTooltip') : t('remove')
                            }}>{
                                isCurUser ? <IconStop/> : <IconDelete/>
                            }</TooltipButton>
													</Popconfirm>
												</div>}
                    </>
                },
            }
        ] as ColumnType<unknown>[];
    }, []);

    return (
        <Holder>
            {contextHolder}
            {showErrors([opt2, opt3, opt4])}
            {showLoading([opt2, opt3, opt4])}
            <ProjectMembersEditor2 project={project} totalMembers={data.filter(v => !v.invite).length} isAdmin={isAdmin}/>

            <TableStyled
                columns={columns}
                dataSource={data}
                bordered={false}
                pagination={false}
            />

            <Modal
              title={t('project.membersListFromUnion', {unionName: selectedUUInvite?.usersUnion?.title, projectName: project.title})}
              open={selectedUUInvite != null}
              onCancel={() => {
                  setSelectedUUInvite(undefined);
              }}
              okText={t('project.membersListFromUnionSave')}
              cancelText={t('project.membersListFromUnionCancel')}
              width={620}
              centered
              onOk={() => {
                  let promises: Promise<any>[] = removedMembers.map(rm => {
                      return Remove({
                          variables: {
                              input: {
                                  userId: rm.user.id,
                                  projectId: project.id
                              }
                          }
                      })
                  })

                  if (maxMembersCount != selectedUUInvite?.maxMembers)
                      promises.push(changeMaxMembers({variables: {
                              inviteId: selectedUUInvite!.id,
                              projectId: project.id,
                              maxMembers: maxMembersCount
                          }}))

                  Promise.all(promises).then(() => {
                      setSelectedUUInvite(undefined);
                      setMaxMembersCount(0);
                      setRemovedMembers([]);
                  })
              }}
            >
                <ProjectMembersUsersUnionMembersList members={project.members.filter(m => m.asUnion?.id === selectedUUInvite?.usersUnion?.id)}
                                                     readonly={!isAdmin || project.archived} invite={selectedUUInvite} project={project}
                                                     maxMembersCount={maxMembersCount} onChangeMaxMembersCount={setMaxMembersCount}
                                                     removedMembers={removedMembers} onChangeRemovedMembers={setRemovedMembers}
                />
            </Modal>
        </Holder>
    );
};

export default ProjectMembersList;
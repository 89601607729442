import React from 'react'
import { Card, Col, message, Row, Typography} from 'antd'
import {useNavigate} from "react-router-dom";
import {Project, ProjectMemberInvite, useProjectAttachTagsMutation, User, UsersUnion} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {authState} from "../../routes/Auth/authContext";
import {makeVar, ReactiveVar, useReactiveVar} from '@apollo/client';
import {ProjectTasksFastListProps} from "./ProjectTasksFastList";
import Tagger from "../Tagger/Tagger";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import ProjectFavoriteButton from "./ProjectFavoriteButton";
import styled from 'styled-components';
import ProjectAvatar from './ProjectAvatar';
import { ProjectStatusLabel } from './ProjectChangeColumn';
import ProjectStatistics from './ProjectStatistics';
import ProjectMembers from './ProjectMembers';
import UsersUnionProjectMembersEditor from '../UsersUnion/UsersUnionProjectMembersEditor';
import UserAvatar from '../User/UserAvatar';
import UsersUnionsAvatar from '../UsersUnion/UsersUnionsAvatar';
import { Link } from '../Link';

const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'

const CardGridStyled=styled(Card.Grid)`
        width: 100% !important;
        position: relative;
        padding: ${P => P.theme.isMobile ? '12px 8px' : '16px'} !important;
        box-shadow: none !important;
        box-sizing: border-box;
        background-color: ${p => p.theme.colors.ui.white};
        transition: all .3s;
        border-radius: 8px !important;
        /*min-height: 125px;*/
        display: flex;
        flex-direction: column;
        gap: 8px;

        &:hover{
        background-color: ${p => p.theme.colors.ui.bgLight2}
        }
`

const ProjectListCardRow=styled(Row)<{$gapped?: boolean}>`
    gap: 8px;
    ${p=>p.$gapped && 'padding-left: 32px;'}
`

const ProjectCardContent=styled(Col)`
    display: flex;
    flex-direction: column;

    gap: 8px;

    >.project-members-container{
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
    }
`

const ProjectTitleContainer=styled.div`
    flex: grow;
`
const ProjectTitle=styled.span`
    display: inline-block;
    cursor: pointer;
    font-weight: 600;
    color: ${({theme})=>theme.colors.font.primary} !important;
    white-space: normal;
    max-height: 3em;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: normal; 
`


interface ProjectsListCardProps {
    project: Project,
    showFavorite?: boolean,
    showStatus?: boolean,
    showButtons?: boolean,
    showOwnerInfo?: boolean
    onProjectClick?: (project: Project) => void
    boardView?: boolean
    usersUnionMembersEditor?: JSX.Element
}

const StyledLink=styled(Link)`
    color: ${p=>p.theme.colors.font.primary} !important;
    transition: all .3s ease-in-out;
    width: 100%;

    &:hover{
        opacity: 0.8;
    }
`
const LinkWithEvent = ({ children, to, onProjectClick, project }: { children: any, to: string, onProjectClick: any, project: Project }) => {
    return <StyledLink to={to} key="tasks" onClick={(e) => {
        if (onProjectClick) {
            onProjectClick(project)
            e.preventDefault();
            e.stopPropagation()
            return false;
        }
    }}>{children}
    </StyledLink>
}

const OwnerInfoContainer=styled.div`
    background-color: ${p=>p.theme.colors.ui.bgLight3};
    padding: 16px;
    display: flex;
    gap: 8px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    align-items: center;

    >.owner-info-text{
        >span{
            display: block;
            line-height: 1.1;

            &.owner-info-text-owner{
                font-size: 12px;
            }

            &.owner-info-text-title{
                font-size: 10px;
                opacity: 0.5;
            }

        }
    }

`
const OwnerInfo=({owner}: {owner: User | UsersUnion})=>{

    const {t}=useTranslation()
    let avatar: React.JSX.Element
    let title: string

    if(owner.__typename==='User')
        avatar=<UserAvatar size='24' user={owner}/>
    else if(owner.__typename==='UsersUnion')
        avatar=<UsersUnionsAvatar uu={owner}/>
    else 
        avatar=<></>

    if(owner.__typename==='User')
        title=owner.fullName?? owner.username
    else if(owner.__typename==='UsersUnion')
        title=owner.title
    else 
        title=''


    return <OwnerInfoContainer>
        {avatar}
        <div className='owner-info-text'>
            <span className='owner-info-text-title'>{t('project.settingsPage.projectOwner')}</span>
            <span className='owner-info-text-owner'>{title}</span>
        </div>
    </OwnerInfoContainer>

}
export const droppedOnProject: ReactiveVar<Project | null> = makeVar<Project | null>(null);

function ProjectsListCard({  project, onProjectClick, showButtons = true, showStatus = true, showFavorite = true, boardView=false, usersUnionMembersEditor, showOwnerInfo=false }: ProjectsListCardProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authInfo = useReactiveVar(authState)
    useNavigate();

    const invite = project.invites?.find(i => i.user?.id == authInfo.user.id)
    const owner = project.userAsOwner ?? project.usersUnionAsOwner?? undefined;
    const [projectAttachTags] = useProjectAttachTagsMutation();

    //Удаление тега из проекта, на вход поступает новый массив тегов
    const onTagDelete = (tags: string[]) => {
        projectAttachTags({
            variables: {
                projectId: project.id,
                tags
            },
            update: (cache, r) => {
                updateProjectInCache(cache, r.data?.changedProject as Project)
            },
            onError: (error, clientOptions)=>{
                
                message.error(t('tags.tagDelitionErrorMessage'))
            },
        })
    }
    return <>
    {showOwnerInfo && owner && <OwnerInfo owner={owner}/>}
    <CardGridStyled
                onClick={() => {
                    navigate(`/project/${project.id}`)
                }}
            >
                <ProjectListCardRow wrap={false}>
                    <ProjectAvatar size='24' icon={project.icon} iconColor={project.iconColor}/>
                    <Col flex="auto">
                        {/*ownerInfo*/}
                        <LinkWithEvent to={'/project/' + project.id} project={project} onProjectClick={onProjectClick}>
                            <ProjectTitleContainer>
                                {showStatus && <ProjectStatusLabel status={project.status}/>}
                                <ProjectTitle>{project.title}</ProjectTitle>
                            </ProjectTitleContainer>
                        </LinkWithEvent>
                    </Col>
                    {showFavorite && !invite && <Col flex="none"><ProjectFavoriteButton project={project} /></Col>}
                </ProjectListCardRow>
                <ProjectListCardRow $gapped style={{marginBottom: 8}}>
                    <ProjectCardContent span={24}>
                        <div className='project-members-container'>
                            {usersUnionMembersEditor}
                            <ProjectMembers /*project={project}*/ members={project.members} invite={project.invites?.find(i => i.user?.id == authInfo.user.id)}/>
                        </div>
                        {project.attachedTags?.length!==0 && <Tagger projectId={project.id} allowAdd={false} block={true} defaultValue={project.attachedTags} maxLines={2} maxTags={4}
                                    onCloseTag={onTagDelete}/>}
                    </ProjectCardContent>
                </ProjectListCardRow>
                <ProjectListCardRow $gapped>
                <LinkWithEvent to={`/project/${project.id}/tasks`} key="tasks" project={project} onProjectClick={() => {
                                ProjectTasksFastListProps({ project })
                            }}>
                            {project.statistics && <ProjectStatistics stats={project.statistics}/>}
                        </LinkWithEvent>
                </ProjectListCardRow>
            </CardGridStyled>
            </>
}

export default ProjectsListCard;
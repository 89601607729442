import React, { useEffect, useRef, useState } from 'react';
import {Button, GlobalToken, theme} from "antd";
import {
    Project,
    ProjectChangePriorityAndStatusMutation, ProjectStatus,
    useProjectChangePriorityAndStatusMutation,
} from '../../generated-types';
import {useTranslation} from "react-i18next";
import {IconArrowDown} from '../Icon/IconArrowDown';
import SelectMenuDropdown, {CustomDropdownItem} from '../AntCastomComponents/SelectMenuDropdown';
import Dropdowned from '../Dropdowned';
import styled from "styled-components";
import {statuses} from "../UsersUnion/UsersUnionProjectsBoard";

export const getStyleByStatus = (s: ProjectStatus, token: GlobalToken) => {
    switch (s) {
        case ProjectStatus.New:
            return {color: token.colors.ui.project3}
        case ProjectStatus.Active:
            return {color: token.colors.ui.accent}
        case ProjectStatus.Finished:
            return {color: token.colors.ui.project4}
        case ProjectStatus.Frozen:
            return {color: token.colors.ui.columnFrozen}
        default:
            return {}
    }
}


interface ProjectChangeColumnProps {
    project: Project
    onChanged?: (status: ProjectStatus) => void;
}

const ProjectChangeColumnSelectRow = ({status}: { status: string }) => {

    const {t} = useTranslation()
    const {token} = theme.useToken()
    return <div
        style={{width: '100%', height: 16, display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 4}}>
        <span style={getStyleByStatus(status as ProjectStatus, token)}>•</span>
        <span style={{fontSize: 12, lineHeight: 1, textAlign: 'start', color: token.colors.font.primary}}>{t(`project.status.${status}`)}</span>
    </div>
}

const ProjectStatusContainer=styled.div<{$color: string}>`
    border-radius: 4px;
    position: relative;
    padding: 4px;
    width: fit-content;
    overflow: hidden;

    >div{
        z-index: 1;
    }

    &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${p=>p.$color};
        opacity: 0.2;
        z-index: 0;
    }
`
export const ProjectStatusLabel=({status}: {status: string})=>{

    const {token}=theme.useToken()

    return <ProjectStatusContainer $color={getStyleByStatus(status as ProjectStatus, token).color?? 'inherit'}>
        <ProjectChangeColumnSelectRow status={status}/>
    </ProjectStatusContainer>
}
export const ButtonStyled = styled(Button)<{ $token: GlobalToken }>`
    width: 165px;
    color: ${({$token}) => $token.colors.font.primary};
    background-color: ${({$token}) => $token.colors.ui.bgLight3};
    border: 0;
    box-shadow: unset;
    flex-direction: row-reverse;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    height: auto;

    &:hover {
        background-color: ${({$token}) => $token.colors.ui.bgLight};
    }
`

const ProjectChangeColumn = ({project, onChanged}: ProjectChangeColumnProps) => {
    const btnRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const [value, setValue] = useState(project.status);
    const {t} = useTranslation();
    const {token} = theme.useToken();

    useEffect(() => {
        setValue(project.status);
    }, [project.status]);

    const [moveProject] = useProjectChangePriorityAndStatusMutation({
        optimisticResponse: ({projectId, status, priority}) => {
            const res = {
                projectChangePriorityAndStatus: {
                    id: projectId,
                    priority,
                    status
                }
            } as ProjectChangePriorityAndStatusMutation;
            return res;
        }
    })

    const dropdownItems = statuses.map((status: ProjectStatus) => ({
        label: <ProjectChangeColumnSelectRow status={status}/>,
        type: 'item',
        selected: status === value.toString(),
        key: status,
        onClick: () => {
            setOpened(false)
            if (project.id)
                moveProject({
                    variables: {
                        projectId: project.id,
                        usersUnionId: project.usersUnionAsOwner!.id,
                        priority: project.priority ?? 0,
                        status: status
                    },
                })
            else if (onChanged) {
                onChanged(status)
                setValue(status)
            }
        }
    }) as CustomDropdownItem)


    return <>
        <ButtonStyled $token={token}
                styles={{icon: {display: "flex", alignItems: 'center'}}}
                icon={<IconArrowDown strokeWidth={'1.5px'}/>}
                onClick={() => setOpened(true)}
                ref={btnRef}
        >
            {dropdownItems.find((value: CustomDropdownItem) => value.selected)?.label}
        </ButtonStyled>
        {opened && <Dropdowned opened={opened}
				                       title={t('project.chooseStatus')}
				                       onClose={() => setOpened(false)}
								               anchor={btnRef}
				>
					<SelectMenuDropdown items={dropdownItems}/>
				</Dropdowned>}
    </>
}

export default ProjectChangeColumn;

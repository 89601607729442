import React from 'react'
import {Button, Spin, theme, Tooltip} from 'antd'
import {Project, useProjectAddToFavoriteMutation} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import {IconFavourites} from "../Icon/IconFavourites";
import styled from 'styled-components';

interface ProjectFavoriteButtonProps {
    project: Project
}

const ButtonStyled=styled(Button)<{$isFavourite: boolean}>`

    color: ${p=>p.$isFavourite? p.theme.colors.ui.accent : p.theme.colors.font.primary} !important;
    background-color: none !important;

    >.ant-btn-icon{
        font-size: 24px;
        display: block;
        line-height: 1;
    }
`

const ProjectFavoriteButton = ({project}: ProjectFavoriteButtonProps) => {
    const {t} = useTranslation()
    const {token} = theme.useToken();
    const [addToFavorite, {loading: loadingAdd }] = useProjectAddToFavoriteMutation();

    const isFav = project.UserMarkers && project.UserMarkers.isFavorite;
    const icon = isFav ?
        <IconFavourites color={token.colors.font.accent} fill={token.colors.font.accent} stroke={token.colors.ui.accent}/>
        : <IconFavourites color={token.colors.font.accent}/>;

    return (
        <div className={"project-favorite-button"}>
        {loadingAdd
            ? <Spin ></Spin>
            : <Tooltip title={t(isFav ? 'project.RemoveFromFavorite' : 'project.AddToFavorite')}>
                <ButtonStyled
                    $isFavourite={isFav}
                 type="text" icon={icon}
                 size='small'
                          onClick={(e) => {
                            e.stopPropagation();
                              addToFavorite({variables: {input: {
                                          projectId: project.id,
                                          isFavorite: !isFav
                                      }
                                  },
                                  update: (cache, r) => {
                                    updateProjectInCache(cache, r.data?.projectAddToFavorite as Project)
                              }
                              })
                          }
                          } />
            </Tooltip>
            }
        </div>
    )
}

export default ProjectFavoriteButton
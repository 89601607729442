import { Button, message, Modal } from "antd";
import {
    InviteResolution, ProcessInviteDocument,
    Project,
    ProjectMemberInvite,
    useProcessInviteMutation,
    User,
    UsersUnion
} from "../../generated-types";
import React, { useState } from "react";
import apolloClient from "../../ApolloClient";
import { getI18n } from "react-i18next";
import ProjectInviteMembersSelector from "./ProjectInviteMembersSelector";
import Text from "antd/es/typography/Text";
import Dropdowned, { CenterJustify } from "../Dropdowned";
import styled, { css } from "styled-components";
import { IconClose } from "../Icon/IconClose";
import { isMobileOnly } from "react-device-detect";
import RefetchesList from "../../queries/RefetchesList";
import { updateProjectInCache } from "../../subscriptions/allProjects";

export let SelectedUsers: User[] = [];

const buttonStyle=css`
    flex-grow: 1;
    height: 44px;
    
    border: none !important;
    box-shadow: none;


`
const SelectorContainer=styled.div`
    padding: 24px;
    width: ${p=>p.theme.isMobile? '100%' : "600px"};
    height: ${p=>p.theme.isMobile? '100%' : "640px"};
    display: flex;
    flex-direction: column;

    >.slots-info{
        padding-bottom: 16px;
    }

    >.buttons-ontainer{
        display: flex;
        gap: 8px;
        

        >.ok-btn{
            
            ${buttonStyle}
            background-color: ${p=>p.theme.colors.ui.accent};
            color: ${p=>p.theme.colors.font.white} !important;
            &:hover{
                background-color: ${p=>p.theme.colors.ui.accentHover};
            }
        }

        >.cancel-btn{
            ${buttonStyle}
            background-color: ${p=>p.theme.colors.ui.bgLight};
            color: ${p=>p.theme.colors.font.primary} !important;
            &:hover{
                background-color: ${p=>p.theme.colors.ui.bgLight3};
            }
        }
    }
`

const Title=styled.div`
    >span{
        font-size: 16px;
        font-weight: 600;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    >.ant-btn{
        font-size: 24px;
        line-height: 1;
        background-color: unset !important;

        >.ant-btn-icon{
            stroke-width: 1.5;
        }

        &:hover{
            color: ${p=>p.theme.colors.font.accent} !important;
        }
    }
`
const ProcessProjectInvite = (props: {
    invite: ProjectMemberInvite | null,
    union: UsersUnion,
    onClose: () => void
}) => {
    SelectedUsers = [];
    const { t } = getI18n();
    const [opened, setOpened] = useState(true);
    const [invite]=useProcessInviteMutation({
        onCompleted(){

            props.onClose()
        },
        onError(error){
            message.error(error.message)
            //console.log('Oops errors!')
        },
        update: (cache, resp) => {
            updateProjectInCache(cache, resp.data?.processInvite as Project)
        },
        refetchQueries: [RefetchesList.UsersUnion]
    })
    
    const title=t('usersUnion.processProjectInvite.title', {project: props.invite?.project.title})

    return <Dropdowned
        title={title}
        opened={opened}
        onClose={() => {
            setOpened(false);
            props.onClose()
        }}
        centerJustify={CenterJustify.center}
    >
        <SelectorContainer>
           {!isMobileOnly && <Title>
                <span>{title}</span>
                <Button type="text" size="small" icon={<IconClose/>} onClick={()=>{setOpened(false); props.onClose()}}/>
            </Title>}
            <ProjectInviteMembersSelector
                maxMembers={props.invite?.maxMembers!}
                invite={props.invite as ProjectMemberInvite} union={props.union}
                project={props.invite?.project as Project}
                onChange={(selected) => {
                    SelectedUsers = selected
                }}
                items={props.union.members.map(m => ({
                    key: m.user.id,
                    user: m.user
                }))}
            />
            <div className="buttons-ontainer">
                <Button className="ok-btn" onClick={() => {
                    if (!props.invite) return;
                    const variables = {
                        inviteId: props.invite.id,
                        users: SelectedUsers.map(u => u.id),
                        resolution: InviteResolution.Accepted
                    };
                    invite({variables})
                }}
                >{t('change')}</Button>
                <Button className="cancel-btn" onClick={()=>{setOpened(false); props.onClose()}}>{t('cancel')}</Button>
            </div>
        </SelectorContainer>
    </Dropdowned>
};

export default ProcessProjectInvite
import styled, { css } from 'styled-components';

const MembersEditorHolder = styled.div<{}>`
    ${p => !p.theme.isMobile ? css`margin: 40px 24px;` : css`margin: 16px 8px;`}
    max-width: 800px;
    display: flex;
    border-radius: 4px;
    background-color: ${p => p.theme.colors.ui.white};

    ${p => !p.theme.isMobile && css`
        height: 109px;
        gap: 16px;
        flex-direction: row;
    `}
    ${p => p.theme.isMobile && css`
        flex-direction: column;
    `}
    
    .invite-member {
        background-color: ${p => p.theme.colors.ui.bgLight};
        color: ${p => p.theme.colors.font.accent};
        border: 0;
        height: 44px;
    }
    
    .block1 {
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 15px;
        line-height: 115%;
    }

    .title {
        font-size: 16px;
    }
    
    .block2 {
        display: flex;
        flex-grow: 1;
        align-items: center;
        border-radius: 8px;
        margin: 8px;
        padding: 16px;
        gap: 8px;
        color: ${p => p.theme.colors.font.primary};
        background-color: ${p => p.theme.colors.font.red01};
        overflow: hidden;
        
        div {
            align-self: self-start;
        }
    }

    .block2 :first-child {
        color: ${p => p.theme.colors.font.red};
    }

    .block3 :first-child {
        display: flex;
    }
    
    .block3 {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px;
    }
`

export default  MembersEditorHolder;
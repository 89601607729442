import React, {CSSProperties, PropsWithChildren, useEffect, useMemo, useState} from 'react'
import {Col, Form, FormInstance, Grid, Input, notification, Row, Space, theme,} from 'antd';
import {
    AttachedFolder,
    EditTaskInput,
    ExternalFile,
    ExternalFileType,
    Project,
    Tag,
    Task,
    TaskMember,
    TaskMemberInput,
    TaskStatus,
    TasksView,
    useTaskViewQuery,
} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import TaskMembersEditor from "./TaskMembersEditor";
import Tagger from "../Tagger/Tagger";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {allProjects} from "../../subscriptions/allProjects";
import {ReactiveVar, useReactiveVar} from '@apollo/client';
import dayjs from 'dayjs'
import TaskImportance from "./TaskImportance";
import TaskCheckList from "./TaskCheckList";
import {IconUser} from '../Icon/IconUser';
import {IconCalend} from '../Icon/IconCalend';
import {IconPriority} from '../Icon/IconPriority';
import {IconBoards} from '../Icon/IconBoards';
import {IconTag} from '../Icon/IconTag';
import TaskFiles from './TaskFiles';
import TaskChangeColumn from './TaskChangeColumn';
import {editTaskContext} from "./TaskEditor";
import { addTaskContext } from './TaskNew';

const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 24},
};

interface TaskCardProps {
    task: Task,
    project: Project,
    projectId: string | undefined,
    save: (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => void,
    form: FormInstance
    newTask?: boolean
}

export const valuesToTaskInput = (values: any, members: TaskMember[] | undefined, files: ExternalFile[] | undefined, tags: string[] | undefined): EditTaskInput => {
    const {title, description, startTime, startDate, endTime, endDate, importance, status} = values;
    const res = {
        title, description,
        startDate,
        importance,
        endDate,
        startTime: startTime ? startTime : undefined,
        endTime: endTime ? endTime : undefined,
        tags: tags ?? [],
        members: members?.map(m => ({userId: m.user.id, memberType: m.memberType})) ?? [] as TaskMemberInput[],
        files: files?.map(f => ({externalFilePath: f.path, name: f.name})) ?? [],
        status: status?? TaskStatus.Todo
    } as EditTaskInput;
    // Для обратной совместимости. Статус задачи вообще нужно убрать
    //res.status = TaskStatus.Todo;
    
    return res;
}

export const prepareTask = (task: Task): any => {
    const {startTime, endTime, endDate, startDate, ...tf} = task;
    return {
        startTime: startTime ? dayjs().startOf("day").add(startTime, "minutes") : undefined,
        endTime: endTime ? dayjs().startOf("day").add(endTime, "minutes") : undefined,
        startDate: startDate ? dayjs(startDate) : undefined,
        endDate: endDate ? dayjs(endDate) : undefined,
        ...tf
    } as any;
}

export type ExternalFileWithId = ExternalFile & {
    id: string
}

interface TaskCardNamedRowProps{
    title: string,
    icon: JSX.Element
}
const TaskCardNamedRow=({title, icon, children}: PropsWithChildren<TaskCardNamedRowProps>)=>{

    const screen=Grid.useBreakpoint()
    const {token}=theme.useToken()
    return <Row wrap={false} style={{borderBottom: screen.xs? `1px solid ${token.colors.ui.bgLight3}` : 'none', padding: '4px 0px'}}>
        <Col flex={screen.xs? undefined : '150px'} style={{marginRight: screen.xs? 8 : 4}}>
        <Space size={4} style={{opacity: 0.5, padding: '8px 0px'}} align='center' styles={{item: {height: 16, display: 'flex', alignItems: 'center'}}}>
            <span style={{fontSize: 16, display: 'flex', alignItems: 'center', lineHeight: 1.375}}>{icon}</span>
            {!screen.xs && <span style={{fontWeight: 300}}>{title}</span>}
        </Space>
        </Col>
        <Col flex={'auto'}>
            <Row>
            {children}
            </Row>
        </Col>
    </Row>
}


const TaskCard: React.FC<TaskCardProps> = ({task, form, save, project, newTask=false}) => {
    useNavigate();
    const {t} = useTranslation();
    const [members, setMembers] = useState<TaskMember[]>(task.members)
    const [tags, setTags] = useState<string[]>(task.tags)
    const [selectedFiles, setSelectedFiles] = useState<ExternalFileWithId[]>(
        task.files.map(v => ({name: v.name, path: v.path, type: ExternalFileType.File, id: v.id} as ExternalFileWithId))
    );
    const {token}=theme.useToken()

    const context=newTask? addTaskContext : editTaskContext
    const ctx = useReactiveVar(context);

    const {loading, error, data: tw_data} = useTaskViewQuery({
        skip: ctx.taskView != undefined || ctx.taskViewId == undefined,
        variables: {
            tasksViewId: ctx.taskViewId!
        }
    })

    const tw = useMemo(() => {
            if (ctx.taskView != undefined) return ctx.taskView;
            if (ctx.taskViewId != undefined) return tw_data?.tasksView;
            return undefined;
        },
        [ctx.taskId, ctx.taskView, ctx.taskViewId, tw_data])

    //Обаботка события успешного submit
    const onFinish=(values: any)=>{
        save(values, members, selectedFiles, tags)
    }

    //Обработка ошибочного submit
    const onFinishFailed=(errorInfo: any)=>{
        errorInfo?.errorFields.forEach((errorField: any)=>{
            errorField?.errors.forEach((error: any)=>notification.error({message: error}))
        })
    }

    useEffect(() => {
        const keydownHandler = (e: any) => {
            if (e.keyCode === 13 && e.ctrlKey) {
                form.submit()
            }
        };

        document.addEventListener('keydown', keydownHandler);

        return () => {
            document.removeEventListener('keydown', keydownHandler);
        };
    }, [form]);

    const myUnions = useReactiveVar(allUsersUnions);
    const allP = useReactiveVar(allProjects);

    let folders: AttachedFolder[] = [];

    let p = allP.projects.find(pp => pp.id === project.id)
    let uu = myUnions.unions.find(uu => uu.id === project.usersUnionAsOwner?.id)
    if (p?.attachedFolders)
        folders = p.attachedFolders ?? []
    if (uu?.attachedFolders)
        folders = folders.concat(uu.attachedFolders);

    const baseInputStyle:CSSProperties={
        padding: 16,
        borderRadius: 16,
    }

    useEffect(()=>{
        form.setFieldsValue(task)//вместо initalValues лучше задать поля так, т.к. initialValues ставятся один раз и потом форма будет открываться с ними
    },[])
    
    return (
        <Form
            layout="vertical"
            name="TaskCardForm"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            {...formItemLayout}
            //initialValues={task}
        >
            <Space style={{width: '100%'}} size={8} direction={'vertical'}>
            <Row>
                <Col span={24}>
                <Form.Item name="title" noStyle
                            rules={[{required: true, message: "" + t('task.titleRequired')}]}>
                            <Input.TextArea autoSize 
                            placeholder={t('task.title') as string} 
                            style={{...baseInputStyle,
                                fontSize: 20,
                                fontWeight: 500
                            }}
                            />
                </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{borderRadius: 16, backgroundColor: token.colors.ui.bgLight2, padding: 16}}>
                            <TaskCardNamedRow title={'Исполнитель'} icon={<IconUser/>}>
                                <TaskMembersEditor task={task} project={project} onUpdateMembers={(newMembers: TaskMember[]) => {
                                setMembers(newMembers)
                                    }}/>
                            </TaskCardNamedRow>
                            <TaskCardNamedRow title={'Срок исполнения'} icon={<IconCalend strokeWidth='1.5px'/>}>
                                {// <TaskDates task={task} form={form}/>
                                }
                            </TaskCardNamedRow>
                            <TaskCardNamedRow title={'Приоритет'} icon={<IconPriority strokeWidth={0}/>}>
                                <Form.Item name="importance" noStyle>
                                    <TaskImportance value={task.importance?? 0} id={task.id} sendOnChange={false} onChange={(importance) => {
                                        form.setFieldValue("importance", importance);
                                    }}/>
                                </Form.Item>
                            </TaskCardNamedRow>
                            <TaskCardNamedRow title={'Доска'} icon={<IconBoards strokeWidth='1.5px'/>}>
                                {tw != undefined && 
                                <Form.Item name="status" noStyle>
                                    <TaskChangeColumn task={task} taskView={tw as TasksView} sendOnChange={false} onChange={(column)=>form.setFieldValue('status', column.title)}/>
                                </Form.Item>}
                            </TaskCardNamedRow>
                            <TaskCardNamedRow title={'Метки'} icon={<IconTag/>}>
                                <Tagger block allowAdd={true} projectId={project.id} defaultValue={task.tags}
                                        usersUnionId={project.usersUnionAsOwner?.id}
                                        allowEditProjectTags={true}
                                        allowEditUsersUnionTags={true}
                                        onChanged={(tags: Tag[]) => {
                                            setTags(tags.map(t => t.id))
                                                    }}/>
                            </TaskCardNamedRow>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="description" noStyle>
                        <Input.TextArea style={{
                            ...baseInputStyle,
                            resize: 'none'
                        }} 
                        rows={8} 
                        
                        placeholder={"" + t('task.description')}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <TaskFiles selectedFiles={selectedFiles} folders={folders} setSelectedFiles={setSelectedFiles} project={project}/>
            </Row>

            <Row>
                {task?.id &&
			            <TaskCheckList parentTask={task} project={project} childTasks={task.childTasks as Task[] ?? []}/>}
            </Row>
            </Space>
        </Form>)
}

export default TaskCard

import { Input } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { IconSearch } from './Icon/IconSearch'

interface SearchInputProps {
    placeHolder: string
    onSearch: (value: string) => void
}
const StyledInput = styled(Input)`
    width: 100%;
    max-height: 40px;
    background-color: ${p=>p.theme.colors.ui.bgLight3};
    border-radius: 8px;

    >.ant-input-prefix{
        font-size: 24px;
        opacity: 0.5;
    }

`
function SearchInput({ placeHolder, onSearch }: SearchInputProps) {

    return (
        <StyledInput allowClear variant={"outlined"}
            prefix={<IconSearch />}
            placeholder={placeHolder}
            onChange={(e) => onSearch(e.target.value?.toUpperCase())} />

    )
}

export default SearchInput
import { Card, Col, theme } from 'antd'
import React, { forwardRef, Ref, useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import styled, { css } from 'styled-components'
import GeneralBoardCard, { DropPositions, GeneralBoardItemType } from './GeneralBoardCard'
import { isMobile } from 'react-device-detect'
import Scrollbar from 'react-scrollbars-custom'
import { useReactiveVar } from '@apollo/client'
import { draggingCardSize } from '../CommonDragLayer'

const GeneralBoardColumnContainer = styled.div`
width: 100%;
height: 100%;
align-self: center;
`
const GeneralBoardColumnTitle = styled.div`
display: flex;
flex-direction: column;
`
const GeneralBoardColumnBody = styled.div<{ $isOver: boolean, $gap: number, $dropPosition?: DropPositions }>`
    display: flex;
    padding: ${p => p.theme.$isMobile ? '2px 0px 36px 0px' : '6px 0px 24px 0px'};
    flex-direction: column;
    min-height: 100%;
    transition: all .3s ease-in-out;

    ${p=>p.$dropPosition===DropPositions.top && `padding-top: ${p.$gap}px;`}
    
    ${p => p.$dropPosition===DropPositions.bottom && css`&::after{
    content: '';
    background-color: ${p.theme.colors.ui.accent};
    width: 100%;
    height: 4px;
    border-radius: 2px;
    }`}

`

const CardStyled = styled(Card) <{ $isOver: boolean }>`
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: unset;
        box-shadow: none;
        border: none;
        padding: 0;
        overflow: visible;
        
        >.ant-card-head{
            background-color: unset;
            box-shadow: none;
            border: none;
            padding: 0;
            flex-shrink: 0;
            z-index: 2;
            min-height: 0px;
            
        }

        >.ant-card-body{
            flex-grow: 1;
            overflow-y: hidden;
            padding: 0;
            padding: 0;
            overflow: visible;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                top: 0;
                transform: translateY(-50%);
                background: linear-gradient(to bottom, ${({ theme }) => theme.colors.ui.bgLight}, rgba(255, 255, 255, 0));
                height: 40px;
                z-index: 1;
            }
        }
`
const ColumnSpliter = styled.div`
width: 1px;
height: calc(100% - 30px);
flex-shrink: 0;
background-color: ${({ theme }) => theme.colors.ui.bgLight3};

`

export type GeneralBoardColumnType<T> = {
    id: number | string,
    title: string
    item: T
}

interface GeneralBoardColumnProps<I, C> {
    signature: string,
    items: GeneralBoardItemType<I>[],
    onItemRender: (item: I) => React.JSX.Element,
    column: GeneralBoardColumnType<C>,
    columnTitle: string | JSX.Element,
    onItemDropped: (droppedItem: I, droppedOnItem?: I, column?: C, dropPosition?: DropPositions) => void
    stickyTopElement?: (column: C) => React.JSX.Element
    isBoardDragging: boolean
    cursorType?: string,
    touchEventsDelay: number
    width?: number | string
    isLast: boolean
}

function GeneralBoardColumn<I, C>({ signature, items, onItemRender, column, columnTitle,
    onItemDropped, stickyTopElement, isBoardDragging,
    cursorType, touchEventsDelay, width, isLast }: GeneralBoardColumnProps<I, C>) {
        
    const { token } = theme.useToken()
    const columnRef=useRef<HTMLDivElement>(null)
    const columnBodyRef=useRef<HTMLDivElement>(null)
    const {height}=useReactiveVar(draggingCardSize);
    const [dropPosition, setDropPosition] = useState<DropPositions | undefined>(undefined)

    const [{ isOver, canDrop }, drop] = useDrop(() => (
        {
            accept: signature,
            drop(itemDropped: GeneralBoardItemType<I>, monitor) {
                if (monitor.isOver({ shallow: true }) && dropPosition) {
                    itemDropHandler(itemDropped, undefined, column.item, dropPosition)
                }
                setDropPosition(undefined)
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),

            }),
            hover(item, monitor) {
                if(!monitor.isOver({ shallow: true }))
                    return

                const body = columnBodyRef.current
                const monitorClientOffset = monitor.getClientOffset()
                
                if(items.length===0){
                    setDropPosition(DropPositions.bottom)
                    return
                }
                                
                if (body && monitorClientOffset) {
                    const containerRect = body.getBoundingClientRect()
                    const offsetY = monitorClientOffset.y - containerRect.top
                
                if (offsetY < containerRect.height * 0.5){
                    if(item.columnId===column.id && item.cardInColumnIndex===0)
                        return

                    setDropPosition(DropPositions.top)
                }
                else {
                    if(item.columnId===column.id && item.cardInColumnIndex===items.length-1)
                        return

                    setDropPosition(DropPositions.bottom)
                }
                }
            },
        }
    ), [items.length, dropPosition])

    drop(columnRef)

    useEffect(() => {
        if (!isOver)
            setDropPosition(undefined)
    }, [isOver])

    const header = <GeneralBoardColumnTitle>
        {columnTitle}
        {stickyTopElement && stickyTopElement(column.item)}
    </GeneralBoardColumnTitle>


    function itemDropHandler(itemDropped: GeneralBoardItemType<I>, droppedOnItem?: GeneralBoardItemType<I>, droppedOnColumn?: C, dropPosition?: DropPositions){

        let droppedOn = droppedOnItem
        if (droppedOn) {
            if (dropPosition === DropPositions.bottom)
                droppedOn = items[droppedOn.cardInColumnIndex + 1]
        } else {
            if(dropPosition===DropPositions.top){
                droppedOn=items[0]
            }
        }
        onItemDropped(itemDropped.item, droppedOn?.item, column.item)

    }

    return (<>
        <Col
            role='COLUMN'
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                flexShrink: 0,
                width: width,
                overflow: 'visible',
                padding: isMobile ? '0px 4px' : '0px 16px',
                minWidth: 330
            }}>
            <GeneralBoardColumnContainer ref={columnRef}>
                <CardStyled className={''}
                    $isOver={isOver}
                    title={header}
                >
                    <Scrollbar style={{ overflow: 'visible' }}
                        wrapperProps={{
                            renderer: (props) => {
                                const { elementRef, key, ...restProps } = props;
                                return <div key={key} {...restProps} ref={elementRef} style={{
                                    inset: 0,
                                    position: 'absolute',
                                    overflow: 'hidden'
                                }} />;
                            },
                        }}

                        thumbYProps={{
                            renderer: (props) => (<div ref={props.elementRef}
                                {...props}
                                style={{
                                    ...props.style,
                                    backgroundColor: token.colors.ui.bgDark,
                                    opacity: 0.2
                                }} />)
                        }}
                        trackYProps={{

                            renderer: (props) => (<div
                                ref={props.elementRef}
                                {...props} style={{
                                    ...props.style,
                                    width: 4,
                                    height: 'calc(100% - 42px)',
                                    position: 'absolute',
                                    top: 0,
                                    right: isMobile ? -6 : -8,
                                    marginTop: isMobile ? 4 : 12,
                                    backgroundColor: token.colors.ui.bgLight3
                                }} />)
                        }}>
                        <GeneralBoardColumnBody
                            ref={columnBodyRef}
                            $isOver={isOver && canDrop}
                            $gap={height}
                            $dropPosition={dropPosition}
                            style={{ cursor: cursorType }}>
                            {
                                items.map((item, index, arr) => {
                                    

                                    return <GeneralBoardCard
                                        key={`GB-item-${column.id}-${item.id}`}
                                        signature={signature}
                                        item={{...item,cardInColumnIndex: index}}
                                        onItemRender={onItemRender}
                                        onItemDropped={itemDropHandler}
                                        isBoardDragging={isBoardDragging}
                                        touchEventsDelay={touchEventsDelay}
                                        lastCard={index===arr.length-1}
                                    />
                                })

                            }
                        </GeneralBoardColumnBody>
                    </Scrollbar>
                </CardStyled>
            </GeneralBoardColumnContainer>
        </Col>
        {isLast && <ColumnSpliter />}
    </>
    )
}

export default GeneralBoardColumn